import React from "react";
import styled from "styled-components";
import { CgSpinner } from "react-icons/cg";
import { spin } from "../../../styles/mixins";

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0.3;
`;

const SpinnerIcon = styled(CgSpinner)`
  margin: 0 auto;
  width: 42px;
  height: 42px;
  animation: ${spin} 0.7s linear 0s infinite;
  z-index: 40;
  position: absolute;
  bottom: calc(50% - 21px);
  left: calc(50% - 21px);
`;

const SpinnerWrapper = styled.div`
  display: grid;
  align-items: center;
`;
const SpinnerWithOverlay = () => {
  return (
    <SpinnerWrapper>
      <Overlay />
      <SpinnerIcon />
    </SpinnerWrapper>
  );
};

export default SpinnerWithOverlay;
