import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { slugify } from "../../../utils/functions/slugify";
import { theme } from "../../../styles/theme/theme";
import Link from "../../UI/elements/Link";
import PostItemRenderer from "../../markdown/PostItemRenderer";

const Wrapper = styled.article`
  margin-top: -2.5em;
`;

const Text = styled.section`
  margin: ${theme.spaces.margin.base} auto;
  width: 80%;

  @media (min-width: 800px) {
    background-color: ${theme.colors.white};
    margin-top: -5em;
    z-index: 10;
    padding: ${theme.spaces.padding.big};
  }

  @media (min-width: 1100px) {
    width: 60%;
  }

  @media (min-width: 1300px) {
    width: 40%;
  }
  span {
    text-transform: uppercase;
    color: ${theme.colors.secondary};
  }

  h1 {
    margin-top: ${theme.spaces.margin.small};
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: ${theme.fonts.fontWeight.light};
  }

  .post-tag {
    margin-right: 0.5em;
  }
`;

const StyledImage = styled(GatsbyImage)`
  @media (min-width: 800px) {
    max-height: 70vh;
    z-index: -10;
  }
`;

interface Props {
  post: {
    featuredImage: ImageDataLike;
    tags: string[];
    title: string;
    postBody: {
      childMarkdownRemark: {
        html: string;
      };
    };
  };
}

const index = ({ post }: Props) => {
  const featuredImage = getImage(post.featuredImage);
  if (!featuredImage) {
    throw new Error(`No image found for post ${JSON.stringify(post)}`);
  }

  return (
    <Wrapper>
      <StyledImage image={featuredImage} alt={post.title} />

      <Text>
        {post.tags.map((tag) => (
          <Link key={tag} to={`/blog/${slugify(tag)}`} className="post-tag">
            <span>{tag}</span>
          </Link>
        ))}
        <h1>{post.title}</h1>
        <PostItemRenderer>{post.postBody.childMarkdownRemark.html}</PostItemRenderer>
      </Text>
    </Wrapper>
  );
};

export default index;
