import React from "react";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import styled from "styled-components";

const ProductImage = styled(GatsbyImage)`
  justify-self: center;
  width: 350px !important;
  /* height: auto; */
`;

interface Props {
  image: ImageDataLike;
  alt: string;
  eager?: boolean;
}

const productSliderImage = ({ image, alt, eager }: Props) => {
  const productPhoto = getImage(image);
  if (!productPhoto) {
    throw new Error("No product photo");
  }

  return <ProductImage image={productPhoto} alt={alt} loading={eager ? "eager" : "lazy"} />;
};

export default productSliderImage;
