import { createGlobalStyle } from "styled-components";
import { linkBorderOut } from "./mixins";
import { theme } from "./theme/theme";

const GlobalStyle = createGlobalStyle`
:root {
      box-sizing: border-box;
    }

    *,
    *::before,
    *::after {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      -webkit-touch-callout: none;
      -webkit-tap-highlight-color: transparent;
    }

    html {
      font-family: "Lato", sans-serif;
      font-size: ${theme.fonts.fontSize.base};
      font-weight: ${theme.fonts.fontWeight.normal};
      line-height: 1.5;


      @media (max-width: 650px) {
        font-size: ${theme.fonts.fontSize.xs};
      }
    }

    p,
    span {
      letter-spacing: 0.02em;
      color: ${theme.colors.font};
      font-weight: ${theme.fonts.fontWeight.light};
      line-height: 1.6;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      letter-spacing: 0.1rem;
      line-height: 1.25;
      margin-bottom: 0.75em;
      font-weight: ${theme.fonts.fontWeight.normal};
      color: ${theme.colors.titles};
    }

    h1 {
      font-size: ${theme.fonts.fontSize["4xl"]};

      @media (max-width: 650px) {
        font-size: ${theme.fonts.fontSize["3xl"]};
      }
    }
    h2 {
      font-size: ${theme.fonts.fontSize["3xl"]};

      @media (max-width: 650px) {
        font-size: ${theme.fonts.fontSize["2xl"]};
      }
    }
    h3 {
      font-size: ${theme.fonts.fontSize["2xl"]};

      @media (max-width: 650px) {
        font-size: ${theme.fonts.fontSize.xxl};
      }
    }
    h4 {
      font-size: ${theme.fonts.fontSize.xxl};

      @media (max-width: 650px) {
        font-size: ${theme.fonts.fontSize.xl};
      }
    }

    h5 {
      font-size: ${theme.fonts.fontSize.xl};

      @media (max-width: 650px) {
        font-size: ${theme.fonts.fontSize.lg};
      }
    }

    ol,
    ul {
      list-style: none;
    }

    img {
      width: 100%;
      display: block;
    }

    a {
      color: inherit;
      text-decoration: inherit;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent; //TODO: migration review

      :active,
      :focus {
        outline: 0;
      }
    }

    textarea,
    input {
      outline: none;
      border: none;
      -webkit-appearance: none;
      appearance: none;
    }


    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button,
    input[type='number'] {
      -webkit-appearance: none;
      margin: 0;
      -moz-appearance: textfield !important;
      appearance: textfield;
    }

    input:focus {
      outline: none;
    }

    button {
      background-color: transparent;
      font-family: "Lato", sans-serif;
      cursor: pointer;
      border: none;

      :focus {
        border: none;
        outline: none;
      }
    }

    fieldset {
      border: none;
      border: 0;
      padding: 0;
      margin: 0;
      min-width: 0;
    }

    .product-title {
      letter-spacing: 4px;
      text-transform: uppercase;
      font-weight: ${theme.fonts.fontWeight.light};
      font-size: ${theme.fonts.fontSize.xxl};

      @media (min-width: 980px) {
        font-size: ${theme.fonts.fontSize["2xl"]};
      }
    }

    .product-title-span {
      color: ${theme.colors.secondary};
      margin-top: -0.5em;
      text-transform: uppercase;

      @media (min-width: 980px) {
        font-size: ${theme.fonts.fontSize.xl};
      }
    }

    .point-wrapper > p {
      color: #fff;
    }

    .navbar_icon {
      height: 35px;
      width: auto;
      fill: inherit; 
      transition: all 0.5s ease-out;

      @media (max-width: 680px) {
        height: 30px;
      }

      @media (max-width: 400px) {
        height: 28px;
      }
    }

    .border__out {
      ${linkBorderOut}
    }

    .swiper-button-next,
    .swiper-button-prev {
      color: rgba(109, 105, 105, 0.2) !important;
    }

    .swiper-button-disabled {
      color: rgba(109, 105, 105, 0.15) !important;
    }

    a.active {
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      z-index: -1;
      left: 0;
      right: 100%;
      bottom: 0;
      background: #9a6b50;
      height: 1px;
    }
  }

  .tooltip {
  position:relative; /* making the .tooltip span a container for the tooltip text */
}

.tooltip:before {
  content: attr(data-text); /* here's the magic */
  position:absolute;
  opacity:0;
  transition:.3s opacity;  
  
  /* vertically center */
  bottom: -100%;
  
  /* move to right */
right: 0;
  
  /* basic styles */
  width: auto;
  padding:5px;
  border-radius:3px;
  background:#fff;
  color: #000;
  text-align:center;
  text-transform: lowercase;


}
.tooltip:hover:before {
  opacity:1;
}
`;

export default GlobalStyle;
