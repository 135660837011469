import { cartSingleItemPush } from "../../utils/functions/dataLayerPush";
import * as storeService from "./storeService";
import { Action, CartPayload } from "../../utils/types/states";
import React, { Dispatch } from "react";
import { Cart, Order } from "../../utils/types/dto";
import { getOrder } from "./storeService";
import { IAPIError } from "@on/model-store";
import { isApiError } from "./commonService";
import { translate, TranslateParamsKeyOnly } from "../../utils/functions/translationComponent";
import { isPromotionAppliedOrApplicable } from "../promotion/renderPromotions";
import { translateApiError } from "../../utils/functions/translation";
import { getUrlParams } from "../../utils/functions/common";

function pollQueryStringParams() {
  const queryStringParams = getUrlParams();
  if (queryStringParams) {
    const cartRecoveryToken = queryStringParams.get("cart_recovery_token") ?? undefined;
    const registrationConfirmationToken = queryStringParams.get("registration_confirmation_token") ?? undefined;
    const smsToken = queryStringParams.get("sms") ?? undefined;
    const messageCode = queryStringParams.get("message_code") ?? undefined;

    if (cartRecoveryToken) {
      queryStringParams.delete("cart_recovery_token");
    }
    if (registrationConfirmationToken) {
      queryStringParams.delete("registration_confirmation_token");
    }
    if (smsToken) {
      queryStringParams.delete("sms");
    }
    if (messageCode) {
      queryStringParams.delete("message_code");
    }

    if (cartRecoveryToken || registrationConfirmationToken || smsToken) {
      const urlWithoutQueryString = window.location.href.split("?")[0];
      const newUrl = `${urlWithoutQueryString}?${queryStringParams.toString()}`;
      window.history.replaceState({}, document.title, newUrl);
    }
    return { cartRecoveryToken: cartRecoveryToken || smsToken, registrationConfirmationToken, messageCode: messageCode as TranslateParamsKeyOnly | undefined };
  }
  return { cartRecoveryToken: undefined, registrationConfirmationToken: undefined, messageCode: undefined };
}

export const fetchCart = async (dispatch: React.Dispatch<Action>) => {
  const { cartRecoveryToken, registrationConfirmationToken, messageCode } = pollQueryStringParams();

  let cart: CartPayload;
  if (registrationConfirmationToken || cartRecoveryToken) {
    const response = await storeService.getCartWithToken({ registrationConfirmationToken, cartRecoveryToken });
    if (isApiError(response)) {
      cart = await storeService.getCart();
      cart.globalMessage = {
        message: translateApiError(response),
        type: "ERROR",
      };
    } else {
      cart = response;
      cart.globalMessage = {
        message: translate({ key: messageCode ?? (registrationConfirmationToken ? "account.confirmed" : "cart.recovered") }) as string,
        type: "INFO",
      };
    }
  } else {
    cart = await storeService.getCart();
  }

  dispatch({
    type: cartRecoveryToken ? "CART_RECOVERED" : "CART_LOADED",
    payload: cart,
  });
};

export const reloadCart = async (dispatch: React.Dispatch<Action>): Promise<void> => {
  const cart = await storeService.getCart();
  dispatch({
    type: "CART_LOADED",
    payload: cart,
  });
};

export const addToCart = async (params: { productId: string; quantity: number; subProductsReplacementForced: boolean }, dispatch: React.Dispatch<Action>): Promise<Cart> => {
  const cart = await storeService.addToCart({ product_id: params.productId }, params.quantity, params.subProductsReplacementForced);

  if (typeof window !== "undefined") {
    cartSingleItemPush(cart, params.productId);
  }
  const additionalData = { productId: params.productId, quantity: params.quantity };
  dispatch({ type: "ADD_ITEM", payload: { ...cart, additionalData } });
  return cart;
};

export const setQuantity = async (
  productId: string,
  //lineId: string,
  quantity: number,
  dispatch: React.Dispatch<Action>,
  increasing = true
) => {
  const currentCart = await storeService.updateItem({ product_id: productId }, quantity, increasing);
  // if (!increasing) {
  //   const { cart } = await adjustGiftPackingQuantity(currentCart, lineId, quantity);
  //   currentCart = cart;
  // }
  const additionalData = { increasing, productId, quantity };
  dispatch({ type: "SET_QUANTITY", payload: { ...currentCart, additionalData } });
};

export const applyCoupon = async (couponCode: string, dispatch: Dispatch<Action>) => {
  const newCart = await storeService.applyCoupon(couponCode);
  if (isApiError(newCart)) {
    dispatch({ type: "APPLY_COUPON_FAILED", payload: { errorMessage: translateApiError(newCart) } });
  } else {
    dispatch({ type: "APPLY_COUPON", payload: newCart });
  }
};

export const removeCoupon = async (couponCode: string, dispatch: Dispatch<Action>) => {
  const newCart = await storeService.removeCoupon(couponCode);
  if (isApiError(newCart)) {
    // TODO: handle error
  } else {
    dispatch({ type: "REMOVE_COUPON", payload: newCart });
  }
};

export const getOrderConfirmation = async (orderId: string): Promise<Order | IAPIError> => {
  return await getOrder(orderId);
};

export const isFreeShippingAppliedOrApplicable = (cart: Cart): boolean => {
  return (
    cart.discounts.some((discount) => discount.type === "FREE_SHIPPING") ||
    cart.promotions.filter((promotion) => promotion.discountType === "FREE_SHIPPING").some((freeShippingPromo) => isPromotionAppliedOrApplicable(freeShippingPromo, cart))
  );
};
