import React from "react";
import PostMdxWrapper from "../../styles/wrappers/PostMdxWrapper";
import Markdown from "markdown-to-jsx";

interface Props {
  children: string;
  className?: string;
}

const ProductItemRenderer = ({ children, className }: Props) => {
  return (
    <PostMdxWrapper className={className}>
      <Markdown>{children}</Markdown>
    </PostMdxWrapper>
  );
};

export default ProductItemRenderer;
