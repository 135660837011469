import React from "react";
import styled, { css } from "styled-components";
import { ProductProps } from "../../../utils/types/graphQLTypes";
import { theme } from "../../../styles/theme/theme";

const PromoBadge = styled.div<{ $positionBottom?: string; $positionRight?: string; $label: string }>`
  position: absolute;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  bottom: ${(props) => props.$positionBottom || "80%"};
  right: ${(props) => props.$positionRight || "0"};
  background: ${theme.colors.primary};
  padding: 0.2em 0.6em;
  font-weight: ${theme.fonts.fontWeight.normal};
  font-size: ${theme.fonts.fontSize.xl};
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: ${theme.colors.primary};
  z-index: 10;
  ${({ $label }) =>
    $label === "NEW" &&
    css`
      background: none;
      color: ${theme.colors.primary};
      border-style: dotted;
      font-style: italic;
      font-weight: 600;
      border-width: 2px;
    `}
  ${({ $label }) =>
    $label === "BESTSELLER" &&
    css`
      background: none;
      color: ${theme.colors.primary};
      font-weight: 600;
    `} /*  ::after {
    content: "";
    position: absolute;
    top: -8px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 0 0 6px;
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(252, 199, 155, 1);
    right: 0;
  }  */
`;

interface Props {
  position?: { bottom?: string; right?: string };
  label: ProductProps["label"];
}

const ProductPromoBadge = ({ position, label }: Props) => {
  const getText = (label: "BESTSELLER" | "NEW" | "PROMOTION") => {
    if (label === "NEW") {
      return "NOWOŚĆ!";
    }
    if (label === "PROMOTION") {
      return "PROMOCJA";
    }
    return label;
  };

  return (
    <>
      {label && (
        <PromoBadge $positionBottom={position?.bottom} $positionRight={position?.right} $label={label}>
          {getText(label)}
        </PromoBadge>
      )}
    </>
  );
};

export default ProductPromoBadge;
