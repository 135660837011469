import { Cart } from "../../utils/types/dto";
import { save } from "./storeService";
import { IAPIError } from "@on/model-store";

export interface LoginDataForm {
  Email: string;
  Password: string;
  EmailPassword: string;
}

export interface ResetPasswordFormData {
  password: string;
  token: string;
  confirm_password: string;
}

export const login = async (data: LoginDataForm): Promise<Cart | IAPIError> => {
  return await save({
    account: {
      email: data.Email.toLowerCase(),
      password: data.Password,
    },
  });
};

export const loginFB = async (data: { email: string; facebookUserId: string }): Promise<Cart | IAPIError> => {
  return await save({
    account: {
      email: data.email.toLowerCase(),
      facebookUserId: data.facebookUserId,
    },
  });
};

export const loginGoogle = async (jwtToken: string): Promise<Cart | IAPIError> => {
  return await save({
    account: {
      googleJWTToken: jwtToken,
    },
  });
};

export const doLogout = async (): Promise<Cart | IAPIError> => {
  return await save({
    logout: true,
  });
};
