import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

const FadeIn = keyframes`
  0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

const FadeOut = keyframes`
  0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
`;

//TODO tune animation duration
const FadeInDiv = styled.div`
  animation: ${FadeIn} 0.5s;
`;

const FadeOutDiv = styled.div`
  animation: ${FadeOut} 0.5s;
`;

interface DataProps {
  startAnimation: boolean;
  updateMiniCartCallback: () => void;
  animationFinishedCallback: () => void;
  children: React.ReactElement;
}

const MiniCartAnimation = ({ startAnimation, updateMiniCartCallback, animationFinishedCallback, children }: DataProps): React.ReactElement => {
  const [animationPhase, setAnimationPhase] = useState(startAnimation ? "BEFORE_UPDATE" : "NO_ANIMATION");

  useEffect(() => {
    if (startAnimation) {
      setAnimationPhase("BEFORE_UPDATE");
    }
  }, [startAnimation]);

  useEffect(() => {
    if (animationPhase === "UPDATING") {
      updateMiniCartCallback();
      setAnimationPhase("AFTER_UPDATE");
    }
  }, [animationPhase, updateMiniCartCallback, setAnimationPhase]);

  const onAnimationBeforeUpdate = () => {
    setAnimationPhase("UPDATING");
  };

  const onAnimationAfterUpdate = () => {
    setAnimationPhase("NO_ANIMATION");
    animationFinishedCallback();
  };

  if (animationPhase === "BEFORE_UPDATE") {
    return <FadeOutDiv onAnimationEnd={onAnimationBeforeUpdate}>{children}</FadeOutDiv>;
  } else if (animationPhase === "UPDATING") {
    return <></>;
  } else if (animationPhase === "AFTER_UPDATE") {
    return <FadeInDiv onAnimationEnd={onAnimationAfterUpdate}>{children}</FadeInDiv>;
  } else {
    return children;
  }
};

export default MiniCartAnimation;
