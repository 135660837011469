import React, { useContext } from "react";
import styled from "styled-components";
import { IconLogOut } from "../../../assets/icons";
import { CartContext } from "../../cart/cartState";
import * as PropTypes from "prop-types";
import { CartContextState } from "../../../utils/types/states";
import { doLogout } from "../../service/loginService";

const LogoutWrapper = styled.div`
  cursor: pointer;
`;

LogoutWrapper.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
};

const IconLogout = () => {
  const { state } = useContext<CartContextState>(CartContext);

  const logout = async () => {
    await doLogout();
    if (typeof window !== "undefined") {
      window.location.href = "/logout";
    }
  };

  return (
    <>
      {state?.account_logged_in && (
        <LogoutWrapper onClick={async () => await logout()} className="logout-button" data-testid={"logout-link-icon"}>
          <IconLogOut className="navbar_icon" />
        </LogoutWrapper>
      )}
    </>
  );
};

export default IconLogout;
