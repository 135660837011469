import React from "react";
import { DiscountType, ErrorData, IAPIError } from "@on/model-store";

const plTranslation = (errorData: ErrorData): string => {
  switch (errorData.errorMsgCode) {
    case "account.registration.token.expired":
      return "Nie udało się potwierdzić konta. Token wygasł, zarejestruj się ponownie";
    case "account.reset.password.invalid.token":
      return "Nie udało się zresetować hasła. Nieprawidłowy token. Poproś o zmianę hasła jeszcze raz";
    case "account.reset.password.token.expired":
      return "Nie udało się zresetować hasła. Token wygasł. Poproś o zmianę hasła jeszcze raz";
    case "account.already.confirmed":
      return "Konto już zostało potwierdzone.";
    case "account.not.confirmed":
      return "Konto nie zostało jeszcze aktywowane.";
    case "account.not.found":
      return "Konto nie zostało znalezione.";

    case "cart.recovery.token.not.applicable":
      return "Nie udało się odzyskać koszyka. Koszyk jest przypisany do innego konta.";
    case "cart.recovery.token.not.found":
      return "Nie udało się odzyskać koszyka. Nieprawidłowy token.";

    case "coupon.not.active.yet":
      return `Kupon będzie aktywny od ${new Date(Number(errorData.params.dateFrom)).toLocaleString()}`;
    case "coupon.expired":
      return `Kupon wygasł ${new Date(Number(errorData.params.dateTo)).toLocaleString()}`;
    case "coupon.not.applicable.to.cart.min.value.not.reached":
      return `Kupon jest ważny dla zamówień powyżej ${errorData.params.minCartValue} zł`;
    case "coupon.not.applicable.to.cart.no.applicable.products":
      return `Kupon jest ważny dla produktów: ${errorData.params.productNames.join(", ")}. Dodaj conajmniej jeden z nich do koszyka`;
    case "coupon.not.found":
      return "Nie znaleziono kuponu";
    case "coupon.not.active":
      return "Kupon nie jest aktywny";
    case "coupon.max.redemptions.reached":
      return "Kupon został już wykorzystany";
    case "coupon.max.redemptions.per.user.reached":
      return "Kupon został już wykorzystany przez to konto";
    case "coupon.not.applicable.to.account":
      return "Kupon nie jest ważny dla tego konta";
    case "coupon.not.applicable.to.empty.cart":
      return "Kupon nie jest ważny dla pustego koszyka";
    case "coupon.not.applicable.unknown.reason":
      return "Nie można zastosować kuponu";
    case "coupon.freeshipping.discount.already.applied":
      return "Zniżka na darmową wysyłkę już została zaplikowana";
    case "coupon.freeshipping.no.shipping.selected":
      return "Wybierz najpierw metodę dostawy, następnie zastosuj kupon na darmową wysyłkę";
    case "coupon.freeshipping.not.applicable.for.codshipping":
      return "Darmowa wysyłka nie jest dostępna dla wybranej metody płatności przy odbiorze";

    case "click.confirmation.link":
      return "Znajdź wiadomość email i kliknij link potwierdzający.";
    case "email.already.registered":
      return "Konto z tym adresem email już istnieje.";
    case "email.too.long":
      return "Adres email jest za długi.";
    case "email.not.found":
      return "Nie znaleziono adresu email.";
    case "firstname.too.long":
      return "Imię jest za długie.";
    case "incorrect.parameter.orderid":
      return "Nieprawidłowy parametr orderid.";
    case "invalid.email":
      return "Nieprawidłowy adres email.";
    case "invalid.email.or.password":
      return "Nieprawidłowy adres email lub hasło.";
    case "invalid.token":
      return "Nieprawidłowy token.";
    case "invalid.email.or.facebookuserid":
      return "Nieprawidłowy email użytkownika lub facebook id.";
    case "invalid.email.or.googleuserid":
      return "Nieprawidłowy email użytkownika lub google id.";
    case "invalid.userid":
      return "Nieprawidłowy parametr user id.";
    case "lastname.too.long":
      return "Nazwisko jest za długie.";
    case "not.logged.in":
      return "Nie jesteś zalogowany.";
    case "order.not.found":
      return "Zamówienie nie zostało znalezione.";
    case "password.too.short":
      return "Hasło jest za krótkie.";
    case "passwords.dont.match":
      return "Hasła nie są takie same.";
    case "product.not.found":
      return "Produkt nie został znaleziony.";

    case "payment.cart.modified.concurrently":
      return "Koszyk został zmodyfikowany w innej zakładce. Odśwież stronę i spróbuj ponownie";
    case "payment.cart.not.found":
      return "Nie znaleziono koszyka";
    case "payment.missing.email":
      return "Brak adresu email";
    case "payment.missing.query.params":
      return "Brak parametrów";
    case "payment.unknown.error":
      return "Nieznany błąd";
    case "payment.order.not.found":
      return "Nie znaleziono zamówienia";
    case "payment.shipping.invalid":
      return "Nieprawidłowy sposób płatności dla wybranej metody dostawy";
    case "session.not.found":
      return "Nie znaleziono sesji koszyka";
    case "shipping.method.not.found":
      return "Nie znaleziono metody dostawy.";
    case "shipping.service.not.found":
      return "Nie znaleziono metody dostawy.";

    case "unknown.error":
      return "Wystąpił nieznany błąd";
  }
};

export const translateApiError = (error: IAPIError): string => {
  return translateErrorData(error.errorData);
};

export const translateErrorData = (errorData: ErrorData): string => {
  return plTranslation(errorData);
};
