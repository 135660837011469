import styled from "styled-components";
import { linkBorderOut } from "../mixins";
import { theme } from "../theme/theme";

const PostMdxWrapper = styled.article`
  p {
    margin-bottom: ${theme.spaces.margin.big};
    line-height: 1.9;
  }

  blockquote {
    position: relative;
    display: inline-flex;
    margin-bottom: ${theme.spaces.margin.base};

    ::after {
      position: absolute;
      content: "";
      height: 100%;
      width: 5px;
      top: 0;
      left: 0;
      background-color: ${theme.colors.primary_opacity};
    }
  }

  ul {
    list-style: disc;
    padding-left: ${theme.spaces.padding.big};
  }

  li {
    padding: ${theme.spaces.padding.small};
  }

  strong {
    font-weight: bolder;
  }

  img {
    margin: ${theme.spaces.margin.xxl} 0;
    padding: ${theme.spaces.padding.big} 0;
  }

  h2 {
    margin: ${theme.spaces.margin.base} 0;
    padding: ${theme.spaces.padding.base} 0;
  }

  h3 {
    color: ${theme.colors.black};
    font-size: 1.4em;
    font-style: italic;
  }

  a {
    ${linkBorderOut}
  }
`;

export default PostMdxWrapper;
