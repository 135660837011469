import React from "react";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import styled from "styled-components";
import FadeIn from "../../../styles/mixins/fadeIn";
import { theme } from "../../../styles/theme/theme";
import { financial } from "../../../utils/functions/financial";
import Link from "./Link";
/* import { dataLayerItemPush } from "../utils/functions/dataLayer" */

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  text-align: center;
  animation: ${FadeIn} ease 2s;

  h3 {
    justify-self: center;
    align-self: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: ${theme.spaces.margin.big};
    font-weight: ${theme.fonts.fontWeight.light};
    font-size: ${theme.fonts.fontSize.lg};

    @media (min-width: 1300px) {
      margin-top: ${theme.spaces.margin.xl};
      letter-spacing: 3px;
      font-size: ${theme.fonts.fontSize.xl};
    }
  }

  .price-text {
    font-weight: ${theme.fonts.fontWeight.light};
    color: ${theme.colors.secondary};
    font-size: ${theme.fonts.fontSize.lg};
    opacity: 0.8;
  }
`;

const ProductImage = styled(GatsbyImage)`
  justify-self: center;
  width: 350px !important;
  /* height: auto; */
`;

interface ProductSliderItemProps {
  product: {
    name: string;
    slug: string;
    price: string;
    images: {
      fileLocal: ImageDataLike;
    }[];
  };
}

const ProductSliderItem = ({ product }: ProductSliderItemProps) => {
  const image = getImage(product.images[0].fileLocal);
  if (!image) {
    throw new Error(`Missing image for product ${product.name}`);
  }

  return (
    <Wrapper>
      <Link
        to={`/sklep/${product.slug}`}
        /* onClick={() =>
          dataLayerItemPush(
            product,
            "view_item",
            `${product.title}`,
            "item-details-page"
          )
        } */
      >
        <ProductImage image={image} alt={product.name} />
      </Link>
      <div>
        <h3>{product.name}</h3>
        <p className="price-text">{financial(product.price, "PLN")} </p>
      </div>
    </Wrapper>
  );
};

export default ProductSliderItem;
