import { Currency, InPostPoint } from "@on/model-store";
import { OrlenWidget, OrlenWidgetClass } from "../../components/checkout/helpers/checkoutShippingOrlenGeoWidgetTypes";

export type DataLayerEvent = "purchase" | "begin_checkout" | "view_item" | "view_item_list" | "add_to_cart";

export type DataLayerECommerce = {
  items: {
    item_name: string;
    item_id: string;
    currency: Currency;
    price: number;
    discount: number;
    quantity: number;
  }[];
};

type DataLayer =
  | {
      ecommerce: null;
    }
  | {
      event: DataLayerEvent;
      currency: Currency;
      eventCallback?: () => void;
      value: number;
      ecommerce: DataLayerECommerce;
    }
  | {
      event: DataLayerEvent;
      eventCallback?: (id: string) => void;
      currency: "PLN";
      item_list_name: string;
      ecommerce: DataLayerECommerce;
    }
  | {
      event: DataLayerEvent;
      eventCallback?: (id: string) => void;
      value: number;
      currency: "PLN";
      transaction_id?: string;
      user?: string; //email
      tax: string;
      coupon?: string;
      num_items?: number;
      ecommerce: DataLayerECommerce;
    };

export type WindowAccess = Window &
  typeof globalThis & {
    intervalToClear?: { id: NodeJS.Timeout; location: string };
    searchTimeouts?: NodeJS.Timeout[];
    blikTimer?: number;
    messageDisplayed?: string;
    yotpoLoaded?: boolean;
    yotpo?: {
      refreshWidgets: () => void;
    };
    eventstream: any;
    dataLayer: DataLayer[];
    Tracking: any;
    Common: any;
    loadingMethod: string;
    geoWidgetCallback: (point: InPostPoint) => void;
    geoWidgetCallbackInternal: (point: InPostPoint) => void;
    geoOrlenWidget: OrlenWidget;
    RuchWidget: OrlenWidgetClass;
    googleLoginButtonsCount?: number;
    googleLoginButtonCallback: () => void;
    google: {
      accounts: {
        id: {
          initialize: (props: { client_id: string; ux_mode: string; callback: (response: { credential: string }) => void }) => void;
          renderButton: (element: HTMLElement, options: { type: string; width: string }) => void;
        };
      };
    };
    KWKUniJS: {
      conversion: (props: { mclic: "G5134FD1011"; argmon: string; argann: string; argmodp: string; nacur: "PLN"; argbr?: string; gdpr_kwk: number }) => void;
      ptag: (
        props:
          | { zone: "homepage"; gdpr_kwk: number }
          | { zone: "product"; productId: string; categoryId?: string; gdpr_kwk: number }
          | { zone: "listing"; categoryId: string; products: string[]; gdpr_kwk: number }
          | { zone: "basket"; products: { id: string; price: number; quantity: number }[]; currency: "PLN"; gdpr_kwk: number }
          | { zone: "transaction"; products: { id: string; price: number; quantity: number }[]; transactionId: string; currency: "PLN"; gdpr_kwk: number }
      ) => void;
    };
  };

export const getWindow = (): WindowAccess => window as WindowAccess;
