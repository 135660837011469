import * as React from "react";
import { memo } from "react";

function CreditCard(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <path d="M44.5 86.1c-7.6 1.1-20.5 7.3-26.7 12.8C12 104 6.3 112.5 3 121l-2.5 6.5v257L3 391c3.3 8.5 9 17 14.8 22.1 7.2 6.3 19.2 11.7 28.7 13 5.3.6 77.1.9 215 .7l207-.3 6.5-2.3c8.1-3 18.8-10 23.5-15.5 4.6-5.3 8.7-12.7 11.1-19.7 1.8-5.3 1.9-11.1 1.9-133s-.1-127.7-1.9-133c-5.7-17-17.7-29-34.8-35.2l-6.3-2.3-209-.1c-116.4-.1-211.7.2-215 .7zm425.9 23c12.1 4.5 19.3 15.5 20.3 30.9l.6 9H20.7l.5-8.8c.8-12.8 5.1-21.6 13.5-27.3 8.9-6.2-6.9-5.8 221-5.8 208.9-.1 209.2-.1 214.7 2zM490 192v21H22v-42h468v21zm.8 115.7l-.3 72.8-2.6 5.6c-3.3 6.9-8.9 12.7-15.5 16l-4.9 2.4h-423l-4.9-2.4c-6.6-3.3-12.2-9.1-15.5-16l-2.6-5.6-.3-72.8-.3-72.7h470.2l-.3 72.7z" />
      <path d="M387.7 278c-10.2 2.6-19.2 10.4-22.7 19.7-1.9 5.2-2.1 7.4-1.8 23.7.3 16.8.5 18.5 2.7 23 3.3 6.6 9.1 12.2 16 15.5 5.4 2.5 6.3 2.6 22.6 2.6 13.9 0 18-.3 22.3-1.8 7-2.5 15.2-10.1 18.3-16.9 2.3-5 2.4-6.5 2.4-23.8s-.1-18.8-2.4-23.8c-2.9-6.5-11.3-14.3-17.8-16.7-5.6-2.1-33.1-3.1-39.6-1.5zm34.2 22.6c3.9 2.8 5.1 7.3 5.1 19.4 0 19.5-1.6 21-21.2 21-20.9 0-21.8-.8-21.8-21 0-14.1 1-17.7 5.4-19.9 1.2-.5 8.4-1 16.1-1 11.5-.1 14.5.2 16.4 1.5zM69.3 300.2c-5.7 2.8-6.7 12.2-1.7 16.9l2.6 2.4 67.1.3c60.8.3 67.5.1 70.3-1.3 6.1-3.2 7.4-11.7 2.5-16.6l-2.9-2.9-67.9.1c-44.8 0-68.5.4-70 1.1zM70.3 342.5c-3.3 1.4-6.3 5.9-6.3 9.5 0 3.7 3 8.1 6.5 9.6 5 2.1 130.9 2.1 135.9 0 4.3-1.8 6.6-5.1 6.6-9.6s-2.3-7.8-6.6-9.6c-4.9-2.1-131.4-2-136.1.1z" />
    </svg>
  );
}

const MemoCreditCard = memo(CreditCard);
export default MemoCreditCard;
