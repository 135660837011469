import React from "react";
import styled from "styled-components";
import { theme } from "../../../styles/theme/theme";

const Title = styled.h3`
  letter-spacing: 4px;
  text-transform: uppercase;
  font-weight: ${theme.fonts.fontWeight.light};
  font-size: ${theme.fonts.fontSize.xxl};
  margin-bottom: 0.3em;

  @media (min-width: 980px) {
    font-size: ${theme.fonts.fontSize["2xl"]};
  }
`;

interface ProductTitleProps {
  children: React.ReactElement | string;
}

const ProductTitle = ({ children }: ProductTitleProps) => {
  return <Title>{children}</Title>;
};

export default ProductTitle;
