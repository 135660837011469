import React from "react";
import styled, { css } from "styled-components";
import { financial } from "../../../utils/functions/financial";
import { theme } from "../../../styles/theme/theme";

const OuterWrapper = styled.div<{ $isCatPage?: boolean }>`
  display: grid;
  font-weight: ${theme.fonts.fontWeight.light};
  font-size: ${theme.fonts.fontSize.xl};
  white-space: pre-wrap;
  position: relative;
  margin-top: ${({ $isCatPage }) => ($isCatPage ? theme.spaces.margin.large : 0)};
  margin-bottom: ${({ $isCatPage }) => ($isCatPage ? theme.spaces.margin.medium : 0)};
`;

const OmnibusSpan = styled.span<{ $isCatPage?: boolean }>`
  font-size: ${theme.fonts.fontSize.xxs};
  font-weight: ${theme.fonts.fontWeight.light};
  color: ${theme.colors.secondary};
  text-align: center;
  @media (min-width: 980px) {
    ${(props) =>
      props.$isCatPage
        ? css`
            position: absolute;
            bottom: -1em;
            justify-self: center;
          `
        : css`
            text-align: left;
          `};
  }
`;

const Wrapper = styled.div<{ $margin?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ $margin }) => ($margin ? $margin : theme.spaces.margin.large)};
  font-weight: ${theme.fonts.fontWeight.light};
  font-size: ${theme.fonts.fontSize.xl};
  white-space: pre-wrap;
`;

const ProductCapacity = styled.span`
  color: ${theme.colors.font};
  text-transform: uppercase;
  @media (min-width: 980px) {
    margin-right: ${theme.spaces.margin.xxl};
  }
`;

const PriceTextContainer = styled.div<{ $align?: string; $capacity: boolean; $isProductPage: boolean }>`
  text-align: ${({ $align }) => $align};
  @media (min-width: 980px) {
    margin-right: ${({ $capacity, $isProductPage }) => ($isProductPage && !$capacity ? "2.5em" : "unset")};
  }
`;

const PromoPriceText = styled.span<{ $salePrice: boolean; $sliderStyle: boolean }>`
  color: ${theme.colors.secondary};

  ${(props) =>
    props.$salePrice
      ? css`
          color: darkgreen;
          font-weight: ${theme.fonts.fontWeight.normal};
        `
      : css`
          text-decoration-line: line-through;
          margin-right: 0.5em;
        `};

  ${(props) =>
    props.$sliderStyle &&
    css`
      font-weight: ${theme.fonts.fontWeight.light};
      font-size: ${theme.fonts.fontSize.lg};
    `};
`;

const RegularPriceText = styled.span<{ $sliderStyle: boolean }>`
  color: ${theme.colors.secondary};

  ${(props) =>
    props.$sliderStyle &&
    css`
      font-weight: ${theme.fonts.fontWeight.light};
      font-size: ${theme.fonts.fontSize.lg};
      opacity: 0.8;
    `};
`;

interface PriceProps {
  price: number;
  capacity?: string;
  align?: string;
  sale: boolean;
  least_price?: number;
  sale_price?: number;
  sliderStyle?: boolean;
  productName?: string;
  isProductPage?: boolean;
  className?: string;
  margin?: string;
  isCatPage?: boolean;
}

const Price = ({ price, capacity, align, sale, sale_price, sliderStyle, least_price, productName, isProductPage, className, margin, isCatPage }: PriceProps) => {
  const getProperUnit = (productName: string, capacity: string) => {
    if (productName.toLowerCase().includes("mydło") || productName.toLowerCase().includes("mydła")) {
      return "g";
    } else if (capacity.toLowerCase().includes("paczka") || capacity.toLowerCase().includes("bon") || capacity.toLowerCase().includes("zestaw")) {
      return "";
    } else {
      return "ml";
    }
  };
  return (
    <OuterWrapper $isCatPage={isCatPage}>
      <Wrapper className={className} $margin={margin}>
        {sale && sale_price ? (
          <PriceTextContainer $align={align} $capacity={Boolean(capacity)} $isProductPage={isProductPage ?? false}>
            <PromoPriceText $salePrice={false} $sliderStyle={Boolean(sliderStyle)}>
              {financial(price.toFixed(2), "PLN")}
            </PromoPriceText>
            <PromoPriceText $salePrice={true} $sliderStyle={Boolean(sliderStyle)}>
              {financial(sale_price.toFixed(2), "PLN")}
            </PromoPriceText>
          </PriceTextContainer>
        ) : (
          <PriceTextContainer $align={align} $capacity={Boolean(capacity)} $isProductPage={isProductPage ?? false}>
            <RegularPriceText $sliderStyle={Boolean(sliderStyle)}>{financial(price.toFixed(2), "PLN")}</RegularPriceText>
          </PriceTextContainer>
        )}

        {capacity && productName && (
          <ProductCapacity>
            {" "}
            | {capacity} {getProperUnit(productName, capacity)}
          </ProductCapacity>
        )}
      </Wrapper>
      {isProductPage && sale && sale_price && least_price && <OmnibusSpan> Najniższa cena z okresu 30 dni przed obniżką: {financial(least_price.toFixed(2), "PLN")} </OmnibusSpan>}
      {isCatPage && sale && sale_price && least_price && <OmnibusSpan $isCatPage={isCatPage}> Najniższa cena z okresu 30 dni: {financial(least_price.toFixed(2), "PLN")} </OmnibusSpan>}
    </OuterWrapper>
  );
};

export default Price;
