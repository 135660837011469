import * as React from "react";
import { memo } from "react";

function CodIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 504 504" width="3em" height="2em" {...props}>
      <g transform="translate(0.000000,529.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
        <path
          d="M3247 5132 c-805 -957 -1397 -1668 -1394 -1675 1 -5 122 -75 267
-157 291 -163 344 -203 388 -297 23 -50 27 -71 27 -148 0 -77 -4 -98 -27 -147
-59 -126 -176 -207 -311 -215 -76 -5 -78 -4 -606 166 -35 12 -68 21 -72 21 -9
0 22 -27 671 -570 206 -172 382 -320 391 -327 15 -12 35 8 186 189 216 257
579 691 704 839 53 63 309 368 570 679 260 311 499 596 531 634 32 38 58 73
58 77 0 4 -66 63 -147 131 -80 68 -251 211 -378 318 -328 275 -707 593 -726
608 -14 10 -32 -7 -132 -126z m-141 -1103 c186 -57 331 -199 389 -382 97 -306
-92 -610 -407 -657 -135 -20 -271 14 -397 99 -151 103 -251 289 -251 466 1 88
13 142 51 221 55 117 159 207 289 251 89 31 230 31 326 2z"
        />
        <path
          d="M2850 3843 c-14 -9 -37 -27 -52 -41 l-27 -25 -37 28 -36 28 -24 -29
c-61 -72 -61 -72 -24 -103 l34 -28 -13 -55 c-16 -74 -1 -135 45 -182 66 -68
148 -69 269 -2 79 44 97 49 120 32 16 -12 17 -17 6 -45 -16 -38 -79 -96 -129
-119 -20 -9 -39 -17 -40 -18 -2 -2 16 -29 39 -61 24 -32 44 -60 46 -62 4 -7
89 39 115 64 34 31 39 31 68 0 13 -14 28 -25 34 -25 6 1 28 22 49 48 l38 47
-37 32 c-36 32 -37 34 -26 72 28 93 7 171 -61 225 -35 28 -45 31 -110 31 -65
-1 -80 -5 -155 -45 -64 -33 -88 -41 -100 -34 -9 6 -17 15 -19 20 -7 21 44 80
94 109 29 17 52 33 53 37 0 13 -75 117 -85 118 -6 0 -22 -7 -35 -17z"
        />
        <path
          d="M1425 4389 c-78 -21 -354 -99 -578 -163 -176 -51 -244 -66 -293 -66
l-64 0 0 -534 0 -535 48 14 c26 8 117 32 203 54 207 53 203 54 354 -96 50 -49
113 -102 140 -117 54 -30 188 -77 624 -216 156 -49 297 -90 313 -90 56 0 112
24 154 65 72 72 82 177 25 266 -25 40 -54 59 -312 203 -475 266 -481 270 -501
307 -35 66 -13 135 54 170 29 15 58 19 136 19 l98 0 253 301 c138 166 250 304
246 307 -3 3 -137 30 -298 59 -310 57 -515 75 -602 52z"
        />
        <path
          d="M0 4259 c0 -20 58 -654 80 -874 5 -55 17 -179 25 -275 9 -96 18 -190
21 -207 l5 -33 94 0 95 0 0 705 0 705 -160 0 c-156 0 -160 0 -160 -21z"
        />
        <path
          d="M1457 1736 c-126 -45 -181 -191 -118 -315 11 -21 48 -62 83 -92 109
-93 308 -269 443 -393 214 -196 353 -288 525 -348 l95 -33 575 -6 c500 -4 582
-7 625 -22 84 -27 161 -86 216 -164 13 -18 21 -19 89 -12 41 4 307 29 590 55
283 26 518 50 523 53 4 4 -11 52 -32 107 -22 54 -74 187 -116 294 -197 501
-343 701 -580 795 -139 55 -170 57 -805 62 -428 4 -599 2 -639 -7 -62 -14
-124 -64 -147 -118 -35 -86 0 -204 78 -258 l41 -29 315 -5 314 -5 19 -24 c25
-30 24 -52 -2 -85 l-21 -27 -487 4 c-615 4 -572 -6 -899 221 -342 237 -491
337 -526 351 -44 18 -109 19 -159 1z"
        />
        <path
          d="M5095 294 c-174 -17 -616 -58 -960 -90 -154 -14 -285 -27 -292 -29
-8 -2 -11 -31 -10 -87 l2 -83 708 -3 707 -2 0 155 c0 118 -3 155 -12 154 -7
-1 -71 -8 -143 -15z"
        />
      </g>
    </svg>
  );
}

const MemoCodIcon = memo(CodIcon);
export default MemoCodIcon;
