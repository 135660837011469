import { colors } from "./colors";
import { fonts } from "./fonts";
import { spaces } from "./spaces";

export const theme = {
  colors,
  fonts,
  spaces,
} as const;

export type Theme = {
  theme: typeof theme;
};
