import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from "gatsby-plugin-image";

const StyledBanner = styled(GatsbyImage)<{ $height: string; $tag: string }>`
  display: grid;
  height: ${({ $height }) => $height};
  grid-area: 1/1;
  position: relative;
`;

interface DataProps {
  $height: string;
  $tag: string;
  image: ImageDataLike;
}

const BgImage = ({ image, $height, $tag }: DataProps) => {
  const bgImage = getImage(image) as IGatsbyImageData;

  return <StyledBanner loading="lazy" image={bgImage} $height={$height} $tag={$tag} alt="" />;
};

export default BgImage;
