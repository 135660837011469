import React from "react";
import styled from "styled-components";

const Title = styled.h3`
  justify-self: center;
  align-self: center;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fonts.fontWeight.thin};
  font-size: ${({ theme }) => theme.fonts.fontSize.xxl};
  color: ${({ theme }) => theme.colors.font};
`;

const TitleSpan = styled.span`
  margin-top: -2em;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.colors.secondary};
  align-self: end;
  text-align: center;
  text-transform: lowercase;

  :first-letter {
    text-transform: uppercase;
  }
`;

interface Props {
  title: string;
  titleSpan: string;
}

const ProductListItemTitle = ({ title, titleSpan }: Props) => {
  return (
    <>
      <Title dangerouslySetInnerHTML={{ __html: title }} />
      <TitleSpan dangerouslySetInnerHTML={{ __html: titleSpan }} />
    </>
  );
};

export default ProductListItemTitle;
