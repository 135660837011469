import * as React from "react";
import { memo } from "react";

const PerfectMatchTwoPuzzlesColor = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 261 180" enableBackground="new 0 0 261 180" {...props}>
      <g>
        <path
          fill="#AF885B"
          d="M24.877,39.73h40.018c1.432,0,2.596-1.164,2.596-2.595c0-0.359-0.074-0.712-0.223-1.049
		c-0.146-0.326-0.353-0.617-0.62-0.864c-2.063-1.898-3.226-4.487-3.268-7.287c-0.041-2.693,0.979-5.248,2.872-7.196
		c1.89-1.946,4.411-3.038,7.098-3.075c2.731-0.034,5.303,0.995,7.246,2.91c1.943,1.917,3.014,4.474,3.014,7.204
		c0,2.819-1.19,5.534-3.27,7.446c-0.535,0.493-0.84,1.189-0.84,1.911c0,0.693,0.271,1.345,0.763,1.837
		c0.489,0.488,1.142,0.758,1.834,0.758h40.019v40.299c0,0.189-0.159,0.35-0.35,0.35c-0.094,0-0.187-0.041-0.248-0.107
		c-2.286-2.547-5.562-4.007-8.985-4.007c-3.258,0-6.313,1.277-8.601,3.598c-2.289,2.321-3.526,5.396-3.479,8.66
		c0.044,3.215,1.351,6.229,3.675,8.484c2.325,2.258,5.376,3.466,8.593,3.422c3.346-0.047,6.553-1.506,8.798-3.999
		c0.062-0.068,0.154-0.108,0.251-0.108c0.191,0,0.347,0.154,0.347,0.346v40.297H81.815c-0.125,0-0.205-0.06-0.248-0.102
		c-0.063-0.063-0.098-0.153-0.098-0.247c0-0.097,0.038-0.187,0.105-0.248c2.493-2.242,3.951-5.45,4.003-8.798
		c0.048-3.217-1.168-6.271-3.427-8.595c-2.256-2.322-5.27-3.628-8.484-3.674c-3.255-0.035-6.336,1.19-8.656,3.478
		c-2.321,2.289-3.6,5.345-3.6,8.604c0,3.421,1.462,6.697,4.003,8.982c0.068,0.062,0.109,0.158,0.109,0.251
		c0,0.192-0.157,0.349-0.349,0.349H24.877V39.73z"
        />
      </g>
      <g>
        <path
          fill="#AF885B"
          d="M221.318,136.964H181.02c-1.278,0-2.317,1.04-2.317,2.319c0,0.648,0.275,1.271,0.757,1.712
		c2.132,1.916,3.355,4.658,3.355,7.521c0,2.728-1.072,5.284-3.012,7.2c-1.943,1.916-4.521,2.951-7.248,2.913
		c-2.689-0.04-5.211-1.133-7.1-3.078c-1.891-1.948-2.911-4.502-2.871-7.192c0.044-2.803,1.265-5.486,3.352-7.365
		c0.481-0.433,0.757-1.058,0.757-1.711c0-0.62-0.24-1.202-0.672-1.635c-0.435-0.441-1.019-0.685-1.642-0.685h-40.299V96.667
		c0-1.276-1.038-2.315-2.318-2.315c-0.652,0-1.276,0.277-1.711,0.76c-1.879,2.089-4.563,3.309-7.365,3.349
		c-2.685,0.046-5.244-0.975-7.191-2.866c-1.947-1.889-3.041-4.411-3.078-7.1c-0.039-2.73,0.997-5.306,2.913-7.249
		c1.915-1.942,4.472-3.013,7.199-3.013c2.866,0,5.608,1.223,7.522,3.354c0.434,0.483,1.059,0.761,1.714,0.761
		c1.277,0,2.315-1.04,2.315-2.318V39.73h40.301c0.19,0,0.345,0.155,0.345,0.347c0,0.098-0.039,0.189-0.106,0.249
		c-2.493,2.245-3.952,5.45-4.003,8.797c-0.049,3.219,1.167,6.271,3.426,8.598c2.258,2.323,5.27,3.628,8.483,3.674
		c0.058,0,0.116,0,0.175,0c3.196,0,6.202-1.23,8.481-3.479c2.32-2.289,3.6-5.345,3.6-8.604c0-3.423-1.46-6.698-4.004-8.986
		c-0.068-0.06-0.107-0.151-0.107-0.249c0-0.19,0.156-0.346,0.347-0.346h40.299v40.015c0,0.349-0.281,0.63-0.625,0.63
		c-0.09,0-0.177-0.017-0.243-0.046c-0.088-0.04-0.162-0.094-0.222-0.159c-2.269-2.467-5.362-3.854-8.705-3.905
		c-3.213-0.05-6.278,1.172-8.604,3.434c-2.323,2.256-3.624,5.268-3.667,8.479c-0.048,3.26,1.186,6.332,3.475,8.653
		c2.289,2.322,5.346,3.602,8.61,3.602c3.365,0,6.607-1.424,8.894-3.903c0.238-0.26,0.661-0.266,0.905-0.021
		c0.119,0.116,0.183,0.274,0.183,0.441V136.964z"
        />
      </g>
    </svg>
  );
};

const MemoPerfectMatchTwoPuzzlesColor = memo(PerfectMatchTwoPuzzlesColor);
export default MemoPerfectMatchTwoPuzzlesColor;
