import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Link from "../../UI/elements/Link";
import { CategoriesProps, ValidateSubType } from "../../../utils/types/graphQLTypes";
import { staticLinks } from "../../../utils/constants/staticLinks";
import { PerfectMatchPseudoIcon } from "../../navbar/helpers/mobileMenuHelpers";

const query = graphql`
  {
    allStoreCategory(filter: { active: { eq: true }, ID: { ne: "root" } }, sort: { sort: ASC }) {
      nodes {
        id
        slug
        name
      }
    }
    bundles: allStoreProduct(filter: { active: { eq: true }, productType: { eq: "BUNDLE" }, showInNav: { eq: true } }, sort: { priority: ASC }) {
      nodes {
        slug
        tag
      }
    }
  }
`;

type CategoriesPropsLocal = {
  allStoreCategory: {
    nodes: {
      id: string;
      name: string;
      slug: string;
    }[];
  };
};

type BundleProps = {
  bundles: {
    nodes: {
      slug: string;
      tag: string;
    }[];
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TYPE_GUARD2: ValidateSubType<CategoriesPropsLocal, CategoriesProps> = true;

type DataProps = BundleProps & CategoriesPropsLocal;

const NavbarCategories = () => {
  const {
    allStoreCategory: { nodes: categories },
    bundles: { nodes: bundles },
  } = useStaticQuery<DataProps>(query);

  const {
    pages: {
      staticPages: { dopasujKosmetyk, gwiazdka2024 },
    },
  } = staticLinks;

  return (
    <>
      <li className="perfectMatchLink">
        <PerfectMatchPseudoIcon />
        <Link to={dopasujKosmetyk} activeClassName="activeMobile">
          dopasuj kosmetyk
        </Link>
      </li>
      <li>
        <Link to={gwiazdka2024} activeClassName="activeMobile">
          gwiazdka 2024
        </Link>
      </li>
      {categories.map((category) => {
        return (
          <li key={category.id}>
            <Link to={`/sklep/${category.slug}`} activeClassName="activeMobile">
              {category.name}
            </Link>
          </li>
        );
      })}
      {bundles.map((bundle) => {
        return (
          <li key={bundle.slug}>
            <Link to={`${dopasujKosmetyk}/${bundle.slug}`} activeClassName="activeMobile">
              {bundle.tag}
            </Link>
          </li>
        );
      })}
    </>
  );
};

export default NavbarCategories;
