import React from "react";
import { DiscountType } from "@on/model-store";

export type TranslateParamsKeyOnly = "account.confirmed" | "cart.recovered" | "payment.in.progress";

export type TranslateParams =
  | {
      key: TranslateParamsKeyOnly;
    }
  | {
      key: `promotion.${Exclude<DiscountType, "EMPTY">}.min_cart_value.not.fulfilled`;
      params: { diff: string; name: string };
    }
  | {
      key: `promotion.${Exclude<DiscountType, "EMPTY">}.products.not.fulfilled`;
      params: { missingProducts: string; name: string };
    }
  | {
      key: `promotion.${Exclude<DiscountType, "EMPTY">}.fulfilled`;
      params: { name: string };
    };

export const translate = (p: TranslateParams) => {
  switch (p.key) {
    case "account.confirmed":
      return "Konto zostało potwierdzone";
    case "cart.recovered":
      return "Koszyk został odzyskany";
    case "payment.in.progress":
      return "Niedokończono poprzedniej płatności. Koszyk został załadowany ponownie";
    case "promotion.FIXED.fulfilled":
    case "promotion.PERCENTAGE.fulfilled":
    case "promotion.FIXED_PRODUCT.fulfilled":
    case "promotion.PERCENTAGE_PRODUCT.fulfilled":
      return `Gratulacje! Promocja ${p.params.name} aktywna`;
    case "promotion.FREE_SHIPPING.fulfilled":
      return (
        <>
          <span>{"Gratulacje! Darmowa wysyłka aktywna*"}</span>
          <span>{`Nie dotyczy pobrań`}</span>
        </>
      );
    case "promotion.FIXED.min_cart_value.not.fulfilled":
    case "promotion.PERCENTAGE.min_cart_value.not.fulfilled":
    case "promotion.FIXED_PRODUCT.min_cart_value.not.fulfilled":
    case "promotion.PERCENTAGE_PRODUCT.min_cart_value.not.fulfilled":
      return `Do promocji ${p.params.name} brakuje ${p.params.diff}`;
    case "promotion.FREE_SHIPPING.min_cart_value.not.fulfilled":
      return (
        <>
          <span>{`Do darmowej wysyłki brakuje ${p.params?.diff}*`}</span>
          <span>{`Nie dotyczy pobrań`}</span>
        </>
      );
    case "promotion.FIXED.products.not.fulfilled":
    case "promotion.PERCENTAGE.products.not.fulfilled":
    case "promotion.FIXED_PRODUCT.products.not.fulfilled":
    case "promotion.PERCENTAGE_PRODUCT.products.not.fulfilled":
      return `Promocja ${p.params.name} zostanie aktywowana po dodaniu jednego z następujących produktów ${p.params.missingProducts}`;
    case "promotion.FREE_SHIPPING.products.not.fulfilled":
      return (
        <>
          <span>`Dodaj produkty: ${p.params.missingProducts} aby aktywować darmową wysyłkę*`</span>
          <span>{`Nie dotyczy pobrań`}</span>
        </>
      );
  }
};
