import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { ButtonGlobal } from "../../UI/index";
import ProductSliderWrapper from "../../shop/productSlider/ProductSliderWrapper";
import ProductSliderItem from "../../shop/productSlider/ProductSliderItem";
import { borderAfter } from "../../../styles/mixins";
import { SwiperSlide } from "swiper/react";
import { CategoriesProps, ProductProps, ProductsProps, ValidateSubType } from "../../../utils/types/graphQLTypes";
import { ImageDataLike } from "gatsby-plugin-image";
import { Currency } from "@on/model-store";
import { theme } from "../../../styles/theme/theme";

const Wrapper = styled.section`
  margin: ${theme.spaces.margin.vertical} 0;
  padding-bottom: ${theme.spaces.margin.vertical};
  ${borderAfter};

  @media (min-width: 680px) {
    margin: ${theme.spaces.margin.vertical_big} 0;
  }

  button {
    align-self: center;
    margin-top: 3em;
    @media (min-width: 680px) {
      margin-top: 4em;
    }
    @media (min-width: 1400px) {
      align-self: center;
      margin-top: 6em;
    }
  }
`;

const SectionTitle = styled.div`
  display: grid;
  place-items: center;
  text-transform: uppercase;
  margin-bottom: ${theme.spaces.margin.xxl};

  span {
    text-align: center;
    font-weight: ${theme.fonts.fontWeight.thin};
    color: ${theme.colors.titles};
    font-size: ${theme.fonts.fontSize.lg};
    letter-spacing: 2px;
  }

  ul {
    letter-spacing: 3px;
    display: flex;
    flex-direction: row;
    column-gap: 2em;
    color: ${theme.colors.titles};
    font-size: ${theme.fonts.fontSize.xxl};
    font-weight: ${theme.fonts.fontWeight.light};
  }
`;

const StyledItem = styled.li<{ $isActive: boolean; $type: "button" }>`
  cursor: pointer;
  position: relative;
  padding-top: ${theme.spaces.padding.base};
  padding-bottom: ${theme.spaces.padding.small};
  color: ${({ $isActive }) => ($isActive ? "#000" : "#454545")};
  transition: color 1s ease;

  &:after {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    bottom: 0;
    left: 0;
    border-bottom: ${({ $isActive }) => ($isActive ? "1px solid #000" : "none")};
    opacity: ${({ $isActive }) => ($isActive ? "1" : "0")};
    transition: opacity 1s ease;
  }
`;

const ButtonSection = styled.div`
  display: grid;
  place-items: center;
`;

const query = graphql`
  {
    products: allStoreProduct(sort: { name: ASC }, filter: { categories: { elemMatch: { is_front_page_cat: { eq: true } } } }) {
      nodes {
        categories {
          category_id
        }
        ...StoreProductItem
        images {
          fileLocal {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: BLURRED, formats: [AVIF, AUTO, WEBP], breakpoints: [360, 450, 500, 750])
            }
          }
        }
      }
    }
    categories: allStoreCategory(filter: { is_front_page_cat: { eq: true }, ID: { ne: "root" }, active: { eq: true } }, sort: { name: ASC }) {
      nodes {
        name
        slug
        meta_description
        category_id
        active
      }
    }
  }
`;

type ProductsPropsLocal = {
  products: {
    nodes: {
      prices: Record<
        Currency,
        {
          active: boolean;
          currency: string;
          unit_price: number;
          previous_unit_price?: number;
          sale: boolean;
          taxRate: number; // 0.23
        }
      >;
      label: ProductProps["label"];
      name: string;
      slug: string;
      images: {
        fileLocal: ImageDataLike;
      }[];
      product_id: string;
      categories: {
        category_id: string;
      }[];
    }[];
  };
};

type CategoriesPropsLocal = {
  categories: {
    nodes: {
      name: string;
      slug: string;
      meta_description: string;
      category_id: string;
      active: boolean;
    }[];
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TYPE_GUARD: ValidateSubType<ProductsPropsLocal, ProductsProps> = true;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TYPE_GUARD2: ValidateSubType<CategoriesPropsLocal, CategoriesProps> = true;

interface DataProps extends ProductsPropsLocal, CategoriesPropsLocal {}

const Index = () => {
  const {
    products: { nodes: storeProducts },
    categories: { nodes: storeCategories },
  } = useStaticQuery<DataProps>(query);

  const [active, setActive] = useState(0);

  const showProducts = storeProducts
    .filter((item, i, ar) => ar.findIndex((item2) => item2.product_id === item.product_id) === i)
    .filter((product) => product.categories.some((category) => category.category_id === storeCategories[active].category_id));

  return (
    <Wrapper>
      <SectionTitle>
        <span>skorzystaj z dobrodziejstwa natury</span>
        <ul>
          {storeCategories.map((category, index) => {
            return (
              <StyledItem key={category.category_id} $type="button" onClick={() => setActive(index)} $isActive={active === index}>
                {category.name}
              </StyledItem>
            );
          })}
        </ul>
      </SectionTitle>
      <ProductSliderWrapper itemsNumber={showProducts.length}>
        {showProducts.map((item) => {
          return (
            <SwiperSlide key={item.product_id}>
              <ProductSliderItem key={item.product_id} product={item} position={{ right: "15px", bottom: "90%" }} />
            </SwiperSlide>
          );
        })}
      </ProductSliderWrapper>
      <ButtonSection>
        <ButtonGlobal text={`zobacz kosmetyki ${storeCategories[active].name}`} link={`/sklep/${storeCategories[active].slug}`} />
      </ButtonSection>
    </Wrapper>
  );
};

export default Index;
