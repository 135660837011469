import React, { PropsWithChildren, useEffect, useReducer } from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "../../styles/theme/theme";
import { fetchCart } from "../service/cartService";
import { getInitialState, reducer, CartContext } from "../cart/cartState";
import { QueryClient, QueryClientProvider } from "react-query";
import GlobalStyle from "../../styles/GlobalStyle";
import { CartState } from "../../utils/types/states";
import { Script } from "gatsby";
import { clearIntervals } from "../../utils/functions/common";

const queryClient = new QueryClient();
let isRootLayoutEffectExecuted = false;

const RootLayout = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, getInitialState() as CartState);
  clearIntervals();
  useEffect(() => {
    if (isRootLayoutEffectExecuted) {
      return;
    }
    isRootLayoutEffectExecuted = true;
    fetchCart(dispatch)
      .then((_value) => _value)
      .catch(() => {});
    // eslint-disable-next-line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isRUMEnabled =
    process.env.GATSBY_RUM_ENABLED === "true" &&
    process.env.GATSBY_RUM_APPLICATION_ID &&
    process.env.GATSBY_RUM_APPLICATION_VERSION &&
    process.env.GATSBY_RUM_APPLICATION_REGION &&
    process.env.GATSBY_RUM_GUEST_ROLE_ARN &&
    process.env.GATSBY_RUM_IDENTITY_POOL_ID &&
    process.env.GATSBY_RUM_ENDPOINT;

  const rumTelemetries = [];
  if (process.env.GATSBY_RUM_TELEMETRY_ERRORS === "true") {
    rumTelemetries.push("errors");
  }
  if (process.env.GATSBY_RUM_TELEMETRY_PERFORMANCE === "true") {
    rumTelemetries.push("performance");
  }
  if (process.env.GATSBY_RUM_TELEMETRY_HTTP === "true") {
    rumTelemetries.push("http");
  }

  return (
    <>
      {isRUMEnabled && (
        <Script
          id="rum-script"
          dangerouslySetInnerHTML={{
            __html: `
              (function(n,i,v,r,s,c,x,z){x=window.AwsRumClient={q:[],n:n,i:i,v:v,r:r,c:c};window[n]=function(c,p){x.q.push({c:c,p:p});};z=document.createElement('script');z.async=true;z.src=s;document.head.insertBefore(z,document.head.getElementsByTagName('script')[0]);})(
              'cwr',
              '${process.env.GATSBY_RUM_APPLICATION_ID}',
              '${process.env.GATSBY_RUM_APPLICATION_VERSION}',
              '${process.env.GATSBY_RUM_APPLICATION_REGION}',
              'https://client.rum.us-east-1.amazonaws.com/1.15.0/cwr.js',
              {
                sessionSampleRate: ${process.env.GATSBY_RUM_SESSION_SAMPLE_RATE || 0.1},
                guestRoleArn: "${process.env.GATSBY_RUM_GUEST_ROLE_ARN}",
                identityPoolId: "${process.env.GATSBY_RUM_IDENTITY_POOL_ID}",
                endpoint: "${process.env.GATSBY_RUM_ENDPOINT}",
                telemetries: ${JSON.stringify(rumTelemetries)},
                allowCookies: true,
                enableXRay: false
              }
            );`,
          }}
        />
      )}

      <ThemeProvider theme={theme}>
        <CartContext.Provider value={{ state: state as (CartState & { isCartLoaded: true }) | (Partial<CartState> & { isCartLoaded: false }), dispatch }}>
          <QueryClientProvider client={queryClient}>
            <GlobalStyle />
            {children}
          </QueryClientProvider>
        </CartContext.Provider>
      </ThemeProvider>
    </>
  );
};

export default RootLayout;
