import { getWindow, WindowAccess } from "../types/window";

export const onInteraction = (callback: () => void, windowProperty?: keyof WindowAccess): void => {
  const initScriptWrapper = (event: Event) => {
    initScriptInternal();
    event.currentTarget?.removeEventListener(event.type, initScriptWrapper);
  };
  const initScriptInternal = () => {
    if (windowProperty) {
      if (getWindow()[windowProperty]) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      getWindow()[windowProperty] = true;
    }
    callback();
  };
  if (typeof window !== "undefined") {
    document.addEventListener("scroll", initScriptWrapper);
    document.addEventListener("mousemove", initScriptWrapper);
    document.addEventListener("touchstart", initScriptWrapper);
  }
};

export const loadScriptOnInteraction = (url: string, windowProperty: keyof WindowAccess): void => {
  onInteraction(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = url;
    document.head.appendChild(script);
  }, windowProperty);
};
