import styled from "styled-components";
import { linkBorderOut } from "../mixins";
import { theme } from "../theme/theme";

const ProductMdxWrapper = styled.div`
  p {
    margin-bottom: ${theme.spaces.margin.big};
    line-height: 1.8;
  }

  hr {
    margin-top: 2em;
    margin-bottom: 1em;
    opacity: 0.2;
  }

  ul {
    list-style: disc;
    padding-left: ${theme.spaces.padding.big};
  }

  em {
    letter-spacing: 0.1em;
    font-weight: 700;
  }

  li {
    padding: ${theme.spaces.padding.small};
    font-size: ${theme.fonts.fontSize.xl};
    letter-spacing: 1px;
  }

  strong {
    display: block;
    font-weight: ${theme.fonts.fontWeight.normal};
    font-size: ${theme.fonts.fontSize.xl};
    margin-bottom: ${theme.spaces.margin.medium};
    letter-spacing: 0.15em;
  }

  h3 {
    text-align: center;
    letter-spacing: 2px;
    color: ${theme.colors.font};
    font-weight: ${theme.fonts.fontWeight.light};
    padding: ${theme.spaces.padding.medium} 0;

    @media (min-width: 800px) {
      text-align: left;
    }
  }

  a {
    ${linkBorderOut}
  }
`;

export default ProductMdxWrapper;
