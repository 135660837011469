import React from "react";
import { slugify } from "../../utils/functions/slugify";
import Link from "../UI/elements/Link";

interface Props {
  tags: string[];
}

const TagCloud = ({ tags }: Props) => {
  return (
    <>
      {tags.map((tag) => (
        <Link key={tag} to={`/blog/${slugify(tag)}`} className="tag-link">
          <span>{tag}</span>
        </Link>
      ))}
    </>
  );
};

export default TagCloud;
