import * as React from "react";
import { memo } from "react";

function IconLogOut(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg imageRendering="optimizeQuality" textRendering="geometricPrecision" shapeRendering="geometricPrecision" viewBox="0 0 95.967 89.563" {...props}>
      <path d="M81.278 67.715C73.684 79.71 60.306 87.67 45.063 87.67c-11.83 0-22.53-4.791-30.283-12.543C7.029 67.374 2.237 56.674 2.236 44.844c0-11.829 4.792-22.53 12.544-30.283C22.533 6.81 33.234 2.019 45.063 2.018c15.015 0 28.22 7.724 35.869 19.419l1.633-1.067C74.573 8.147 60.758.068 45.063.068 20.333.068.287 20.114.287 44.844c0 24.731 20.046 44.775 44.776 44.776 15.934 0 29.929-8.326 37.862-20.861l-1.647-1.044z" />
      <path d="M33.415 45.818h60.259v-1.949H33.415" />
      <path d="M86.24 52.818l-1.331-1.427 7.042-6.545-7.042-6.546 1.331-1.428 8.574 7.974z" />
    </svg>
  );
}

const MemoIconLogOut = memo(IconLogOut);
export default MemoIconLogOut;
