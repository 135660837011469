import { useState } from "react";
import { onInteraction } from "../functions/scriptLoad";

export function useDidInteract(): boolean {
  const [interacted, setInteracted] = useState(false);
  onInteraction(() => {
    setInteracted(true);
  });

  return interacted;
}
