import { useState, useEffect } from "react";
import throttle from "lodash/throttle";

export function useScroll<B extends boolean>(topOrNotOnly: B): B extends true ? boolean : number;
export function useScroll(topOrNotOnly: boolean): boolean | number {
  const [windowScroll, setWindowScroll] = useState(topOrNotOnly ? false : 0);

  function changeWindowScroll(mounted: boolean) {
    if (typeof window !== `undefined` && mounted) {
      if (windowScroll !== window.pageYOffset) {
        setWindowScroll(topOrNotOnly ? window.pageYOffset > 0 : window.pageYOffset);
      }
    }
  }

  useEffect(() => {
    let mounted = true;

    if (typeof window !== `undefined`) {
      window.addEventListener(
        "scroll",
        throttle(function () {
          changeWindowScroll(mounted);
        }, 200)
      );

      return () => {
        mounted = false;

        window.removeEventListener("scroll", function () {
          changeWindowScroll(mounted);
        });
      };
    }
  }, [changeWindowScroll]);

  return topOrNotOnly ? Boolean(windowScroll) : (windowScroll as number);
}
