import React from "react";
import styled from "styled-components";
import "swiper/swiper-bundle.css";
import Swiper, { ReactIdSwiperChildren } from "react-id-swiper";

//SwiperCore.use([Navigation]);

const StyledSlider = styled(Swiper)`
  position: relative;
  top: 0;
  width: 350px;
  height: auto;
  justify-self: center;

  @media (min-width: 750px) {
    width: 90%;
    max-width: 1800px;
  }
`;

interface Props {
  itemsNumber: number;
  children: ReactIdSwiperChildren | undefined;
}

const ProductSlider = (props: Props) => {
  const numberOfItems = props.itemsNumber;
  return (
    <StyledSlider
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
      slidesPerView={1}
      shouldSwiperUpdate={true}
      spaceBetween={30}
      //loopFillGroupWithBlank={true}
      breakpoints={{
        // when window width is >= 640px
        750: {
          slidesPerView: numberOfItems < 2 ? numberOfItems : 2,
        },
        // when window width is >= 768px
        1120: {
          slidesPerView: numberOfItems < 3 ? numberOfItems : 3,
        },
        // when window width is >= 1300px
        1500: {
          slidesPerView: numberOfItems < 4 ? numberOfItems : 4,
        },
      }}
    >
      {props.children}
    </StyledSlider>
  );
};

export default ProductSlider;
