import React from "react";
import { Wrapper, ContentWrapper, ContentParagraph } from "../promotion/promotionsHelpers";
import styled, { keyframes } from "styled-components";
import { Action } from "../../utils/types/states";
import { getWindow } from "../../utils/types/window";

export const heightBounce = keyframes`
  0% {
    transform: scaleY(0);
    max-height: 0px;
  }
  10% {
    transform: scaleY(1);
    max-height: 300px;
  }
  70% {
    transform: scaleY(1);
    max-height: 300px;
  }
  100% {
    transform: scaleY(0);
    max-height: 0px;
  }`;

const FadeInDiv = styled.div`
  transform: scaleY(0);
  max-height: 0px;
  animation: ${heightBounce} 10s;
  animation-delay: 0.1s;
  animation-fill-mode: both;
`;

export const GlobalNavbarMessage = (props: { globalMessage: { message: string; type: "ERROR" | "INFO" }; dispatch: React.Dispatch<Action> }) => {
  const removeGlobalError = () => {
    if (getWindow()) {
      getWindow().messageDisplayed = "";
    }
    props.dispatch({ type: "REMOVE_GLOBAL_MESSAGE", payload: {} });
  };
  const backgroundColor = props.globalMessage.type === "ERROR" ? "#FF9494" : "#9A6B50";
  if (!props.globalMessage.message || getWindow().messageDisplayed === props.globalMessage.message) {
    return <> </>;
  }
  if (getWindow()) {
    getWindow().messageDisplayed = props.globalMessage.message;
  }
  return (
    <FadeInDiv onAnimationEnd={removeGlobalError}>
      <Wrapper color={backgroundColor} $paddingBottom={"0"} $paddingTop={"0"}>
        <ContentWrapper>
          <ContentParagraph color="#fff">{props.globalMessage.message}</ContentParagraph>
        </ContentWrapper>
      </Wrapper>
    </FadeInDiv>
  );
};
