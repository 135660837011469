import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { ButtonAnimation } from "../../../styles/mixins";
import { Theme, theme } from "../../../styles/theme/theme";
import Link from "./Link";

interface StyledButtonProps extends Theme {
  $background?: string;
  $padding?: string;
}

interface DataProps {
  text: string;
  color?: string;
  link: string;
  padding?: string;
  background?: string;
  testid?: string;
}

const StyledButton = styled.button<StyledButtonProps>`
  background: ${(props) => props.$background || "#fff"};
  padding: ${(props) => props.$padding || "1em 2em"};
  text-transform: uppercase;
  color: ${({ theme }: Theme) => theme.colors.primary};
  letter-spacing: 0.1em;
  vertical-align: middle;
  border: 1px solid ${(props) => props.color || theme.colors.primary};
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
  ${ButtonAnimation};

  :focus,
  :enabled,
  :active {
    border: 1px solid ${(props) => props.color || theme.colors.primary};
  }
`;

const Button = ({ text, color, padding, background, link, testid }: DataProps) => {
  return (
    <Link to={link} data-testid={testid}>
      <StyledButton color={color} $padding={padding} $background={background}>
        {text}
      </StyledButton>
    </Link>
  );
};

Button.defaultProps = {
  text: "czytaj więcej",
};

Button.propTypes = {
  text: PropTypes.string,
};

export default Button;
