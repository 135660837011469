import React, { useState } from "react";
import ShopCategoriesMenu from "../shop/menu/ShopCategoriesMenu";
import { staticLinks } from "../../utils/constants/staticLinks";
import { MenuListWrapper, ShopCategoriesMenuWrapper, Close, IconsWrapper, UserIconsWrapper, MenuNavWrapper } from "./helpers/mobileMenuHelpers";
import { MenuListItem } from "./helpers/navbarHelpers";
import { IconUser, IconLogout, IconPerfectMatch } from "../UI";
import Link from "../UI/elements/Link";
import SearchBox from "../searchBox/searchBox";
import { getUrlParams } from "../../utils/functions/common";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  width: number;
  path: string;
}

const MobileMenu = (props: Props) => {
  const {
    pages: {
      staticPages: { blog, sklep, oNas, b2b, kontakt },
    },
  } = staticLinks;
  const isSearchPage = props.path.includes("/search/");
  const [value, setValue] = useState(isSearchPage ? getUrlParams()?.get("q") || "" : "");
  return (
    <MenuNavWrapper open={props.open} data-testid={"navbar-mobile__menu"}>
      <div>
        <IconsWrapper>
          <Close onClick={() => props.setOpen(false)} data-testid={"navbar-mobile__menu--closeIcon"} />
          {props.width < 450 && (
            <UserIconsWrapper data-testid={"navbar-mobile__menu--iconsWrapper"}>
              <IconPerfectMatch />
              <IconLogout data-testid={"navbar-mobile__menu--logoutIcon"} />
              <IconUser data-testid={"navbar-mobile__menu--userIcon"} />
            </UserIconsWrapper>
          )}
        </IconsWrapper>
        <MenuListWrapper>
          {!isSearchPage && <SearchBox value={value} setValue={setValue} $isMobileMenu={true} />}
          <MenuListItem>
            <Link to={sklep} activeClassName="active">
              sklep
            </Link>
          </MenuListItem>
          <ShopCategoriesMenuWrapper>
            <ShopCategoriesMenu />
          </ShopCategoriesMenuWrapper>
          <MenuListItem className="border">
            <Link to={blog} activeClassName="active">
              blog
            </Link>
          </MenuListItem>
          <MenuListItem className="border">
            <Link to={oNas} activeClassName="active">
              o nas
            </Link>
          </MenuListItem>
          <MenuListItem className="border">
            <Link to={kontakt} activeClassName="active">
              kontakt
            </Link>
          </MenuListItem>
          <MenuListItem className="border">
            <Link to={b2b} activeClassName="active">
              B2B
            </Link>
          </MenuListItem>
        </MenuListWrapper>
      </div>
    </MenuNavWrapper>
  );
};

export default MobileMenu;
