import React from "react";
import ProductTextStyling from "../../../styles/wrappers/ProductTextStyling";
import Markdown from "markdown-to-jsx";

interface ProductTextProps {
  text: string;
  className?: string;
}

const ProductText = ({ className, text }: ProductTextProps) => {
  return (
    <ProductTextStyling className={className}>
      <Markdown>{text}</Markdown>
    </ProductTextStyling>
  );
};

export default ProductText;
