import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import { TitleHomepage } from "../UI/index";
import { TestimonialsData as data } from "./data";
import { borderBefore } from "../../styles/mixins";

import "swiper/swiper-bundle.css";
import { theme } from "../../styles/theme/theme";

SwiperCore.use([Pagination, Autoplay]);

const Wrapper = styled.section`
  margin: ${theme.spaces.margin.vertical} auto;
  padding: ${theme.spaces.margin.horizontal} 0;
  ${borderBefore};

  @media (min-width: 680px) {
    margin: ${theme.spaces.margin.vertical_big} auto;
    padding-top: ${theme.spaces.margin.vertical_big};
  }
`;

const SwiperWrapper = styled.div`
  padding: ${theme.spaces.padding.base};
  margin: ${theme.spaces.margin.big} auto;
  display: grid;
  grid-template-columns: 0.9fr 1.6fr;
  grid-gap: 1em;
  max-width: 800px;

  @media (min-width: 680px) {
    margin: ${theme.spaces.margin.vertical} auto;
    grid-template-columns: 1.2fr 1.6fr;
    grid-gap: 3em;
  }

  @media (min-width: 1360px) {
    padding: ${theme.spaces.padding.base};
  }

  .swiper-pagination-bullets {
    top: 0;
    width: auto;
    max-width: 100%;
    height: 20px;
    overflow: hidden;
  }

  .swiper-pagination-bullet {
    background: none;
    border: 2px solid #9a6b50;

    @media (min-width: 680px) {
      width: 1.5em;
      height: 1.5em;
    }
  }

  .swiper-pagination-bullet-active {
    background: #9a6b50;
  }
`;
const StyledSlider = styled(Swiper)`
  width: 100%;

  .swiper-slide {
    align-self: center;
    height: auto;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const Text = styled.p`
  padding-left: 2px;
  text-align: justify;
  word-break: break-word;
  line-height: 1.4;

  @media (min-width: 680px) {
    font-size: ${theme.fonts.fontSize.xl};
    line-height: 1.6;
  }
`;

const Name = styled.span`
  padding-top: 0.7em;
  align-self: flex-end;
  font-size: ${theme.fonts.fontSize.xl};
  color: ${theme.colors.secondary};

  @media (min-width: 680px) {
    font-size: ${theme.fonts.fontSize.xxl};
  }
`;

const Testimonials = () => {
  return (
    <Wrapper>
      <TitleHomepage title="zaufaj opiniom" span="mówią o nas" />
      <SwiperWrapper>
        <div>
          <StaticImage src="../../assets/images/testimonials.jpg" alt="opinie o kosmetykach Opcja Natura" style={{ minHeight: "250px" }} loading={"lazy"} />
        </div>
        <StyledSlider slidesPerView={1} spaceBetween={10} centeredSlides={true} autoplay={{ delay: 3500 }} pagination={{ clickable: true }}>
          {data.map((review) => {
            return (
              <SwiperSlide key={review.id}>
                <Text>{review.text}</Text>
                <Name>{review.name}</Name>
              </SwiperSlide>
            );
          })}
        </StyledSlider>
      </SwiperWrapper>
    </Wrapper>
  );
};

export default Testimonials;
