import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import PostItem from "../../blog/blogList/PostListItem";
import { TitleHomepage, ButtonGlobal } from "../../UI/index";
import { borderBefore } from "../../../styles/mixins";
import { theme } from "../../../styles/theme/theme";
import { PostProps } from "../../../utils/types/graphQLTypes";

const Wrapper = styled.section`
  margin: ${theme.spaces.margin.vertical} auto;
  padding: ${theme.spaces.margin.horizontal} 0;
  ${borderBefore}

  .link-container {
    display: none;
  }

  .link-wrapper {
    margin: 3em auto;
    display: grid;
    place-items: center;

    @media (min-width: 680px) {
      margin: 4em auto;
    }
    @media (min-width: 1400px) {
      margin: 6em auto;
    }
  }
`;

const PostsWrapper = styled.div`
  padding: ${theme.spaces.padding.xxl};
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 4em;

  @media (min-width: 980px) {
    max-width: 1360px;
  }

  @media (min-width: 1360px) {
    padding: ${theme.spaces.padding.base};
  }
`;

const query = graphql`
  {
    posts: allContentfulBlogPosts(sort: { createdAt: DESC }, filter: { homepagePost: { eq: true } }, limit: 3) {
      nodes {
        ...PostItem
      }
    }
  }
`;

type DataProps = {
  posts: {
    nodes: PostProps[];
  };
};

const Index = () => {
  const {
    posts: { nodes },
  } = useStaticQuery<DataProps>(query);
  return (
    <Wrapper>
      <TitleHomepage title="blog. opcja natura." span="chcesz wiedzieć więcej? Zajrzyj na bloga!" />
      <PostsWrapper>
        {nodes.map((post) => {
          return <PostItem key={post.id} post={post} />;
        })}
      </PostsWrapper>
      <div className="link-wrapper">
        <ButtonGlobal text="zobacz wszystkie wpisy" link="/blog" />
      </div>
    </Wrapper>
  );
};

export default Index;
