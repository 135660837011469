import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { fadeIn } from "../../../styles/mixins/";
import ProductPromoBadge from "../product/ProductPromoBadge";
import Link from "../../UI/elements/Link";
import { ProductProps } from "../../../utils/types/graphQLTypes";

const ImageWrapper = styled.section`
  position: relative;
  max-width: 95%;
  margin: 0 auto;
`;

const ProductImage = styled(GatsbyImage)`
  animation: ${fadeIn} ease 3s;

  margin: 0 auto;
`;

interface Props {
  images: {
    fileLocal: ImageDataLike;
  }[];
  imagealt: string;
  link: string;
  label: ProductProps["label"];
}

const ProductListItemImage = ({ images, imagealt, link, label }: Props) => {
  const [showSecondImage, setShowSecondImage] = useState(false);
  const firstImage = getImage(images[0].fileLocal);
  const secondImage = getImage(images[images.length > 1 ? 1 : 0].fileLocal);
  if (!firstImage) {
    throw new Error("First image is undefined");
  }
  if (!secondImage) {
    throw new Error("Second image is undefined");
  }

  const [productImage, setProductImage] = useState(firstImage);

  const handleMouseEnter = () => {
    if (images.length > 1) {
      setShowSecondImage(true);
    }
  };

  const handleMouseLeave = () => {
    if (images.length > 1) {
      setShowSecondImage(false);
    }
  };

  useEffect(() => {
    if (images.length > 1) {
      setProductImage(showSecondImage ? secondImage : firstImage);
    }
  }, [productImage, showSecondImage, images]);

  const children = (
    <>
      <div onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => handleMouseLeave()}>
        <ProductImage image={productImage} alt={imagealt} />
      </div>
      <ProductPromoBadge label={label} />
    </>
  );

  return (
    <ImageWrapper>
      <Link to={`${link}`}>{children}</Link>
    </ImageWrapper>
  );
};

export default ProductListItemImage;
