import React from "react";
import styled from "styled-components";
import { SwiperSlide } from "swiper/react";
import ProductSliderItem from "./ProductSliderItem";
import ProductSliderWrapper from "./ProductSliderWrapper";
import { TitleHomepage } from "../../UI/index";
import { borderBefore } from "../../../styles/mixins";
import { theme } from "../../../styles/theme/theme";
import { ImageDataLike } from "gatsby-plugin-image";
import { Currency } from "@on/model-store";
import { ProductProps } from "../../../utils/types/graphQLTypes";

const Wrapper = styled.section`
  margin-top: ${theme.spaces.margin.horizontal};
  padding: ${theme.spaces.margin.horizontal} 0;
  ${borderBefore};
`;

interface Props {
  crossSales: {
    product_id: string;
    label: ProductProps["label"];
    prices: Record<
      Currency,
      {
        active: boolean;
        currency: string;
        unit_price: number;
        previous_unit_price?: number;
        sale: boolean;
        taxRate: number; // 0.23
      }
    >;
    name: string;
    slug: string;
    images: {
      fileLocal: ImageDataLike;
    }[];
  }[];
  title: string;
  titleSpan: string;
  eager?: boolean;
}

const ProductCarousel = ({ crossSales, title, titleSpan, eager }: Props) => {
  return (
    <Wrapper>
      <TitleHomepage title={title} span={titleSpan} />
      <ProductSliderWrapper itemsNumber={crossSales.length}>
        {crossSales.map((crossSale) => {
          return (
            <SwiperSlide key={crossSale.product_id}>
              <ProductSliderItem product={crossSale} position={{ right: "15px", bottom: "90%" }} eager={eager ?? false} />
            </SwiperSlide>
          );
        })}
      </ProductSliderWrapper>
    </Wrapper>
  );
};

export default ProductCarousel;
