import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { TitleHomepage } from "../../UI";
import { borderBefore } from "../../../styles/mixins";
import data from "./data";
import { theme } from "../../../styles/theme/theme";

const Wrapper = styled.div`
  margin: ${theme.spaces.margin.vertical} auto;
  position: relative;
  padding: ${theme.spaces.margin.horizontal} 0;
  ${borderBefore};

  @media (min-width: 680px) {
    margin: ${theme.spaces.margin.vertical} auto;
    padding-top: ${theme.spaces.margin.vertical_big};
  }

  button {
    display: block;
    margin: 0 auto;
    margin-top: 3em;
    @media (min-width: 680px) {
      margin-top: 4em;
    }
    @media (min-width: 1400px) {
      margin-top: 6em;
    }
  }
`;

const CardWrapper = styled.ul`
  scrollbar-width: none;
  overflow-x: scroll;
  overflow-y: hidden;
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(4, 280px) 1px;
  grid-gap: 1em;
  padding: 0 ${theme.spaces.padding.big};

  :after {
    content: "";
  }

  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 550px) {
    grid-template-columns: 1px repeat(4, 300px) 1px;

    :before,
    :after {
      content: "";
    }
  }

  @media (min-width: 980px) {
    padding: ${theme.spaces.padding.xxl};
    grid-template-columns: repeat(4, minmax(200px, 1fr));
    max-width: 1360px;

    :before,
    :after {
      content: none;
    }
  }

  @media (min-width: 1360px) {
    padding: 0;
  }
`;

const ElementContainer = styled.li`
  max-width: 100%;
  display: grid;
  grid-template-rows: 190px 80px auto;
  @media (min-width: 680px) {
    width: auto;
    height: auto;
  }
  h3 {
    text-align: center;
    letter-spacing: 2px;
    margin-top: ${theme.spaces.margin.large};
    color: ${theme.colors.font};
  }

  p {
    max-width: 95%;
    justify-self: center;
    line-height: 1.8;
    text-align: justify;
    text-justify: inter-word;
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  overflow: hidden;
  transition: transform 0.8s ease;

  &:hover {
    transform: scaleX(1.1);
  }
`;

const Index = () => {
  const {
    allFile: { nodes: image },
  } = useStaticQuery<{
    allFile: {
      nodes: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
        };
      }[];
    };
  }>(query);

  return (
    <Wrapper>
      <TitleHomepage title="natura. to proste" span="Jak to robimy w naszej manufakturze?" />

      <CardWrapper>
        {data.map((card, i) => {
          return (
            <ElementContainer key={i}>
              <StyledImage image={image[i].childImageSharp.gatsbyImageData} alt="opcja natura maufaktura" />
              <h3>{card.title}</h3>
              <p>{card.text}</p>
            </ElementContainer>
          );
        })}
      </CardWrapper>
    </Wrapper>
  );
};

const query = graphql`
  {
    allFile(filter: { relativeDirectory: { eq: "whyUs" } }, sort: { name: ASC }) {
      nodes {
        childImageSharp {
          gatsbyImageData(height: 400)
        }
      }
    }
  }
`;

export default Index;
