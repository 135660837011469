import { ReactElement } from "react";

require("@fontsource/lato/latin-ext-300.css");
require("@fontsource/lato/latin-300.css");
require("@fontsource/lato/latin-ext-300.css");
require("@fontsource/lato/latin-400.css");
require("@fontsource/lato/latin-ext-400.css");
require("@fontsource/lato/latin-700.css");
require("@fontsource/lato/latin-ext-700.css");
require("@fontsource/lato/latin-300-italic.css");
require("@fontsource/lato/latin-ext-300-italic.css");
require("@fontsource/lato/latin-700-italic.css");
require("@fontsource/lato/latin-ext-700-italic.css");

import * as React from "react";
import { RootLayout } from "./src/components/index";
import { GatsbyBrowser } from "gatsby";

interface Props {
  element: ReactElement;
}

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }: Props) => {
  return <RootLayout>{element}</RootLayout>;
};
