import React from "react";
import styled from "styled-components";
import { theme } from "../../../styles/theme/theme";

const Titles = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spaces.margin.xxl};

  @media (min-width: 800px) {
    justify-self: flex-start;
    margin-bottom: ${theme.spaces.margin.xxl};
  }

  h3 {
    justify-self: center;
    align-self: center;
    text-align: center;
    margin-bottom: ${theme.spaces.margin.small};
    font-weight: ${theme.fonts.fontWeight.normal};

    @media (min-width: 980px) {
      align-self: flex-start;
      text-align: left;
    }
  }

  span {
    text-align: center;
    justify-self: center;

    @media (min-width: 980px) {
      text-align: left;
    }
  }
`;

interface Props {
  productTitle: string;
  productTitleSpan: string;
}
const Title = ({ productTitle, productTitleSpan }: Props) => {
  return (
    <Titles>
      <h3 className="product-title">{productTitle}</h3>
      <span className="product-title-span" dangerouslySetInnerHTML={{ __html: productTitleSpan }} />
    </Titles>
  );
};

export default Title;
