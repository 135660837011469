import styled from "styled-components";
import { theme } from "../../../styles/theme/theme";
import { SearchIcon } from "../../../assets/icons";
import { theme as checkoutTheme } from "../../../styles/theme/checkoutTheme";

export const BoxContainer = styled.div`
  display: grid;
`;

export const BoxInnerContainer = styled.div<{ $isMobileMenu?: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${checkoutTheme.colors.inputBckg};
  border-bottom: 1px solid ${theme.colors.primary};
  max-width: 300px;
  box-sizing: border-box;
  padding: 0.4em;
  margin-bottom: ${(props) => (props.$isMobileMenu ? "1.5em" : "0")};
  margin-top: ${(props) => (props.$isMobileMenu ? "1em" : "0")};

  span {
    text-transform: uppercase;
    display: inline;
    letter-spacing: 0.1em;
    margin-left: 0.5em;
  }
`;

export const FormInput = styled.input`
  background: ${checkoutTheme.colors.inputBckg};
  line-height: normal;
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;

  :focus {
    background: ${checkoutTheme.colors.inputBckg};
  }
`;

export const StyledSearchIcon = styled(SearchIcon)<{ $isMobileMenu?: boolean }>`
  fill: ${theme.colors.primary};
  max-height: ${(props) => (props.$isMobileMenu ? "2em" : "4em")};
  cursor: pointer;
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  display: grid;
  justify-content: center;

  h3,
  p {
    text-align: center;
  }
`;

export const ErrorSpan = styled.p`
  position: absolute;
  bottom: -1.8em;
  color: ${checkoutTheme.colors.errorColor};
  left: 0;
  text-transform: lowercase;
`;
