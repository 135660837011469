import styled from "styled-components";
import Link from "../UI/elements/Link";
import { theme } from "../../styles/theme/theme";
import { theme as checkoutTheme } from "../../styles/theme/checkoutTheme";

export const Wrapper = styled.div<{ $paddingTop?: string; $paddingBottom?: string }>`
  font-size: ${theme.fonts.fontSize.lg};
  padding-left: 0.1em;
  padding-right: 0.1em;
  text-align: center;
  z-index: 20;
  font-size: ${theme.fonts.fontSize.xl};
  padding-top: ${(props) => props.$paddingTop ?? "0.3em"};
  padding-bottom: ${(props) => props.$paddingBottom ?? "1em"};
  overflow: hidden;
  background: ${(props) => props.color || "#fff"};
  max-height: 100px;
  //min-height: 75px;
  transition: max-height 1s ease-out;

  p {
    display: inline;
  }
`;

export const StaticWrapper = styled.div<{ $paddingTop?: string; $paddingBottom?: string; $color?: string }>`
  font-size: ${theme.fonts.fontSize.lg};
  padding-left: 0.3em;
  padding-right: 0.3em;
  text-align: center;
  z-index: 20;
  font-size: ${theme.fonts.fontSize.xl};
  padding-top: ${(props) => props.$paddingTop ?? "0.3em"};
  padding-bottom: ${(props) => props.$paddingBottom ?? "1em"};
  overflow: hidden;
  background: ${(props) => props.$color || "#fff"};

  p {
    display: inline;
  }
`;

export const ContentWrapper = styled.div`
  text-align: center;
  padding: 0.5em 0;
  display: grid;
  place-items: center;
`;

export const ContentParagraph = styled.p`
  letter-spacing: 0.03em;
  font-size: ${theme.fonts.fontSize.xl};
  color: ${(props) => props.color || "#000"};
`;

export const Bold = styled.span`
  letter-spacing: 0.04em;
  font-weight: ${theme.fonts.fontWeight.bold};
  color: inherit;
`;

export const DynamicMessage = styled.p<{ $applied?: boolean; $backgroundColor?: string; $textColor?: string }>`
  font-weight: 600;
  color: ${(props) => (props.$applied ? checkoutTheme.colors.promoColor : "unset")};
  text-transform: ${(props) => (props.$applied ? "uppercase" : "unset")};
  background-color: ${(props) => props.$backgroundColor || "unset"};

  span:nth-child(1) {
    color: ${(props) => (props.$applied ? checkoutTheme.colors.promoColor : "unset")};
  }

  span:nth-child(2) {
    display: block;
    font-size: ${theme.fonts.fontSize.base};
    font-weight: ${theme.fonts.fontWeight.thin};
    text-transform: lowercase;
    font-style: italic;
  }
`;

export const PromoLink = styled(Link)`
  cursor: pointer;
  margin-top: 1em;
  margin-right: 0.2em;
  margin-left: 0.2em;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  padding: 0.3em 0.7em;
  /* border: 1px solid #eaeaea; */
  color: #000;
  font-weight: 600;
`;

export const InParagraphLink = styled(Link)`
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5em;
  margin-left: 0.5em;
  letter-spacing: 0.2em;
  border-bottom: 1px solid ${(props) => props.color};
  color: ${(props) => props.color || "#000"};
`;
