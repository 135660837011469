import React, { useState, useRef, MutableRefObject } from "react";
import styled from "styled-components";
import { ProductText } from "../../UI/index";
import { theme } from "../../../styles/theme/theme";

const Wrapper = styled.div`
  height: 100%;
`;

const TabsContainer = styled.ul`
  position: sticky;
  top: 3.6em;
  background-color: #fff;
  display: flex;
  margin-bottom: ${theme.spaces.margin.big};
  margin-top: ${theme.spaces.margin.xxl};
  padding: ${theme.spaces.padding.large} 0;

  @media (min-width: 850px) {
    top: 4.6em;
  }

  .active-btn {
    border-bottom: 1px solid ${theme.colors.primary};
    font-weight: ${theme.fonts.fontWeight.light};
  }
`;

const Tab = styled.li`
  text-transform: uppercase;
  cursor: pointer;
  margin-right: ${theme.spaces.margin.big};
  font-size: ${theme.fonts.fontSize.xl};
  font-weight: ${theme.fonts.fontWeight.thin};
  color: ${theme.colors.primary};
`;

interface Props {
  tabs: {
    button: string;
    text: string;
  }[];
}
const ProductTabs = (props: Props) => {
  const [active, setActive] = useState({
    button: props.tabs[0].button,
    value: 0,
  });

  const myRef = useRef<MutableRefObject<HTMLUListElement> | MutableRefObject<undefined>>(null);

  const executeScroll = (tab: { button: string }, index: number) => {
    setActive({
      button: tab.button,
      value: index,
    });
    if (typeof window !== "undefined") {
      window.scroll(0, window.pageYOffset - (myRef as unknown as MutableRefObject<HTMLUListElement>).current?.offsetTop);
    }
  };

  return (
    <Wrapper>
      <TabsContainer ref={myRef as unknown as MutableRefObject<HTMLUListElement>}>
        {props.tabs.map((tab, index) => {
          return (
            <Tab key={index} role="button" onClick={() => executeScroll(tab, index)} className={`${tab.button === active.button ? "active-btn" : ""}`}>
              {tab.button}
            </Tab>
          );
        })}
      </TabsContainer>
      <ProductText text={props.tabs[active.value].text} />
    </Wrapper>
  );
};

export default ProductTabs;
