export const TestimonialsData = [
  {
    id: 1,
    name: "Zuzka",
    text: "Bardzo polecam kosmetyki Opcja Natura, przetestowałam już właściwie wszystkie i uważam, że są genialne! Pięknie pachną, mają bardzo dobre, naturalne składy, super działają na moją skórę i są w przystępnych cenach. Paczki przychodzą zawsze szybko i na czas i są pięknie zapakowane. Miło korzystać!",
  },
  {
    id: 2,
    name: "Marta",
    text: "Najlepsze produkty, jakie napotkałam na swojej drodze. Są w 100% naturalne, wykonywane z sercem i pasją ♥️ stosuje je od dawna - ja i cała moja rodzina. Polecam ",
  },
  {
    id: 3,
    name: "Anna",
    text: "Opcja Natura - to natura która muska moje ciało każdego dnia. Jestem bardzo zadowolonym użytkownikiem produktów marki Opcja Natura. Gorąco polecam !!!",
  },
  {
    id: 4,
    name: "Iga",
    text: "Dla wszystkich, którzy chcą się przekonać jak kosmetyki naturalne potrafią pozytywnie wpłynąć na cerę, skórę. To nie łatwe zadanie dla producentki jednak - efekt jest porażający.. niejednokrotnie zdarza mi się uśmiechać podczas używania tych rześkich zapachów. Aplikuję produkty marki Opcja Natura od ponad dwóch lat i według mnie są zdecydowanie godne zaufania. ",
  },
  {
    id: 5,
    name: "Zofka",
    text: "Uwielbiamy! Używam tych kosmetyków dla całej rodziny razem z dziećmi od momentu narodzin. Polecam z ręką na sercu ",
  },
  {
    id: 6,
    name: "Magdalena",
    text: "Absolutnie fantastyczne kosmetyki. Mam reumatoidalne zapalenie stawow i badania wykazaly ze na poziomie komorkowym mam toxyczne ciało i prawdopodobnie odnosi sie to do kosmetyków których używam. Znalazłam Opcja Natura i nie moge byc bardziej szczęśliwa. Nie tylko pachną cudownie ale są free from all toxins i dzialaja bajecznie!!!! Polecam absolutnie każdej osobie.",
  },
  {
    id: 7,
    name: "Angelika",
    text: "Opcja Natura wykonuje wspaniałe cudeńka, które pozytywnie wpływają na wygląd cery Każde zamówienie wywołuje ogromny uśmiech na twarzy, do tego piękne opakowania, a także wspaniała obsługa. Polecam!!",
  },
  {
    id: 8,
    name: "Natalia",
    text: "Najlepsze kosmetyki, które używałam. Bardzo wydajne, naturalny zapach, szeroka gama produktów. Szczerze polecam!",
  },
  {
    id: 9,
    name: "MrsMarryLu",
    text: "Opcja Natura to bardzo rzetelna i solidna firma. Produkty, które oferuje są na wysokim poziomie jakościowym oraz estetycznym. Cena jest przystępna i adekwatna co do wartości oferowanych produktów. Serdecznie polecam!",
  },
  {
    id: 10,
    name: "Filip",
    text: "Jedyna słuszna OPCJA! Kosmetyki naprawdę warte każdej złotówki a do tego w pełni naturalne! Polecam w 100% zadowolony klient!",
  },
  {
    id: 11,
    name: "Agnieszka",
    text: "Szybka sprawna obsługa, wyroby na najwyższym poziomie, obsługa miła i pomocna w doborze odpowiednich produktów, mazidło opatrunkowe niezastąpione polecam!",
  },
  {
    id: 12,
    name: "Kinga",
    text: "najlepsze kosmetyki jakie do tej pory stosowalam. nie uczulają, swietnie pachną, cudownie nawilżają. polecam z calego serca!",
  },
];
