import React from "react";
import styled from "styled-components";
import { fadeIn } from "../../../styles/mixins";
import ProductPromoBadge from "../product/ProductPromoBadge";
import Price from "../product/Price";
import { theme } from "../../../styles/theme/theme";
import ProductSliderImage from "./ProductSliderImage";
import { ImageDataLike } from "gatsby-plugin-image";
import Link from "../../UI/elements/Link";
import { Currency } from "@on/model-store";
import { ProductProps } from "../../../utils/types/graphQLTypes";

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  text-align: center;
  animation: ${fadeIn} ease 2s;

  h3 {
    justify-self: center;
    align-self: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: ${theme.spaces.margin.big};
    font-weight: ${theme.fonts.fontWeight.light};
    font-size: ${theme.fonts.fontSize.lg};

    @media (min-width: 1300px) {
      margin-top: ${theme.spaces.margin.xl};
      letter-spacing: 3px;
      font-size: ${theme.fonts.fontSize.xl};
    }
  }
`;

interface Props {
  product: {
    label: ProductProps["label"];
    prices: Record<
      Currency,
      {
        active: boolean;
        currency: string;
        unit_price: number;
        previous_unit_price?: number;
        sale: boolean;
        taxRate: number; // 0.23
      }
    >;
    name: string;
    slug: string;
    images: {
      fileLocal: ImageDataLike;
    }[];
  };
  position?: { bottom?: string; right?: string };
  eager?: boolean;
}

const ProductSliderItem = ({ product, position, eager }: Props) => {
  const price = product.prices["PLN"];
  return (
    <Wrapper>
      <Link to={`/sklep/${product.slug}`}>
        <ProductSliderImage image={product.images[0].fileLocal} alt={product.name} eager={eager} />
        <ProductPromoBadge position={position} label={product.label} />
      </Link>
      <div>
        <h3>{product.name}</h3>
        <Price
          price={price.sale && price.previous_unit_price ? price.previous_unit_price : price.unit_price}
          sale={price.sale}
          sale_price={price.sale ? price.unit_price : undefined}
          sliderStyle={true}
          productName={product.name}
        />
      </div>
    </Wrapper>
  );
};

export default ProductSliderItem;
