import React from "react";
import { Navbar, Footer, CookieConstentMessage } from "../index";
import styled from "styled-components";
import { PageProps } from "gatsby";

const StyledWrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`;

interface Props {
  children: React.ReactNode;
  location: PageProps["location"];
}

const Layout = ({ children, location }: Props) => {
  return (
    <StyledWrapper>
      <Navbar path={location.pathname} />
      <main>{children}</main>
      <CookieConstentMessage />
      <Footer />
    </StyledWrapper>
  );
};

export default Layout;
