import * as React from "react";
import { memo } from "react";

function P24Logo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg id="prefix__layer" x={0} y={0} viewBox="-153 -46 652 652" xmlSpace="preserve" width="3em" height="3em" {...props}>
      <style>{".prefix__st0{fill:#d13239}.prefix__st1{fill:#b3b2b1}"}</style>
      <path
        className="prefix__st0"
        d="M-6.3 291.6l49.6-.1-1.8 11.3L0 342.3h34.8l-2 12.1h-51.2l2.1-12.5 39.7-38.1H-8.3zM-60.4 272.3c-1.1-1.1-2.6-2-4.3-2.7-1.8-.7-3.8-1.2-5.8-1.6-2.5-.4-4.9-.6-6.6-.7-1.6-.1-2.6-.1-2.6-.1h-37.8l-14.4 87.2h13.5l5.3-32.3 26.2.1s10.2.4 17.4-3.4c7.2-3.8 9.2-12.5 9.2-12.5s.8-3.2 1.5-7.2c.7-4.3 1.5-9.5 1.8-12.3.2-1.2.3-2 .3-2s.2-.9.2-2.3c0-1.9-.3-4.8-1.8-7.6-.7-.9-1.3-1.8-2.1-2.6zm-10.4 15.4c0 .2-1.2 7.2-2.8 15.5-.6 3.2-3 4.9-5.8 5.5-4.6 1-9.6.8-9.6.8l-21.9-.1 4.8-29 19.8.1h3.2c2.1 0 4.9.1 7.3.4 2 .3 3.6.7 4.1 1.3.7.8.9 1.9 1 2.9.1 1.3-.1 2.5-.1 2.6zM110.4 267.2h13l-14.5 87.2h-13zM179.7 291.6l12.5-.1 4.4 44.3 19.1-44.3h15.4l4.5 44.5 19.1-44.5h13l-27.5 62.9h-15.3l-4.4-44-19.4 44h-15zM86.5 292.1c-3.6-1.2-9.9-1.4-15.3-1.4-5.2.1-7.5.3-9.5.7 0 0-9.2 1.3-14.4 7.8-5.2 6.5-6.8 20.6-6.8 20.6s-3.1 15.6-2.2 20.8c.9 5.2 2.5 10 8.4 12.3 5.9 2.3 10.9 2.2 10.9 2.2s10.5.8 18.4-1 12.1-7.4 12.1-7.4 1.9-2.4 3.2-5.3c1.3-2.9 1.7-4.8 1.8-5.1l.8-3.4H80.4s-.7 8.9-8 9.7c-7.3.8-11.1.5-12.6.4-1.4-.1-9.2.3-8.6-6.2v-.4c.3-7.4 1.2-9.3 1.2-9.3l42.3-.1 1.8-10.5c2.3-11.9.8-20.9-10-24.4zM83.6 315h-29l1.1-4.6s1-3.6 3-5.1c2-1.5 4.6-1.8 7-2 2.4-.2 8.8-.7 14.1.4 1.7.3 3.4 1.3 3.8 2.8 1.1 3.3 0 8.5 0 8.5z"
      />
      <path
        className="prefix__st0"
        d="M51.5 336.5v.3c-.1.5 0-.3 0-.3zM131.2 336.6v0zM166.2 292.1c-3.6-1.2-9.9-1.4-15.3-1.4-5.2.1-7.5.3-9.5.7 0 0-9.2 1.3-14.4 7.8-5.2 6.5-6.8 20.6-6.8 20.6s-3.1 15.6-2.2 20.8c.9 5.2 2.5 10 8.4 12.3 5.9 2.3 10.9 2.2 10.9 2.2s10.5.8 18.4-1c7.9-1.9 12.1-7.4 12.1-7.4s1.9-2.4 3.2-5.3c1.3-2.9 1.7-4.8 1.8-5.1l.8-3.4h-13.5s-.7 8.9-8 9.7c-7.3.8-11.1.5-12.6.5-1.4-.1-9.2.3-8.6-6.2v-.4c.3-7.4 1.2-9.3 1.2-9.3l42.3-.1 1.8-10.5c2.3-11.9.9-21-10-24.5zm-2.9 22.9h-29l1.1-4.6s1-3.6 3-5.1c2-1.5 4.6-1.8 7-2s8.8-.7 14.1.4c1.7.3 3.4 1.3 3.8 2.8 1.1 3.3 0 8.5 0 8.5zM282.6 291.6l8.3 46 23.5-46 13.2.1-33.9 65.7s-6.1 11.9-9.9 14.9-6.1 4.4-9.2 4.7c-3.1.3-4.4.5-7.3 0l-3.2-.6 2-11.7s5.3 1 8.4-.3c3.1-1.2 5.6-6.6 5.6-6.6l1.6-2.7-12.3-63.7 13.2.2z"
      />
      <path
        className="prefix__st1"
        d="M332.5 297.8h13.6l.8-5.3s1.5-9.6 4.7-11.4c1-.6 2.7-1.1 4.7-1.5 3.6-.6 8.1-.7 11.8-.6 5.7.2 7.8.3 13.6.9 5.8.7 4.3 6.2 4.3 6.2l-1.1 8.3s-.5 3.7-1.8 6c-1.2 2.1-4.4 3.5-6.3 4.1-4.5 1.5-19.9 5.4-19.9 5.4l-12.1 3.5s-7.4 2.2-11.6 6.7c-4.2 4.6-5.8 9.8-6.4 12.6-.6 2.7-3.8 21.6-3.8 21.6h65.3l2.2-13-51.6.1.9-5.3s.6-5.5 2.8-7.3c.7-.6 1-1.3 5.2-2.8 2.5-.9 10.9-3.1 10.9-3.1l19.5-5.3s10.6-2.7 14.8-8.6c4.2-5.8 5.8-16.9 5.8-16.9s1.1-10.8.3-14.2c-.9-3.4-4.1-7.4-7.9-9.2-3.9-1.7-7.9-2.8-19.7-2.6-11.7.2-17.6.7-23.5 2.9-6 2.2-9.4 6.2-11.6 11.8-2.5 5.5-3.9 17-3.9 17zM455.4 322.1l9.2-54.8h-16.2l-50.5 54.2-2.3 13.7h44.1l-3.2 19.3h13.6l3.2-19.3h12.5l2.2-13.1h-12.6zm-13.4 0h-28.5l34.7-37-6.2 37zM-31.8 274.4H9.8s9.3-7.5 15.9-12.4c6.6-4.8 18.7-12.5 18.7-12.5l-23.5-11S1 250.8-7.4 256.6c-8.2 5.4-24.4 17.8-24.4 17.8zM57.5 241.8l-19.3-13s17.4-9.9 40.7-19.2c23.2-9.2 35.6-13.1 35.6-13.1l3.9 18.4s-22.3 7.5-35.1 13.5c-13.2 5.7-25.8 13.4-25.8 13.4zM132.7 210.9l-3.4-18.8s23.8-6.3 45.6-10.4c21.8-4.1 50.7-6 50.7-6l-9.6 29.1s-25.4-3.5-49.3-.2c-18.5 2.2-34 6.3-34 6.3zM231.2 207.3l16.1-32.3s35.2-.7 65.6 4c30.4 4.7 58.2 11.9 57.6 12.2l-77.1 40.3s-18-11.4-40.4-18.4c-12.6-3.7-21.8-5.8-21.8-5.8zM307.3 240.3l16.9 12.9h139.2s-.3-4.5-4-10.9c-2.3-4-6.5-8.2-10.9-12.6-1.6-1.6-8-6.6-12.8-9.7-12.3-8-19.2-11.1-31.9-16.8l-96.5 37.1z"
      />
      <path className="prefix__st0" d="M-22.8 291.5c-5.2 0-10.2 2.1-14.4 4.4l.7-4.4h-13.8l-11.1 62.6h13.9l6.1-34.7c1.3-7 6.5-15.7 16.8-15.7h7.2l2.2-12.2h-7.6z" />
    </svg>
  );
}

const MemoP24Logo = memo(P24Logo);
export default MemoP24Logo;
