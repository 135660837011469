import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { LinearProgress } from "../UI/elements/LinearProgress";
import { DiscountBoxFormContainer, DiscountBoxForm, StyledInput, DiscountBoxSubmitButton, FormErrorMessage, FormMessage } from "./helpers/newsletterHelpers";
import { changeNewsletterSubscription } from "../service/storeService";
import { CheckboxItem, CheckboxRowContainer, Input, Label } from "../checkout/helpers/checkoutFormsHelpers";
import { BsAsterisk } from "react-icons/bs";
import Link from "../UI/elements/Link";

const SubscriptionForm = (props: { regulaminNewsletter: string }) => {
  const [loading, setLoading] = useState(false);
  const [formMessage, setFormMessage] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm<{ email: string; privacy: boolean }>();

  const onSubmit = async (data: { email: string }) => {
    const email = data.email;
    setLoading(true);
    try {
      await changeNewsletterSubscription({ email, subscribe: true, account_must_exists: false });
      setFormMessage("Adres dodany do naszej bazy.");
    } catch (e) {
      setFormMessage("Coś poszło nie tak. Spróbuj ponownie za kilka minut.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ email: "" });
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <DiscountBoxFormContainer onSubmit={handleSubmit(onSubmit)} data-testid={"subscription-form"}>
      <DiscountBoxForm>
        <StyledInput
          placeholder="Wpisz Email"
          type="text"
          data-testid={"subscription-form__input"}
          autoComplete="email"
          autoCapitalize="off"
          {...register("email", {
            required: { value: true, message: "Proszę podaj adres email" },
            pattern: { value: /^\S+@\S+\.\S+$/, message: "Wpisz prawidłowy adres mail" },
          })}
        />

        <DiscountBoxSubmitButton type="submit" data-testid={"subscription-form__submit"}>
          Zapisz się
        </DiscountBoxSubmitButton>
        {errors.email && <FormErrorMessage data-testid={"subscription-form__errorMessage"}>{errors.email.message}</FormErrorMessage>}
      </DiscountBoxForm>
      {loading && <LinearProgress />}

      <FormMessage data-testid={"subscription-form__successMessage"}>{formMessage}</FormMessage>

      <div>
        <CheckboxRowContainer>
          <CheckboxItem>
            <Input type="checkbox" id="privacy" style={{ WebkitAppearance: "checkbox" }} {...register("privacy", { required: true })} />
            <Label htmlFor="privacy" $error={Boolean(errors["privacy"])}>
              <BsAsterisk style={{ fontSize: "8px", marginRight: "0.3em", position: "absolute", top: "1px", left: "3px" }} />{" "}
              <span data-testid={"privacy-policy"}>Wyrażam zgodę na przechowywanie i przetwarzanie moich danych osobowych zgodnie z </span>
              <Link to={props.regulaminNewsletter} className="border__out">
                {" "}
                Polityką prywatności.{" "}
              </Link>
            </Label>
            {errors["privacy"] && <FormErrorMessage data-testid={"privacy-error"}>Zaznacz wymagane zgody</FormErrorMessage>}
          </CheckboxItem>
        </CheckboxRowContainer>
      </div>
    </DiscountBoxFormContainer>
  );
};

export default SubscriptionForm;
