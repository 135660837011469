import React from "react";
import styled from "styled-components";
import ProductListItemImage from "./ProductListItemImage";
import { fadeIn } from "../../../styles/mixins/";
import BuyButton from "../buyButton/BuyButton";
import Price from "../../shop/product/Price";
import ProductListItemTitle from "./ProductListItemTitle";
import { theme } from "../../../styles/theme/theme";
import { ProductProps } from "../../../utils/types/graphQLTypes";

const Wrapper = styled.article`
  animation: ${fadeIn} ease 2s;
  display: grid;
  grid-template-rows: 0.7fr auto auto min-content auto;
  grid-gap: ${theme.spaces.padding.base};
  align-content: space-between;
  margin-top: ${theme.spaces.margin.xxl};

  @media (min-width: 655px) {
    grid-gap: ${theme.spaces.margin.large};
  }

  .buy-section {
    justify-self: center;
  }
`;

interface Props {
  product: Pick<ProductProps, "images" | "slug" | "name" | "name_span" | "product_id" | "prices" | "label" | "productType"> & { custom_attributes: { giftPacking: boolean } };
}

const ProductListItem = ({ product }: Props) => {
  const price = product.prices["PLN"];
  const link = product.productType === "PRODUCT" ? `/sklep/${product.slug}` : `/dopasuj-kosmetyk/${product.slug}`;
  return (
    <Wrapper>
      <ProductListItemImage images={product.images} link={link} label={product.label} imagealt={product.name} />
      <ProductListItemTitle title={product.name} titleSpan={product.name_span} />
      <Price
        price={price.sale && price.previous_unit_price ? price.previous_unit_price : price.unit_price}
        sale={price.sale}
        sale_price={price.sale ? price.unit_price : undefined}
        least_price={price.previous_unit_price}
        align="center"
        isProductPage={false}
        isCatPage={true}
        margin="0"
      />
      {product.custom_attributes.giftPacking ? (
        <div className="buy-section">
        <p>Dodaj na stronie koszyka</p>
        </div>
      ) : (
        <div className="buy-section">
          <BuyButton productId={product.product_id} images={product.images} />
        </div>
      )}
    </Wrapper>
  );
};

export default ProductListItem;
