import React from "react";
import styled from "styled-components";

const BannerText = styled.div<{ $textOverlay: boolean }>`
  display: grid;
  grid-area: 1/1;
  position: relative;
  place-items: center;
  background-color: ${({ $textOverlay }) => ($textOverlay ? "rgba(0, 0, 0, 0.3)" : "none")};
`;

interface DataProps {
  children: React.ReactNode;
  textOverlay: boolean;
}

const BgImageText = ({ children, textOverlay }: DataProps) => {
  return <BannerText $textOverlay={textOverlay}>{children}</BannerText>;
};

export default BgImageText;
