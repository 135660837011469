import React from "react";
import styled, { css } from "styled-components";
import { theme } from "../../../styles/theme/theme";

const ShopTitle = styled.header<{ $before?: boolean }>`
  display: grid;
  place-items: center;
  margin: ${theme.spaces.margin.vertical} 0;
  ${({ $before }) =>
    $before &&
    css`
      padding-top: ${theme.spaces.padding.big};
      position: relative;
      ::before {
        content: "";
        width: 100%;
        position: absolute;
        top: 0;
        height: 1px;
        opacity: 0.7;
        color: ${theme.colors.secondary};
        border: 1px solid ${theme.colors.secondary_opacity};
      }
    `};

  h1 {
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: ${theme.fonts.fontWeight.light};
    color: ${theme.colors.primary};
  }

  h1 ~ p {
    text-align: center;
    line-height: 1.9;
    padding: 0 5%;

    @media (min-width: 800px) {
      padding: 0 20%;
    }

    @media (min-width: 1100px) {
      padding: 0 30%;
    }
  }
`;

interface Props {
  children: React.ReactElement | React.ReactElement[];
  before?: boolean;
}

const TitleCategoryPage = ({ children, before }: Props) => {
  return <ShopTitle $before={before}> {children} </ShopTitle>;
};

export default TitleCategoryPage;
