import React from "react";
import styled, { css } from "styled-components";
import { CgSpinner } from "react-icons/cg";
import { spin } from "../../../styles/mixins";

const SpinnerIcon = styled(CgSpinner)`
  margin: 0 auto;
  width: 42px;
  height: 42px;
  animation: ${spin} 0.7s linear 0s infinite;
`;

const SpinnerWrapper = styled.div<{
  $position?: string;
}>`
  margin: 2em auto;
  align-self: center;
  display: grid;
  align-items: center;
  ${({ $position }) =>
    $position === "absolute" &&
    css`
      position: absolute;
      bottom: 0;
      left: calc(50% - 21px);
    `}
`;

interface SpinnerProps {
  position?: string;
}

const Spinner = ({ position }: SpinnerProps) => {
  return (
    <SpinnerWrapper $position={position}>
      <SpinnerIcon />
    </SpinnerWrapper>
  );
};

export default Spinner;
