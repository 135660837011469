export const slugify = (text: string): string => {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/[żź]/gi, "z")
    .replace(/ó/gi, "o")
    .replace(/ł/gi, "l")
    .replace(/ć/gi, "c")
    .replace(/ś/gi, "s")
    .replace(/ń/gi, "n")
    .replace(/ą/gi, "a")
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[\s\W-]+/g, "-"); // Replace spaces, non-word characters and dashes with a single dash (-)
};
