import React from "react";
import styled from "styled-components";
import { ProductText } from "../../UI/index";
import { theme } from "../../../styles/theme/theme";

const ProductDetails = styled.section`
  padding: 0 ${theme.spaces.padding.base};
  margin-bottom: ${theme.spaces.margin.big};

  @media (min-width: 650px) {
    margin-bottom: ${theme.spaces.margin.xxl};
  }

  @media (min-width: 980px) {
    padding: 0;
  }

  @media (min-width: 1200px) {
    max-width: 80%;
  }

  .product-description {
    @media (min-width: 650px) {
      text-align: justify;
      text-justify: inter-word;
      line-height: 1.7;
    }
  }
`;

const Description = ({ text }: { text: string }) => {
  return (
    <ProductDetails>
      <ProductText text={text} className="product-description" />
    </ProductDetails>
  );
};

export default Description;
