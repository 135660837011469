import React, { useEffect } from "react";
import { getWindow } from "../../../utils/types/window";

interface Props {
  productEan: string;
  price: number;
  name: string;
  slug: string;
  siteUrl: string;
  productImageUrl: string;
}

const Reviews = (props: Props) => {
  useEffect(() => {
    if (typeof getWindow() !== `undefined` && typeof getWindow()["yotpo"] !== "undefined") {
      getWindow()["yotpo"]?.refreshWidgets();
    }
  }, []);

  return (
    <div
      className="yotpo yotpo-main-widget"
      data-product-id={`${props.productEan}`}
      data-price={`${props.price}`}
      data-currency="PLN"
      data-name={`${props.name}`}
      data-url={`https://opcjanatura.pl/sklep/${props.slug}`}
      data-image-url={`${props.siteUrl}${props.productImageUrl}`}
    ></div>
  );
};

export default Reviews;
