import { keyframes } from "styled-components";

const FadeIn = keyframes`
  0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

export default FadeIn;
