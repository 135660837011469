import React from "react";
import styled from "styled-components";
import { ButtonAnimation } from "../../../styles/mixins";
import { theme } from "../../../styles/theme/theme";

interface StyledButtonProps {
  $background?: string;
  $padding?: string;
}

interface DataProps {
  text: string;
  color?: string;
  padding?: string;
  background?: string;
}

const StyledButton = styled.button<StyledButtonProps>`
  background: ${(props) => props.$background || "#fff"};
  padding: ${(props) => props.$padding || "1em 2em"};
  text-transform: uppercase;
  color: ${theme.colors.primary};
  letter-spacing: 0.1em;
  vertical-align: middle;
  border: 1px solid ${(props) => props.color || theme.colors.primary};
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
  ${ButtonAnimation};

  :focus,
  :enabled,
  :active {
    border: 1px solid ${(props) => props.color || theme.colors.primary};
  }
`;

const ButtonNoLink = ({ text, color, padding, background }: DataProps) => {
  return (
    <StyledButton color={color} $padding={padding} $background={background}>
      {text}
    </StyledButton>
  );
};

export default ButtonNoLink;
