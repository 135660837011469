import React from "react";

interface Props {
  pageTitle: string;
  pageDesc?: string;
  siteUrl?: string;
  ogImageAlt?: string;
  ogImage?: string;
  pageType?: string;
  schemaMarkup?: Record<string, unknown>;
  referrerPolicy?: "strict-origin-when-cross-origin";
}

const SEO = ({ pageTitle, pageDesc, siteUrl, ogImageAlt, ogImage, pageType, schemaMarkup, referrerPolicy }: Props) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <>
      <html lang="pl" />
      <title>{pageTitle}</title>
      <meta name="description" content={pageDesc} />
      <meta property="og:type" content={pageType} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:image:alt" content={ogImageAlt} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:locale" content="pl" />
      <meta property="og:description" content={pageDesc} />
      <meta property="og:site_name" content="Opcja Natura - Naturalnie Skuteczne Kosmetyki" />
      {schemaMarkup && <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>}
      {referrerPolicy && <meta name="referrer" content={referrerPolicy} />}
    </>
  );
};

export default SEO;
