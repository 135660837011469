import { CartDTO } from "./cart";
import { Product } from "./product";

export interface DataLayerItem {
  item_id: string;
  item_name: string;
  affiliation?: string;
  coupon?: string;
  currency: "PLN";
  discount: number;
  index?: number;
  item_brand?: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_list_id?: string;
  item_list_name?: string;
  item_variant?: string;
  price: number;
  quantity: number;
  product_id: string;
  bundle_id?: string;
}

const roundNumber = (val: number, scale = 2): number => {
  return Number((Math.round(val * 100) / 100).toFixed(scale));
};

export const toDataLayerItems = (cart: CartDTO | undefined): DataLayerItem[] => {
  if (!cart) {
    throw new Error("Cart must be defined");
  }
  const itemsTotal = cart.items.reduce((acc, item) => acc + item.price_total, 0);
  const globalCoupon = cart.discounts.find((discount) => discount.source.type === "COUPON")?.source.name;

  return cart.items
    .map((item) => {
      if (item.product.type === "BUNDLE") {
        return item.product.components.map((component) => {
          if (item.product.type !== "BUNDLE") {
            throw new Error("Item is not a bundle");
          }
          const discountedPrice = component.unit_price * (1 - item.product.bundleDiscountPercentage);
          const bundlePreviousPrice = item.product.previous_unit_price;
          return {
            discounts: item.discounts.map((discount) => ({ ...discount, amount: (discount.amount * component.unit_price) / bundlePreviousPrice })),
            price_total: discountedPrice * item.quantity,
            product: {
              ...component,
              unit_price: discountedPrice,
              previous_unit_price: component.unit_price,
              sale: false,
              ean: component.ean ?? "",
              bundle_id: item.product.product_id,
            },
            quantity: item.quantity,
          };
        });
      } else {
        return [item].map((item) => ({
          discounts: item.discounts,
          price_total: item.price_total,
          product: {
            ...(item.product as {
              type: "PRODUCT";
              ID: string;
              product_id: string;
              baselinker_id: string;
              description: string;
              name: string;
              unit_price: number;
              previous_unit_price?: number;
              leastPrice30Days: number;
              sale: boolean;
              slug: string;
              sku: string;
              ean: string;
              taxRate: number; // 0.23
              images: {
                file: {
                  url: string;
                };
              }[];
              custom_attributes: Product["custom_attributes"];
              bundle_id?: string;
            }),
          },
          quantity: item.quantity,
        }));
      }
    })
    .flat()
    .map((item) => {
      let discountTotal = 0;
      const contributionRatio = item.price_total / itemsTotal;
      let priceTotalAfterDiscount = item.price_total;
      cart.discounts.forEach((discount) => {
        if (discount.type === "FIXED" || discount.type === "PERCENTAGE" || discount.type === "FREE_SHIPPING") {
          const discountValue = discount.amount * contributionRatio;
          discountTotal += discountValue;
          priceTotalAfterDiscount = Math.max(priceTotalAfterDiscount - discountValue, 0);
        } else if (discount.type === "PERCENTAGE_PRODUCT") {
          const discountValue = priceTotalAfterDiscount * discount.source.refValue;
          discountTotal += discountValue;
          priceTotalAfterDiscount = Math.max(priceTotalAfterDiscount - discountValue, 0);
        } else if (discount.type === "FIXED_PRODUCT") {
          const discountValue = discount.amount;
          discountTotal += discountValue;
          priceTotalAfterDiscount = Math.max(priceTotalAfterDiscount - discountValue, 0);
        }
      });

      const saleDiscountDiff =
        Math.max(item.product.previous_unit_price && (item.product.sale || item.product.bundle_id) ? item.product.previous_unit_price - item.product.unit_price : 0, 0) / (1 + item.product.taxRate);
      const allProductDiscountsNet = discountTotal / (1 + item.product.taxRate);
      const itemNetTotal = Math.max(priceTotalAfterDiscount / (1 + item.product.taxRate), 0);
      // , (item.product.unit_price * item.quantity) / (1 + item.product.taxRate));
      // const itemNetTotal = Math.max((item.price_total - discountTotal) / (1 + item.product.taxRate), 0);
      const coupon = item.discounts.find((discount) => discount.source.type === "COUPON")?.source.name || globalCoupon;

      return {
        item_id: item.product.baselinker_id,
        item_name: item.product.name,
        quantity: item.quantity,
        currency: "PLN",
        price: roundNumber(itemNetTotal / item.quantity),
        discount: roundNumber(allProductDiscountsNet / item.quantity + saleDiscountDiff),
        ...(coupon ? { coupon } : undefined),
        product_id: item.product.product_id,
      };
    });
};
