import React from 'react'
import {PerfectMatchSinglePuzzleBrown} from "../../../assets/icons"
import {staticLinks} from "../../../utils/constants/staticLinks"
import Link from "./Link";

const IconPerfectMatch = () => {

   const {
    pages: {
      staticPages: { dopasujKosmetyk },
    },
  } = staticLinks;
  return (
    <div>
         <Link to={dopasujKosmetyk} >
      <PerfectMatchSinglePuzzleBrown className="navbar_icon"/>
      </Link>
    </div>
  )
}

export default IconPerfectMatch
