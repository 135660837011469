import * as React from "react";
import { memo } from "react";

function Box(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 64 64" fillRule="evenodd" clipRule="evenodd" width="1em" height="1em" {...props}>
      <path d="M32 9L6.929 18.635v26.153L31.999 55.8l25.072-11.012V18.635L32 9zm-7.65 5.891L32 11.954l21.36 8.21-8.437 3.473-20.573-8.746zm-13.71 5.272l10.011-3.849 20.69 8.799L32 28.959l-21.36-8.796zm33.533 16.493l1.106-.017V26.63l9.006-3.71V43l-20.892 9.173V31.53l9.1-3.752v9.905l1.68-1.027zM9.714 43V22.92l20.893 8.61v20.645L9.714 43.001z" />
    </svg>
  );
}

const MemoBox = memo(Box);
export default MemoBox;
