import styled from "styled-components";
import { theme as checkoutTheme } from "../../../styles/theme/checkoutTheme";
import { theme } from "../../../styles/theme/theme";

export const DiscountBoxFormContainer = styled.form`
  display: grid;
  justify-items: center;
  justify-content: space-between;
  align-items: center;
`;

export const DiscountBoxForm = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 8px 24px rgba(231, 235, 237, 0.6);
  border: 1px solid ${theme.colors.primary};
  max-width: 300px;
  box-sizing: border-box;
  padding: 0 ${theme.spaces.padding.base};
  height: 52px;

  span {
    text-transform: uppercase;
    display: inline;
    letter-spacing: 0.1em;
    margin-left: 0.5em;
  }
`;

export const StyledInput = styled.input`
  color: ${checkoutTheme.colors.labelColor};
  background: #fff;
  line-height: normal;
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;

  :focus {
    background: #fff;
  }
`;

export const DiscountBoxSubmitButton = styled.button`
  color: ${theme.colors.primary};
  white-space: nowrap;
  text-transform: uppercase;
`;

export const FormErrorMessage = styled.div`
  position: absolute;
  bottom: -1.6em;
  color: ${checkoutTheme.colors.errorColor};
  left: 0;
`;

export const FormMessage = styled.p`
  padding-top: 2em;
`;
