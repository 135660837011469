import React from "react";
import styled, { keyframes } from "styled-components";

const linearProgressAnimation = keyframes` 
  0% {
      background-size: 200% 100%;
      background-position: left -31.25% top 0%;
  }
  50% {
      background-size: 800% 100%;
      background-position: left -49% top 0%;
  }
  100% {
      background-size: 400% 100%;
      background-position: left -102% top 0%;
  }
`;

export const LinearProgress = styled.progress<{ $position?: string }>`
  position: ${({ $position }) => ($position ? $position : "relative")};
  vertical-align: top;
  bottom: -0.25em;
  left: 0;
  width: 100%;
  border: none;
  height: 0.25em;
  color: rgb(154 107 80);
  background-color: rgba(154 107 80), 0.12;
  font-size: 16px;

  &::-webkit-progress-bar {
    background-color: transparent;
  }

  &::-webkit-progress-value {
    background-color: currentColor;
    transition: all 0.2s;
  }

  &::-moz-progress-bar {
    background-color: currentColor;
    transition: all 0.2s;
  }

  &::-ms-fill {
    border: none;
    background-color: currentColor;
    transition: all 0.2s;
  }

  &:indeterminate {
    background-size: 200% 100%;
    background-image: linear-gradient(to right, transparent 50%, currentColor 50%, currentColor 60%, transparent 60%, transparent 71.5%, currentColor 71.5%, currentColor 84%, transparent 84%);
    animation: ${linearProgressAnimation} 2s infinite linear;
  }

  &:indeterminate::-moz-progress-bar {
    background-color: transparent;
  }

  .pure-material-progress-linear:indeterminate&::-ms-fill {
    animation-name: none;
  }
`;

const VSpacer = styled.div<{ $position?: string }>`
  height: 0.25em;
  font-size: 16px;
  position: ${({ $position }) => ($position ? $position : "relative")};
  vertical-align: top;
  display: inline-block;
`;

interface DataProps {
  loading: boolean;
  position?: string;
}

export const CartLinearProgress = (props: DataProps) => {
  if (props.loading) {
    return <LinearProgress $position={props.position} />;
  } else {
    return <VSpacer $position={props.position} />;
  }
};
