import React from "react";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import styled from "styled-components";

const StyledImage = styled(GatsbyImage)`
  max-width: 100%;
  max-height: 100%;
  z-index: -10;
`;

const Index = ({ image }: { image: ImageDataLike }) => {
  const gatsbyImage = getImage(image);
  if (!gatsbyImage) {
    throw new Error("No image found");
  }
  return (
    <>
      <StyledImage image={gatsbyImage} alt="naturalne kosmetyki" />
    </>
  );
};

export default Index;
