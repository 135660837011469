import React from "react";
import { getCurrentPage } from "../../utils/functions/common";
import { navigate } from "gatsby";
import { getWindow } from "../../utils/types/window";
import { FormInput, BoxContainer, BoxInnerContainer, StyledSearchIcon } from "./helpers/searchBoxHelpers";
import { InsufficientCharacters } from "./insufficientCharacters";

interface Props {
  value: string;
  $isMobileMenu?: boolean;
  setValue: (value: string) => void;
}

const SearchBox = ({ value, $isMobileMenu, setValue }: Props) => {
  const doSearch = (value: string, canTrigger: boolean) => {
    setValue(value);
    const isSearchPage = getCurrentPage() === "SEARCH";
    if (!isSearchPage && canTrigger) {
      void navigate(`/search?q=${value}`);
    } else if (isSearchPage) {
      const WAIT_TIME = 500;
      const timeouts = getWindow().searchTimeouts ?? [];
      getWindow().searchTimeouts = timeouts;

      timeouts.forEach((timeoutId) => clearTimeout(timeoutId));
      const timeoutId = setTimeout(() => {
        void navigate(`/search?q=${value}`);
      }, WAIT_TIME);
      timeouts.push(timeoutId);
    }
  };

  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const value = (event.target as unknown as { value: string }).value;
    const canTrigger = event.key.toUpperCase() === "ENTER";
    doSearch(value, canTrigger);
  };

  const onSearchIconClick = () => {
    const canTrigger = value.length > 2;
    doSearch(value, canTrigger);
  };

  const insufficientCharacters = value.length < 3 && value.length > 0;

  return (
    <BoxContainer>
      <BoxInnerContainer $isMobileMenu={$isMobileMenu}>
        <FormInput onKeyUp={onKeyUp} defaultValue={value} placeholder="Szukaj" />
        <StyledSearchIcon onClick={onSearchIconClick} $isMobileMenu={$isMobileMenu} />
        {insufficientCharacters && <InsufficientCharacters />}
      </BoxInnerContainer>
    </BoxContainer>
  );
};

export default SearchBox;
