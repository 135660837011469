import { useState, useEffect } from "react";
import { getWindow } from "../types/window";

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  } as { width?: number; height?: number });
  const handleResize = () => {
    setWindowSize({
      width: getWindow().innerWidth,
      height: getWindow().innerHeight,
    });
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);
  return windowSize;
}
