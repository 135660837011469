import * as React from "react";
import { memo } from "react";

function SignIn(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.609 57c-2.131 0-4.107-.888-5.562-2.499-1.543-1.707-2.268-4.023-1.988-6.342l.856-7.167c.487-4.11 2.666-7.791 5.985-10.095l1.753 2.454c-2.609 1.812-4.329 4.725-4.715 7.989l-.859 7.17a5.074 5.074 0 001.242 3.999c.868.96 2.037 1.491 3.288 1.491H45.39c1.251 0 2.42-.531 3.288-1.491a5.074 5.074 0 001.242-3.999l-.855-7.167c-.39-3.267-2.107-6.177-4.72-7.992l1.754-2.454c3.319 2.307 5.501 5.988 5.989 10.098l.852 7.164c.28 2.319-.445 4.635-1.988 6.342C49.501 56.112 47.523 57 45.391 57H19.61zM18.8 19.5C18.8 12.057 24.947 6 32.5 6c7.554 0 13.7 6.057 13.7 13.5S40.053 33 32.5 33c-7.552 0-13.699-6.057-13.699-13.5zm3.045 0c0 5.79 4.78 10.5 10.655 10.5 5.875 0 10.655-4.71 10.655-10.5S38.376 9 32.5 9c-5.876 0-10.655 4.71-10.655 10.5z"
      />
    </svg>
  );
}

const MemoSignIn = memo(SignIn);
export default MemoSignIn;
