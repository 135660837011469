import React from "react";
import styled from "styled-components";

import MainLogo from "../../../assets/icons/MainLogo";
import Link from "./Link";

const StyleWrapper = styled.div`
  align-self: center;
  justify-self: center;
  max-width: 22rem;
  height: auto;
`;

const StyledLogo = styled(MainLogo)`
  fill: inherit;
  transition: all 0.5s ease-out;
  width: 100%;
  height: auto;
`;

const Logo = () => {
  return (
    <StyleWrapper>
      <Link to="/" aria-label="Strona Główna">
        <StyledLogo />
      </Link>
    </StyleWrapper>
  );
};

export default Logo;
