import * as React from "react";
import { memo } from "react";

function VisaLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 56.48 56.48" width="3em" height="2em" {...props}>
      <path d="M55.026 9.939H1.454C.65 9.939 0 10.59 0 11.393v33.695c0 .803.65 1.454 1.454 1.454h53.572c.804 0 1.454-.651 1.454-1.454V11.393c0-.803-.65-1.454-1.454-1.454zM4.373 21h6.028c.813.031 1.468.29 1.694 1.159l1.311 6.305c-1.288-3.298-4.392-6.042-9.083-7.178l.05-.286zm7.294 14.38L8.185 23.484c2.503 1.602 4.635 4.144 5.386 5.913l.406 1.469 3.808-9.729h4.12l-6.123 14.24-4.115.003zm13.266.01h-3.888l2.431-14.268h3.89L24.933 35.39zm6.766.214c-1.743-.018-3.422-.363-4.332-.762l.547-3.193.501.227c1.277.533 2.104.749 3.661.749 1.117 0 2.314-.437 2.325-1.393.007-.625-.501-1.071-2.015-1.771-1.476-.682-3.43-1.826-3.406-3.875.021-2.773 2.729-4.708 6.572-4.708 1.506 0 2.713.31 3.483.599l-.525 3.093-.351-.165a7.174 7.174 0 00-2.91-.546c-1.522 0-2.228.634-2.228 1.227-.008.668.824 1.108 2.184 1.77 2.243 1.018 3.28 2.253 3.266 3.876-.03 2.96-2.683 4.872-6.772 4.872zm16.85-.208s-.355-1.64-.473-2.138l-4.98-.006c-.151.385-.817 2.144-.817 2.144h-4.083l5.773-13.075c.41-.929 1.105-1.184 2.037-1.184h3.004l3.147 14.258-3.608.001z" />
      <path d="M46.542 26.215l-.262-1.23c-.198.537-.539 1.4-.517 1.362 0 0-1.229 3.167-1.549 3.988h3.229l-.901-4.12z" />
    </svg>
  );
}

const MemoVisaLogo = memo(VisaLogo);
export default MemoVisaLogo;
