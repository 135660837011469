import React from "react";
import styled from "styled-components";
import { theme } from "../../../styles/theme/theme";

const TitleSpan = styled.span`
  color: ${theme.colors.secondary};
  text-transform: uppercase;
  line-height: 1;

  @media (min-width: 980px) {
    font-size: ${theme.fonts.fontSize.xl};
  }
`;

interface ProductTitleSpanProps {
  children: React.ReactElement;
}

const ProductTitleSpan = ({ children }: ProductTitleSpanProps) => {
  return <TitleSpan>{children}</TitleSpan>;
};

export default ProductTitleSpan;
