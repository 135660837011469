import React from "react";
import { IconUser } from "../../../assets/icons";
import Link from "./Link";

const UserIcon = () => {
  return (
    <div className="tooltip icon-account" data-text="konto" data-testid={"user-link-icon"}>
      <Link to="/login" className="show-account" data-testid={"login-link-icon"} aria-label="login">
        <IconUser className="navbar_icon" />
      </Link>
    </div>
  );
};

export default UserIcon;
