import styled from "styled-components";

export const MenuNavWrapper = styled.nav`
  display: none;

  @media (min-width: 1100px) {
    display: block;
    align-self: center;
  }
`;

export const MenuListWrapper = styled.ul`
  text-decoration: none;
  list-style-type: none;
  color: inherit;
  display: flex;
  align-items: center;
`;
