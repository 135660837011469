import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import FadeIn from "../../../styles/mixins/fadeIn";
import { theme } from "../../../styles/theme/theme";
import Link from "../../UI/elements/Link";
import TagCloud from "../TagCloud";

const Wrapper = styled.section`
  max-width: 1360px;
  animation: ${FadeIn} ease 2s;
  display: grid;
  grid-template-rows: 190px auto auto auto;

  @media (min-width: 800px) {
    grid-template-rows: 250px 1fr 1fr auto;
  }

  .titles {
    margin-top: ${theme.spaces.margin.base};
    text-transform: uppercase;
  }

  span {
    font-weight: ${theme.fonts.fontWeight.thin};
  }

  h2 {
    padding-top: ${theme.spaces.padding.small};
    letter-spacing: 4px;
    font-weight: ${theme.fonts.fontWeight.normal};
    font-size: ${theme.fonts.fontSize.xxl};
  }

  p {
    line-height: 1.8;
    align-self: start;
  }

  .link-container {
    margin-top: ${theme.spaces.margin.big};
  }

  .tag-link {
    margin-right: 0.5em;
  }
`;

const StyledImage = styled(GatsbyImage)`
  height: 190px;

  @media (min-width: 800px) {
    height: 250px;
  }
`;

interface PostListItemProps {
  post: {
    title: string;
    slug: string;
    excerpt: {
      excerpt: string;
    };
    featuredImage: {
      title: string;
    };
    tags: string[];
  };
}

const PostListItem = ({ post }: PostListItemProps) => {
  const image = getImage(post.featuredImage);
  if (!image) {
    throw new Error(`No image for post ${JSON.stringify(post)}`);
  }

  return (
    <Wrapper>
      <div className="image">
        <Link to={`/blog/${post.slug}`}>
          <StyledImage image={image} alt={post.featuredImage.title} />
        </Link>
      </div>
      <div className="titles">
        <TagCloud tags={post.tags} />
        <Link to={`/blog/${post.slug}`}>
          <h2>{post.title}</h2>
        </Link>
      </div>
      <div className="text">
        <p>{post?.excerpt?.excerpt}</p>
      </div>
      <span className="link-container">
        <Link to={`/blog/${post.slug}`} className="border__out">
          Czytaj dalej
        </Link>
      </span>
    </Wrapper>
  );
};

export default PostListItem;
