import React from "react";
import styled from "styled-components";
import { theme } from "../../../styles/theme/theme";

const StyledWrapper = styled.div<{ $place?: string; $color?: string }>`
  grid-area: title;
  display: grid;
  row-gap: 1em;
  place-items: ${(props) => props.$place || "center"};
  text-align: center;
  text-transform: uppercase;
  margin-bottom: ${theme.spaces.margin.vertical_big};
  color: ${(props) => props.$color || "#0B0A0A"};
`;
const Span = styled.span`
  font-weight: ${theme.fonts.fontWeight.thin};
  font-size: ${theme.fonts.fontSize.lg};
  letter-spacing: 5px;
  margin-bottom: ${theme.spaces.margin.xl};
  color: inherit;

  @media (min-width: 800px) {
    margin-bottom: ${theme.spaces.margin.big};
  }
`;

const Title = styled.h2`
  color: inherit;
  letter-spacing: 3px;
  font-size: ${theme.fonts.fontSize["2xl"]};
  font-weight: ${theme.fonts.fontWeight.light};
`;

interface Props {
  title: string;
  span?: string;
  children?: React.ReactElement;
  color?: string;
  place?: string;
  titleTestid?: string;
  spanTestId?: string;
}

const TitleHomepage = ({ children, title, span, color, place, titleTestid, spanTestId }: Props) => {
  return (
    <StyledWrapper $color={color} $place={place}>
      {span && <Span data-testid={spanTestId}>{span}</Span>}
      {title && <Title data-testid={titleTestid}>{title}</Title>}
      {children}
    </StyledWrapper>
  );
};

export default TitleHomepage;
