import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
import { theme } from "../../../styles/theme/theme";
import { PerfectMatchSinglePuzzleBrown } from "../../../assets/icons";
import { hideHamburger } from "./navbarHelpers";

export const Close = styled(AiOutlineClose)`
  width: 20px;
  height: auto;
  fill: ${theme.colors.primary};
  cursor: pointer;
`;

export const MenuNavWrapper = styled.nav<{ open: boolean }>`
  display: flex;
  background: #fff;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100vw)")};
  height: 100vh;
  padding: ${theme.spaces.padding.large};

  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.5s ease-in-out;
  border-right: 1px solid ${theme.colors.primary_opacity};
  z-index: 100;

  @media (min-width: ${hideHamburger}) {
    display: none;
  }
`;

export const MenuListWrapper = styled.ul`
  text-decoration: none;
  list-style-type: none;
  flex-direction: column;
  padding-right: 2rem;
  color: #000;
  align-items: flex-start;
`;

export const ShopCategoriesMenuWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  margin: ${theme.spaces.margin.large} 0;

  li {
    padding-left: 4em;
    padding-bottom: ${theme.spaces.padding.big};
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .activeMobile {
    font-weight: bolder;
    color: ${theme.colors.black};
  }

  .perfectMatchLink {
    position: relative;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;
  fill: ${theme.colors.primary};
`;

export const UserIconsWrapper = styled.div`
  align-self: center;
  justify-self: end;
  display: grid;
  grid-auto-flow: column;
  justify-items: end;
  column-gap: 2em;
`;

export const PerfectMatchPseudoIcon = styled(PerfectMatchSinglePuzzleBrown)`
  position: absolute;
  top: -0.7em;
  max-width: 2em;
  left: 1em;
  fill: #b6845c;

  @media (min-width: ${hideHamburger}) {
    display: none;
  }
`;
