export const theme = {
  colors: {
    globalBckg: "#fff",
    stepBckg: "#fff",
    stepCompletedBckg: "#f7fafa",
    inputBckg: "#fdfefe",
    labelColor: "#313332",
    borderColor: "#ced4da",
    borderColorLight: "rgba(206, 212, 218, 0.4)",
    errorColor: "#AF0606",
    promoColor: "#236F21",
    shadowColor: "rgba(221, 220, 219, 0.5)",
  },
  others: {
    shadowDegree: "1px 3px 10px 0px",
  },
};
