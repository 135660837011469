import * as React from "react";
import { memo } from "react";

function IconBusket(props: React.HTMLAttributes<SVGElement>) {
  return (
    <svg viewBox="0 0 1646 1946" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" {...props}>
      <path
        d="M1473 503h-234c5-27 8-54 8-82-1-233-191-422-423-421-233 1-422 190-421 423 0 27 3 54 8 80H172L0 1946h1646L1473 503zm-29 31h2l4 38-45-18 39-20zm-67 0l-17 9c-5 3-8 8-8 14s4 12 10 14l24 9h-170c6-15 11-30 15-46h146zm-199 46H471c-6-15-12-30-17-46h741c-5 16-10 31-17 46zM438 423C437 209 610 35 824 35c213-1 387 172 388 386 0 28-3 56-9 82H446c-5-26-8-53-8-80zm-4 157H255l25-9c5-2 9-8 9-14 1-6-3-11-8-14l-17-9h154c4 16 10 31 16 46zm-234-45l37 19-42 16 5-35zm-9 76h256c18 34 40 67 67 96-5 10-7 21-7 33 0 48 38 86 85 86 48 0 86-38 86-86 0-47-38-85-86-85-22 0-42 8-58 22-18-20-34-42-47-66h676c-13 21-27 42-43 61-15-11-32-17-52-17-47 0-86 38-86 85 0 48 39 86 86 86s86-38 86-86c0-15-4-29-11-41 23-27 43-57 59-88h253l156 1304H35L191 611zm373 143c4 2 7 3 11 3 5 0 10-2 14-6 6-8 4-19-3-25-11-8-21-17-31-26 10-9 23-15 37-15 31 0 56 25 56 55 0 31-25 56-56 56-30 0-55-25-55-56 0-3 1-6 1-9 9 8 17 16 26 23zm559-14c0 31-25 56-55 56s-55-25-55-56c0-30 25-55 55-55 12 0 22 4 31 10-9 9-19 18-30 27-7 6-8 17-2 24 6 8 17 9 24 3 10-8 20-17 29-26 2 5 3 11 3 17z"
        fillRule="nonzero"
      />
    </svg>
  );
}

const MemoIconBusket = memo(IconBusket);
export default MemoIconBusket;
