import React, { memo } from "react";

const PerfectMatchSinglePuzzleBrown = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="19.916 39.224 119.914 119.913"
      enableBackground="new 19.916 39.224 119.914 119.913"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      {...props}
    >
      <path
        fillRule="nonzero"
        d="M90.709,159.137h-0.154c-2.826-0.043-5.474-1.189-7.457-3.232c-1.985-2.045-3.056-4.727-3.014-7.553
	c0.047-2.942,1.33-5.762,3.52-7.732c0.375-0.338,0.59-0.824,0.59-1.335c0-0.484-0.187-0.938-0.523-1.277
	c-0.342-0.346-0.797-0.534-1.283-0.534H41.581V96.667c0-0.998-0.813-1.81-1.812-1.81c-0.508,0-0.995,0.217-1.335,0.594
	c-1.972,2.191-4.792,3.473-7.734,3.516l-0.179,0.002c-2.754,0-5.373-1.07-7.372-3.012c-2.045-1.984-3.193-4.633-3.232-7.456
	c-0.04-2.867,1.047-5.57,3.059-7.611c2.011-2.04,4.695-3.163,7.56-3.163c3.011,0,5.89,1.283,7.899,3.522
	c0.338,0.376,0.826,0.593,1.337,0.593c0.997,0,1.809-0.813,1.809-1.813V39.224h40.808c0.469,0,0.852,0.383,0.852,0.854
	c0,0.244-0.102,0.474-0.277,0.629c-2.385,2.147-3.783,5.219-3.832,8.424c-0.047,3.084,1.119,6.009,3.283,8.237
	s5.05,3.478,8.127,3.521l0.168,0.001c3.061,0,5.945-1.184,8.125-3.333c2.225-2.193,3.449-5.121,3.449-8.244
	c0-3.279-1.398-6.417-3.838-8.609c-0.172-0.152-0.273-0.382-0.273-0.626c0-0.47,0.383-0.853,0.854-0.853h40.806v40.521
	c0,0.627-0.508,1.136-1.132,1.136c-0.16,0-0.314-0.03-0.447-0.089c-0.154-0.069-0.285-0.164-0.393-0.282
	c-2.172-2.361-5.135-3.69-8.338-3.739l-0.181-0.001c-3.017,0-5.88,1.169-8.063,3.291c-2.225,2.16-3.471,5.045-3.514,8.123
	c-0.046,3.122,1.135,6.066,3.328,8.292c2.194,2.226,5.123,3.451,8.25,3.451c3.225,0,6.33-1.363,8.521-3.74
	c0.412-0.451,1.205-0.469,1.637-0.035c0.211,0.207,0.33,0.492,0.33,0.799v40.521H99.025c-0.998,0-1.812,0.813-1.812,1.813
	c0,0.507,0.216,0.994,0.593,1.338c2.235,2.01,3.521,4.889,3.521,7.896c0,2.861-1.124,5.547-3.162,7.561
	C96.164,158.05,93.516,159.137,90.709,159.137z"
      />
      <path
        fillRule="nonzero"
        d="M42.593,136.458h39.794c0.76,0,1.471,0.297,2.002,0.834c0.526,0.528,0.817,1.235,0.817,1.991
	c0,0.797-0.337,1.559-0.925,2.087c-1.981,1.784-3.142,4.335-3.185,6.997c-0.038,2.555,0.931,4.98,2.728,6.832
	c1.794,1.848,4.189,2.887,6.744,2.924l0.14,0.001c2.54,0,4.935-0.981,6.745-2.769c1.845-1.82,2.861-4.25,2.861-6.84
	c0-2.721-1.162-5.324-3.188-7.145c-0.589-0.538-0.925-1.299-0.925-2.09c0-1.557,1.267-2.824,2.823-2.824h39.793V96.949
	c0-0.021-0.006-0.057-0.03-0.08c-0.036-0.036-0.093-0.096-0.179,0.001c-2.385,2.586-5.762,4.067-9.268,4.067
	c-3.398,0-6.585-1.332-8.971-3.752c-2.385-2.42-3.67-5.621-3.62-9.017c0.046-3.349,1.403-6.486,3.821-8.835
	c2.373-2.308,5.486-3.578,8.768-3.578l0.196,0.002c3.483,0.053,6.705,1.497,9.069,4.068c0.013,0.013,0.026,0.026,0.058,0.04
	c0.091,0.013,0.153-0.054,0.153-0.122V40.236H99.432c2.463,2.37,3.864,5.652,3.864,9.075c0,3.396-1.332,6.58-3.75,8.965
	c-2.37,2.337-5.508,3.624-8.836,3.624h-0.181c-3.35-0.047-6.489-1.406-8.842-3.827c-2.353-2.423-3.621-5.604-3.568-8.958
	c0.051-3.347,1.454-6.559,3.863-8.879h-39.39v39.793c0,1.558-1.265,2.824-2.821,2.824c-0.798,0-1.56-0.338-2.09-0.929
	c-1.818-2.024-4.422-3.186-7.146-3.186c-2.591,0-5.02,1.016-6.839,2.861c-1.82,1.847-2.803,4.292-2.767,6.887
	c0.035,2.553,1.074,4.948,2.924,6.743c1.81,1.758,4.177,2.727,6.667,2.727l0.164-0.002c2.663-0.038,5.213-1.198,6.998-3.182
	c0.532-0.59,1.292-0.928,2.087-0.928c1.558,0,2.824,1.266,2.824,2.821v39.793H42.593z"
      />
    </svg>
  );
};

const MemoPerfectMatchSinglePuzzleBrown = memo(PerfectMatchSinglePuzzleBrown);
export default MemoPerfectMatchSinglePuzzleBrown;
