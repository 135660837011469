import React, { useState } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs } from "swiper";
import "swiper/swiper-bundle.css";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import ProductPromoBadge from "../ProductPromoBadge";
import { ThumbsOptions } from "swiper/types";
import { ProductProps } from "../../../../utils/types/graphQLTypes";
import { useDidInteract } from "../../../../utils/hooks/useDidInteract";

SwiperCore.use([Navigation, Thumbs]);

const Wrapper = styled.section`
  margin-top: 2.5em;
  justify-self: right;
`;

const GalleryWrapper = styled.div`
  position: relative;
  width: 320px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 850px) {
    width: 400px;
    margin-right: 3em;
    position: sticky;
    overflow: hidden;
    top: 11em;
    margin-left: unset;
  }
  @media (min-width: 1200px) {
    width: 500px;
  }

  .thumbImage {
    max-width: 130px;
    cursor: pointer;
  }

  .thumbWrapper {
    width: 100%;
    margin-bottom: 2em;
  }
`;

const StyledSwiper = styled(Swiper)`
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const FakeSliderWrapper = styled.div`
  width: 100%;
  margin-bottom: 2em;
  display: flex;
`;

const FakeSliderImageResizer = styled.div`
  width: 76.25px;
  margin-right: 5px;

  @media (min-width: 850px) {
    width: 96.25px;
  }

  @media (min-width: 1200px) {
    width: 121.25px;
  }
`;

interface Props {
  images: {
    fileLocal: ImageDataLike;
  }[];
  label: ProductProps["label"];
  imageAlt?: string;
}

const Index = ({ images, label, imageAlt }: Props) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(undefined as ThumbsOptions["swiper"]);

  //TODO KNUR to sobie mozesz ustawic na sztywno na false, zeby nie przeskakiwalo
  const didUserInteract = useDidInteract();
  return (
    <Wrapper>
      <GalleryWrapper>
        <ProductPromoBadge label={label} position={{ bottom: "68%", right: "7px" }} />
        {!didUserInteract ? (
          <>
            <FakeSliderWrapper>
              {images.map((image, i) => {
                const img = getImage(image.fileLocal);
                if (!img) {
                  throw new Error("No image found");
                }
                return (
                  <FakeSliderImageResizer key={i}>
                    <GatsbyImage  image={img} alt={imageAlt ? `${imageAlt} ${i}` : `kosmetyki Opcja Natura ${i}`} className="thumbImage" loading={"lazy"} />
                  </FakeSliderImageResizer>
                );
              })}
            </FakeSliderWrapper>

            <GatsbyImage image={getImage(images[0].fileLocal)!} alt={imageAlt ? `${imageAlt}` : `kosmetyki Opcja Natura`} loading={"eager"} />
          </>
        ) : (
          <>
            <Swiper onSwiper={setThumbsSwiper} spaceBetween={5} slidesPerView={4} freeMode={true} watchSlidesProgress={true} className="thumbWrapper">
              {images.map((image, i) => {
                const img = getImage(image.fileLocal);
                if (!img) {
                  throw new Error("No image found");
                }
                return (
                  <SwiperSlide key={i}>
                    <GatsbyImage image={img} alt={imageAlt ? `${imageAlt} ${i}` : `kosmetyki Opcja Natura ${i}`} className="thumbImage" loading={"lazy"} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <StyledSwiper spaceBetween={30} slidesPerView={1} navigation={true} thumbs={{ swiper: thumbsSwiper }}>
              {images.map((image, i) => {
                const img = getImage(image.fileLocal);
                if (!img) {
                  throw new Error("No image found");
                }
                return (
                  <SwiperSlide key={i}>
                    <GatsbyImage image={img} alt={imageAlt ? `${imageAlt} ${i}` : `kosmetyki Opcja Natura ${i}`} loading={"lazy"} />
                  </SwiperSlide>
                );
              })}
            </StyledSwiper>
          </>
        )}
      </GalleryWrapper>
    </Wrapper>
  );
};

export default Index;
