import { IAPIError } from "@on/model-store";

export const getCookie = (name: string): string | undefined => {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) {
    if (match[2] === "undefined" || match[2] === "null") {
      return undefined;
    }
    return decodeURIComponent(match[2]);
  } else {
    return undefined;
  }
};

export const removeCookie = (name: string): void => {
  const getCookieInternal = (name_internal: string) => {
    return document.cookie.split(";").some((c) => {
      return c.trim().startsWith(name_internal + "=");
    });
  };
  if (getCookieInternal(name)) {
    const path: string = "/";
    const domain: string = window.location.hostname;
    document.cookie = name + "=" + (path ? ";path=" + path : "") + (domain ? ";domain=" + domain : "") + ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isApiError = (obj: any): obj is IAPIError => "message" in obj && "code" in obj;
