import React from "react";
import Link from "../UI/elements/Link";
import styled from "styled-components";
import { theme } from "../../styles/theme/theme";

const StyledLink = styled(Link)`
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  padding: ${theme.spaces.padding.large} ${theme.spaces.padding.large} ${theme.spaces.padding.large} 0;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    bottom: 0;
    background: #0b0a0a;
    height: 1px;
    transition-property: right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  &:hover,
  &:focus,
  &:active {
    &:before {
      right: 0;
    }
  }
`;

const FooterLink = ({ url, title }: { url?: string; title: string }) => {
  return <StyledLink to={url ? url : "error"}>{title}</StyledLink>;
};

export default FooterLink;
