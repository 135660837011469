import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { staticLinks } from "../../utils/constants/staticLinks";
import { MenuNavWrapper, MenuListWrapper } from "./helpers/desktopMenuHelpers";
import { MenuListItem } from "./helpers/navbarHelpers";
import Link from "../UI/elements/Link";

const query = graphql`
  {
    storeCategory(isSeasonCategory: { eq: true }) {
      name
      slug
    }
  }
`;

type CategoriesPropsLocal = {
  storeCategory: {
    name: string;
    slug: string;
  };
};

type DataProps = CategoriesPropsLocal;

const DesktopMenu = () => {
  const { storeCategory: seasonCategory } = useStaticQuery<DataProps>(query);
  const {
    pages: {
      staticPages: { sklep, oNas, gwiazdka2024 },
    },
  } = staticLinks;
  return (
    <MenuNavWrapper data-testid={"navbar-desktop__wrapper"}>
      <MenuListWrapper>
        <MenuListItem>
          <Link to={sklep} activeClassName="active">
            sklep
          </Link>
        </MenuListItem>
        <MenuListItem className="border">
          <Link to={oNas} activeClassName="active">
            o nas
          </Link>
        </MenuListItem>
        <MenuListItem className="border">
          <Link to={gwiazdka2024} activeClassName="active">
            gwiazdka 2024
          </Link>
        </MenuListItem>
        <MenuListItem className="border">
          <Link to={`/sklep/${seasonCategory.slug}`} activeClassName="active">
            {seasonCategory.name}
          </Link>
        </MenuListItem>
      </MenuListWrapper>
    </MenuNavWrapper>
  );
};

export default DesktopMenu;
