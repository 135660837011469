import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import FadeIn from "../../../styles/mixins/fadeIn";
import { PostProps } from "../../../utils/types/graphQLTypes";
import { theme } from "../../../styles/theme/theme";
import TagCloud from "../TagCloud";
import { ButtonGlobal, BgImage, BgImageText } from "../../UI";

const Wrapper = styled.div`
  animation: ${FadeIn} ease 2s;
  display: grid;

  @media (min-width: 750px) {
    grid-column: 1 / span 2;
  }

  @media (min-width: 1122px) {
    grid-column: 1 / span 3;
  }

  .content {
    color: ${theme.colors.white};
    text-transform: uppercase;
    align-self: end;
    padding: 3em;
  }
  span {
    color: inherit;
  }
  h2 {
    color: inherit;
    letter-spacing: 5px;
    margin: ${theme.spaces.margin.big} 0;
  }

  .tag-link {
    margin-right: 0.5em;
  }
`;

const query = graphql`
  {
    post: contentfulBlogPosts(featuredPost: { eq: true }) {
      ...PostItem
    }
  }
`;

const FeaturedPostListItem = () => {
  const { post } = useStaticQuery<{ post: PostProps }>(query);
  const image = post.featuredImage;
  if (!image) {
    throw new Error("Featured image not found");
  }

  return (
    <Wrapper>
      <BgImage image={image} $height="400px" $tag="div" />
      <BgImageText textOverlay={true}>
        <div className="content">
          <TagCloud tags={post.tags} />
          <h2>{post.title}</h2>
          <ButtonGlobal text="czytaj więcej" color="#fff" link={`/blog/${post.slug}`} />
        </div>
      </BgImageText>
    </Wrapper>
  );
};

export default FeaturedPostListItem;
