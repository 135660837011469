import { createContext, useContext } from "react";
import { Action, CartState } from "../../utils/types/states";

const reducerInternal = (currentState: CartState, action: Action): CartState => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { globalMessage, ...currentStateWithoutGlobalMessage } = currentState;
  const state = {
    ...currentStateWithoutGlobalMessage,
    action: action.type,
    prev: currentState,
  };
  if ("coupon_code" in state && !("coupon" in action.payload)) {
    delete state.coupon_code;
  }

  switch (action.type) {
    case "CART_LOADED":
      return { ...action.payload, isCartLoaded: true, action: "CART_LOADED" };
    case "CART_RECOVERED":
      return { ...action.payload, isCartLoaded: true, action: "CART_RECOVERED" };
    case "LOGIN":
      return { ...state, ...action.payload };
    case "LOGOUT":
      return { ...state, ...action.payload };
    case "GUEST":
      return { ...state, ...action.payload };
    case "USER_REGISTERED":
      return { ...state, ...action.payload };
    case "ACCOUNT_ADDRESS_UPDATED":
      return { ...state, ...action.payload };
    case "CHECKOUT_BILLING_UPDATED":
      return { ...state, ...action.payload };
    case "CHECKOUT_SHIPPING_UPDATED":
      return { ...state, ...action.payload };
    case "CHECKOUT_SHIPPING_METHOD_UPDATED":
      return { ...state, ...action.payload };
    case "ADD_ITEM":
      return { ...state, ...action.payload };
    case "SET_QUANTITY":
      return { ...state, ...action.payload };
    case "REMOVE_ITEM":
      return { ...state, ...action.payload };
    case "REMOVE_COUPON":
      return { ...state, ...action.payload };
    case "PACKED_AS_GIFT":
      return { ...state, ...action.payload };
    case "GIFT_PACKING_UPDATED":
      return { ...state, ...action.payload };
    case "GIFT_PACKING_REMOVED":
      return { ...state, ...action.payload };
    case "APPLY_COUPON":
      return { ...state, ...action.payload, ...{ showCouponError: { state: false } } };
    case "APPLY_COUPON_FAILED": {
      const { errorMessage, ...payloadRest } = action.payload;
      return { ...state, ...payloadRest, ...{ showCouponError: { state: true, message: errorMessage } } };
    }
    case "CLEAR_DISCOUNT_ERROR":
      return { ...state, ...action.payload, ...{ showCouponError: { state: false } } };
    case "REMOVE_GLOBAL_MESSAGE":
      return { ...state, ...action.payload };
    case "CART_LOADED_FROM_ORDER":
      return { ...state, ...action.payload };
    case "BLOCK_CHECKOUT":
      return { ...state, ...action.payload };
    case "UNBLOCK_CHECKOUT":
      return { ...state, ...action.payload };
    default:
      throw new Error("no such case in cartReducer");
  }
};

export const getInitialState = () => {
  return { isCartLoaded: false };
};

export const reducer = (state: CartState, action: Action): CartState => {
  return reducerInternal(state, action);
};

export const CartContext = createContext({
  dispatch: () => {},
  state: {},
}) as unknown as React.Context<{
  dispatch: React.Dispatch<Action>;
  state: (CartState & { isCartLoaded: true }) | (Partial<CartState> & { isCartLoaded: false });
}>;

export const useCartContext = () => useContext(CartContext);
