import React from "react";
import { SearchIcon } from "../../../assets/icons";
import { staticLinks } from "../../../utils/constants/staticLinks";
import Link from "./Link";

const IconSearchDynamic = () => {
  const {
    pages: {
      staticPages: { search },
    },
  } = staticLinks;

  return (
    <div>
      <Link to={search}>
        <SearchIcon className="navbar_icon" />
      </Link>
    </div>
  );
};

export default IconSearchDynamic;
