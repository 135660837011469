export const staticCompanyData = {
  pickup: 'Ul. Kościuszki 9 "Prezentownik"',
  past: {
    name: "Opcja Natura Agata Suliga",
    adress: "ul. Sportowa 1/1",
    zip: "63-714",
    city: "Kobierno",
    vatNo: "6211768215",
    workshopAdress: "ul. Kościuszki 8",
    workshopZip: "63-700",
    workshopCity: "Krotoszyn",
    mail: "sklep@opcjanatura.pl",
    phone: "667503749",
  },
  current: {
    name: "Opcja Natura Sp. z o.o.",
    adress: "ul. Kościuszki 8C/7",
    zip: "63-700",
    city: "Krotoszyn",
    vatNo: "6211846895",
    workshopAdress: "ul. Kościuszki 8",
    workshopZip: "63-700",
    workshopCity: "Krotoszyn",
    mail: "sklep@opcjanatura.pl",
    phone: "536214728",
  },
};
