import { css, keyframes } from "styled-components";
import { theme } from "../theme/theme";

export const borderBefore = css`
  position: relative;

  &:before {
    content: "";
    width: 100%;
    position: absolute;
    top: 0;
    height: 1px;
    opacity: 0.9;
    color: ${theme.colors.secondary};
    border: 1px solid ${theme.colors.secondary_opacity};
  }
`;

export const borderAfter = css`
  position: relative;

  &:after {
    content: "";
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 1px;
    opacity: 0.9;
    color: ${theme.colors.secondary};
    border: 1px solid ${theme.colors.secondary_opacity};
  }
`;
export const linkBorderOut = css`
  display: inline-block;
  color: #000;
  text-decoration: none;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: #0b0a0a;
    transition: width 0.3s;
  }
  &:hover {
    &:after {
      width: 0;
    }
  }
`;

export const linkBorderIn = css`
  transition: all 200ms ease-in;
  position: relative;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  overflow: hidden;

  :before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    bottom: 0;
    background: ${theme.colors.primaryOld};
    height: 1px;
    transition-property: right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  :hover:before,
  :focus:before,
  :active:before {
    right: 0;
  }
`;

export const ButtonAnimation = css`
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${theme.colors.primary};
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  &:focus,
  &:enabled,
  &:active {
    border: 1px solid ${theme.colors.primary};
  }
  &:hover,
  &:active {
    color: #fff;
  }

  &:hover&:before,
  &:active&:before {
    transform: scaleX(1);
  }
`;

export const SectionBottomLine = css`
  ::after {
    content: "";
    height: 1px;
    width: 100%;
    border: 1px solid ${theme.colors.secondary_opacity};
    margin-top: ${theme.spaces.margin.vertical_big};
  }
`;

export const pulseAnimation = keyframes`
  0% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}
	80% {
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
`;

export const fadeIn = keyframes`
  0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

export const fadeInAnimation = css`
  animation: ${fadeIn} 1s ease-in-out;
`;

export const FadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const rotate = keyframes`
from {
   opacity: 0;
   transform: rotate(0deg);
 }
 to {
   opacity: 1;
   transform: rotate(-45deg);
 }
`;

export const heightBounce = keyframes`
  0% {
    height: 0%;
  }
  50% {
    height: 100%;
  } 
  100% {
    height: 0%;
  }`;

export const spin = keyframes`
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
`;

export const arrowAnimation = keyframes`
  0%   { 
    transform: translate(-10px,0px) rotate(-45deg); 
    opacity: 0;  
  }
  50%  { 
    opacity: 1;  
  }
  100% 
  { 
    transform:translate(10px,0) rotate(-45deg); 
    opacity: 0; 
  }
`;