const data = [
  {
    title: "manufaktura kosmetyczna",
    text: "Opcja Natura to niezależny, Polski producent kosmetyków naturalnych. Receptury naszych kosmetyków to efekt wieloletniej pracy, doświadczenia oraz walki o jakość. Nasze produkty powstają z miłości do Natury, a w ich produkcję wkładamy mnóstwo serca i zaangażowania.",
  },
  {
    title: "naturalne składy",
    text: "Opcja Natura to 100% naturalne, roślinne kosmetyki bez sztucznych barwników, chemii i perfum zapachowych. Marka stworzona przez Agatę, magister biologii i miłośniczkę polskich łąk, roślin i wszelkiego rodzaju zieleniny.",
  },
  {
    title: "balans dla cery",
    text: "Przyroda to balans, suma składników, w której nigdy nic nie ginie. Nasze eko kosmetyki mają przywrócić balans Twojej cery. Wierzymy w moc Natury, dlatego pakujemy ją słoiczki i buteleczki i pozwalamy jej działać. Zachęcamy do sprawdzenia jej na swojej skórze - to naprawdę działa!",
  },
  {
    title: "niezależe badania",
    text: "Opcja Natura powstała bez zbędnego pośpiechu. Pomysł dojrzewał przez kilka lat, kiedy to testowaliśmy oraz tworzyliśmy receptury. Mieliśmy czas zapoznać się z kolejnymi składnikami, stworzyć świetne nuty zapachowe czy wreszcie przetestować działanie. Dlatego teraz możecie cieszyć się naprawdę szeroką gamą naturalnych kosmetyków - od kremów do twarzy, przez maseczki i peelingi, po żele i szampony.",
  },
];

export default data;
