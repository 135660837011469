import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { slugify } from "../../../utils/functions/slugify";
import { theme } from "../../../styles/theme/theme";
import Link from "../../UI/elements/Link";
import { AllContentfulBlogPostsProps } from "../../../utils/types/graphQLTypes";

const Wrapper = styled.ul`
  grid-area: navigation;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding: ${theme.spaces.padding.xxl};
  font-weight: ${theme.fonts.fontWeight.normal};
  font-weight: ${theme.fonts.fontWeight.normal};
  text-transform: uppercase;
  font-size: ${theme.fonts.fontSize.base};

  li {
    color: ${theme.colors.secondary};
    margin-left: ${theme.spaces.padding.large};
    padding-top: ${theme.spaces.padding.big};
  }

  a.active > li {
    font-weight: ${theme.fonts.fontWeight.bold};
    color: ${theme.colors.black};
    border-bottom: 1px solid #000;
  }

  a.active:after {
    display: none;
  }
`;

const query = graphql`
  {
    postTags: allContentfulBlogPosts {
      distinct(field: { tags: SELECT })
    }
  }
`;

const BlogNavigation = () => {
  const { postTags } = useStaticQuery<{ postTags: AllContentfulBlogPostsProps }>(query);

  return (
    <Wrapper>
      <Link to="/blog" activeClassName="active">
        <li>wszystkie</li>
      </Link>
      {postTags.distinct.map((tag, i) => {
        return (
          <Link to={`/blog/${slugify(tag)}`} key={i} activeClassName="active">
            <li>{tag}</li>
          </Link>
        );
      })}
    </Wrapper>
  );
};

export default BlogNavigation;
