exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-c-tsx": () => import("./../../../src/pages/c.tsx" /* webpackChunkName: "component---src-pages-c-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-dopasuj-kosmetyk-tsx": () => import("./../../../src/pages/dopasuj-kosmetyk.tsx" /* webpackChunkName: "component---src-pages-dopasuj-kosmetyk-tsx" */),
  "component---src-pages-gwiazdka-tsx": () => import("./../../../src/pages/gwiazdka.tsx" /* webpackChunkName: "component---src-pages-gwiazdka-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-odbior-osobisty-tsx": () => import("./../../../src/pages/odbior-osobisty.tsx" /* webpackChunkName: "component---src-pages-odbior-osobisty-tsx" */),
  "component---src-pages-order-confirmation-tsx": () => import("./../../../src/pages/order-confirmation.tsx" /* webpackChunkName: "component---src-pages-order-confirmation-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-sklep-tsx": () => import("./../../../src/pages/sklep.tsx" /* webpackChunkName: "component---src-pages-sklep-tsx" */),
  "component---src-pages-unsubscribe-tsx": () => import("./../../../src/pages/unsubscribe.tsx" /* webpackChunkName: "component---src-pages-unsubscribe-tsx" */),
  "component---src-pages-wspolpraca-b-2-b-tsx": () => import("./../../../src/pages/wspolpraca-b2b.tsx" /* webpackChunkName: "component---src-pages-wspolpraca-b-2-b-tsx" */),
  "component---src-templates-blog-post-template-tsx": () => import("./../../../src/templates/blog-post-template.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */),
  "component---src-templates-blog-tag-template-tsx": () => import("./../../../src/templates/blog-tag-template.tsx" /* webpackChunkName: "component---src-templates-blog-tag-template-tsx" */),
  "component---src-templates-mdx-template-tsx": () => import("./../../../src/templates/mdx-template.tsx" /* webpackChunkName: "component---src-templates-mdx-template-tsx" */),
  "component---src-templates-perfect-match-template-tsx": () => import("./../../../src/templates/perfect-match-template.tsx" /* webpackChunkName: "component---src-templates-perfect-match-template-tsx" */),
  "component---src-templates-product-category-template-tsx": () => import("./../../../src/templates/product-category-template.tsx" /* webpackChunkName: "component---src-templates-product-category-template-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../../../src/templates/product-template.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */)
}

