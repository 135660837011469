import React from "react";
import { CartDTO, DiscountType, PromotionDTO } from "@on/model-store";
import { financial } from "../../utils/functions/financial";
import { ContentWrapper, DynamicMessage, Wrapper } from "./promotionsHelpers";
import Markdown from "markdown-to-jsx";
import { translate } from "../../utils/functions/translationComponent";
import { CartState } from "../../utils/types/states";

function isMinCartValueFulfilled(minCartValue: number, cart: CartDTO) {
  return cart.grand_total - cart.shipment_total + cart.discount_total >= minCartValue;
}

function isProductRestrictionFulfilled(productRestriction: PromotionDTO["restrictions"]["product"], cart: CartDTO) {
  return (productRestriction ?? []).some((p) => cart.items.map((i) => i.product.product_id).includes(p.id));
}

export const isPromotionAppliedOrApplicable = (promotion: PromotionDTO, cart: CartDTO): boolean => {
  const isDiscountApplied = cart.discounts?.some((d) => d.source.ref === promotion.ID);
  if (promotion.discountType === "FREE_SHIPPING") {
    if (!isDiscountApplied) {
      const isRestrictionFulfilled =
        (promotion.restrictions.min_cart_value && isMinCartValueFulfilled(promotion.restrictions.min_cart_value, cart)) ||
        (promotion.restrictions.product && isProductRestrictionFulfilled(promotion.restrictions.product, cart)) ||
        Object.entries(promotion.restrictions).length === 0;
      return isRestrictionFulfilled;
    }
  }
  return isDiscountApplied;
};

function DynamicMessageWrapper({ promotion, children, $applied }: { promotion: PromotionDTO; children: React.ReactNode; $applied?: boolean }) {
  return (
    <DynamicMessage $applied={$applied} $backgroundColor={promotion.backgroundColor} $textColor={promotion.textColor}>
      {promotion.overrideText ? <Markdown>{promotion.overrideText}</Markdown> : children}
    </DynamicMessage>
  );
}

function RenderPromotion({ promotion, cart }: { promotion: PromotionDTO; cart: CartDTO }) {
  if (cart.items.length > 0) {
    const promotionApplied = isPromotionAppliedOrApplicable(promotion, cart);
    if (promotionApplied) {
      return (
        <DynamicMessageWrapper promotion={promotion} $applied={true}>
          {translate({ key: `promotion.${promotion.discountType as Exclude<DiscountType, "EMPTY">}.fulfilled`, params: { name: promotion.name } })}{" "}
        </DynamicMessageWrapper>
      );
    } else {
      if (promotion.restrictions.min_cart_value) {
        const minCartValueFulfilled = isMinCartValueFulfilled(promotion.restrictions.min_cart_value, cart);
        if (!minCartValueFulfilled) {
          const diff = financial((promotion.restrictions.min_cart_value - (cart.grand_total - cart.shipment_total + cart.discount_total)).toString(), cart.currency);
          return (
            <DynamicMessageWrapper promotion={promotion}>
              {translate({ key: `promotion.${promotion.discountType as Exclude<DiscountType, "EMPTY">}.min_cart_value.not.fulfilled`, params: { diff, name: promotion.name } })}{" "}
            </DynamicMessageWrapper>
          );
        }
      }
      if (promotion.restrictions.product && promotion.restrictions.product.length > 0) {
        const productRestrictionFulfilled = isProductRestrictionFulfilled(promotion.restrictions.product, cart);
        if (!productRestrictionFulfilled) {
          const missingProducts = promotion.restrictions.product
            .filter((p) => !cart.items.map((i) => i.product.product_id).includes(p.id))
            .map((p) => p.name)
            .join(", ");
          return (
            <DynamicMessageWrapper promotion={promotion}>
              {translate({ key: `promotion.${promotion.discountType as Exclude<DiscountType, "EMPTY">}.products.not.fulfilled`, params: { missingProducts, name: promotion.name } })}
            </DynamicMessageWrapper>
          );
        }
      }
    }
  }
  return <DynamicMessageWrapper promotion={promotion}>{promotion.description}</DynamicMessageWrapper>;
}

export const RenderPromotions = ({ promotions, cart }: { promotions?: PromotionDTO[]; cart: (CartState & { isCartLoaded: true }) | (Partial<CartState> & { isCartLoaded: false }) }) => {
  return (
    <Wrapper>
      {promotions?.length &&
        cart.isCartLoaded &&
        promotions.map((promotion, index) => (
          <ContentWrapper key={index}>
            <RenderPromotion promotion={promotion} cart={cart} />
          </ContentWrapper>
        ))}
    </Wrapper>
  );
};
