import React from "react";
import ProductListItem from "./ProductListItem";
import CategoryPageMenu from "./CategoryPageMenu";

import { ProductCategoryPage } from "../../../styles/wrappers/ProductCategoryPageWrapper";
import { TitleCategoryPage } from "../../UI/index";
import { ProductProps } from "../../../utils/types/graphQLTypes";

interface Props {
  products: (Pick<ProductProps, "images" | "slug" | "name" | "name_span" | "product_id" | "prices" | "label" | "productType"> & { custom_attributes: { giftPacking: boolean } })[];
  title: string;
  description: string;
}

const Index = ({ products, title, description }: Props) => {
  return (
    <>
      <TitleCategoryPage>
        <h1 dangerouslySetInnerHTML={{ __html: title }} />
        <p dangerouslySetInnerHTML={{ __html: description }} />
      </TitleCategoryPage>
      <ProductCategoryPage>
        <nav>
          <CategoryPageMenu />
        </nav>
        <section className="product-list">
          {products.map((product) => {
            return <ProductListItem key={product.product_id} product={product} />;
          })}
        </section>
      </ProductCategoryPage>
    </>
  );
};

export default Index;
