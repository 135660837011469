export const colors = {
  white: "#fff",
  black: "#000000",
  primary: "#b8855d",
  primaryOld: "#9A6B50",
  secondary: "#6E6A6A",
  titles: "#656565",
  font: "#0B0A0A",
  gray: "#F7EDF0",
  linearGradient: "linear-gradient(345deg, rgba(136, 66, 46, 1) 0%, rgba(252, 199, 155, 1) 50%, rgba(136, 66, 46, 1) 100%)",
  secondary_opacity: "rgba(110, 106, 106, 0.2)",
  primary_opacity: "rgba(154, 107, 80, 0.2)",
};
