import React from "react";
import BannerWide from "./BannerWide";
import BannerNarrow from "./BannerNarrow";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { IGatsbyImageData } from "gatsby-plugin-image";

const Banner = ({ narrowImage, wideImage }: { narrowImage: IGatsbyImageData; wideImage: IGatsbyImageData }) => {
  const { width } = useWindowSize();

  return <div>{(width ?? 0) < 700 ? <BannerNarrow image={narrowImage} /> : <BannerWide image={wideImage} />}</div>;
};

export default Banner;
