import * as React from "react";
import { memo } from "react";

function MapPin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 64 64" fillRule="evenodd" clipRule="evenodd" {...props}>
      <path
        fillRule="nonzero"
        d="M15 24.573C15 14.868 22.835 7 32.5 7S50 14.868 50 24.573C50 40.687 32.5 56 32.5 56S15 40.687 15 24.573zm31.95 0c0-8.003-6.484-14.51-14.45-14.51-7.966 0-14.45 6.507-14.45 14.51 0 11.438 10.061 22.79 14.447 27.2 4.386-4.415 14.453-15.796 14.453-27.2zm-20.55.802c0-3.381 2.733-6.125 6.1-6.125 3.367 0 6.1 2.744 6.1 6.125S35.867 31.5 32.5 31.5c-3.367 0-6.1-2.744-6.1-6.125zm9.15 0a3.057 3.057 0 00-3.05-3.063 3.057 3.057 0 00-3.05 3.063 3.057 3.057 0 003.05 3.063 3.057 3.057 0 003.05-3.063z"
      />
    </svg>
  );
}

const MemoMapPin = memo(MapPin);
export default MemoMapPin;
