import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { TitleHomepage } from "../../UI";
import { borderBefore } from "../../../styles/mixins";
import { theme } from "../../../styles/theme/theme";

const Wrapper = styled.section`
  margin: ${theme.spaces.margin.vertical} auto;
  position: relative;
  padding: ${theme.spaces.margin.horizontal} 0;
  ${borderBefore};

  @media (min-width: 680px) {
    margin: ${theme.spaces.margin.vertical} auto;
    padding-top: ${theme.spaces.margin.vertical_big};
  }

  @media (min-width: 1360px) {
    padding: ${theme.spaces.padding.base} auto;
  }

  span {
    text-transform: lowercase;
    letter-spacing: 1px;
    font-size: ${theme.fonts.fontSize.xl};
    color: ${theme.colors.primary};
  }

  .border_out {
    display: inline-block;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: ${theme.colors.primary};
      transition: width 0.3s;
    }
    &:hover {
      &:after {
        width: 0;
      }
    }
  }
`;

const PhotosWrapper = styled.ul`
  scrollbar-width: none;
  overflow-x: scroll;
  overflow-y: hidden;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(10, auto);
  grid-gap: ${theme.spaces.padding.medium};
  padding-left: ${theme.spaces.padding.big};

  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 680px) {
    overflow-x: hidden;
    padding: ${theme.spaces.padding.xxl};
    grid-gap: ${theme.spaces.padding.base};
    grid-template-columns: repeat(5, minmax(120px, 1fr));
  }

  @media (min-width: 1360px) {
    max-width: 1360px;
    padding: 0;
  }
`;

const ImageContainer = styled.li`
  width: 150px;
  height: 150px;
  overflow: hidden;

  @media (min-width: 680px) {
    width: auto;
    height: auto;
  }
`;

const StyledImage = styled(GatsbyImage)`
  transition: transform 0.5s ease;
  &:hover {
    transform: scale(1.3);
  }
`;

const query = graphql`
  {
    allFile(
      sort: { name: ASC }
      filter: { relativeDirectory: { eq: "insta" } }

      limit: 10
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(height: 320)
        }
      }
    }
  }
`;

interface DataProps {
  allFile: {
    nodes: {
      childImageSharp: { gatsbyImageData: IGatsbyImageData };
    }[];
  };
}

const InstagramFeed = () => {
  const {
    allFile: { nodes: images },
  } = useStaticQuery<DataProps>(query);
  return (
    <Wrapper>
      <TitleHomepage title="natura. to proste">
        <a href="https://www.instagram.com/opcja_natura/" target="_blank" className="border_out" rel="noreferrer">
          <span>@opcjanatura</span>
        </a>
      </TitleHomepage>
      <PhotosWrapper>
        {images.map((image, i) => {
          return (
            <ImageContainer key={i}>
              <a href="https://www.instagram.com/opcja_natura/" target="_blank" rel="noreferrer">
                <StyledImage image={image.childImageSharp.gatsbyImageData} alt={`opcja natura instagram feed foto ${i}`} />
              </a>
            </ImageContainer>
          );
        })}
      </PhotosWrapper>
    </Wrapper>
  );
};

export default InstagramFeed;
