import styled from "styled-components";
import { rotate } from "../../../styles/mixins";
import { theme as checkoutTheme } from "../../../styles/theme/checkoutTheme";
import { theme } from "../../../styles/theme/theme";

const { colors } = checkoutTheme;

export const CheckoutFromContainer = styled.form`
  background-color: inherit;
`;

export const FormFieldContainer = styled.div`
  padding: 17px 0;
  position: relative;
  display: grid;
  grid-auto-rows: 0.7fr;
`;

export const Fieldset = styled.fieldset`
  margin-bottom: 2em;
`;

export const FormLabel = styled.label<{ $marginTop?: string }>`
  margin-top: ${({ $marginTop }) => ($marginTop ? $marginTop : "unset")};
  color: ${colors.labelColor};
  letter-spacing: 0.03rem;
  line-height: 1;
  display: inline-block;
  align-self: start;
`;

export const FormInputContainer = styled.div<{ $error?: boolean }>`
  display: inline-block;
  color: ${colors.labelColor};
  background-color: ${colors.inputBckg};
  line-height: normal;
  height: 52px;
  border: 1px solid ${colors.borderColor};
  position: relative;
  width: 100%;
  justify-content: center;
  margin-top: 3px;

  &::after {
    content: "";
    display: block;
    width: 0;
    height: 3px;
    transition: width 0.4s;
    width: ${({ $error }) => ($error ? "100%" : "0")};
    background: ${({ $error }) => ($error ? colors.errorColor : colors.borderColor)};
  }

  :focus-within::after {
    width: 100%;
  }
`;

export const FormInput = styled.input`
  outline: none;
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 500;
  line-height: 20px;
`;

export const ErrorMessage = styled.p<{ $position?: string }>`
  position: ${({ $position }) => ($position ? $position : "absolute")};
  color: ${colors.errorColor};
  bottom: 0;
`;

export const ErrorBox = styled.p<{ $top?: string }>`
  position: absolute;
  top: ${({ $top }) => ($top ? $top : "-1.5em")};
  color: ${colors.errorColor};
  opacity: 1;
  transition: opacity 0.5s ease-in;
  width: max-content;
`;

export const FormRowContainer = styled.div<{ $equal?: boolean }>`
  display: grid;
  grid-template-columns: ${({ $equal }) => ($equal ? "1fr 1fr" : "2fr 1fr")};
  grid-auto-rows: 1fr;
  justify-items: space-between;
  align-items: center;
  grid-gap: 2rem;
`;

export const CheckboxRowContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 1em 0;
`;

export const CheckboxItem = styled.div`
  display: block;
  position: relative;

  input[type="checkbox"] + label::after {
    content: none;
  }

  input[type="checkbox"]:checked + label::after {
    content: "";
  }

  input[type="checkbox"]:checked + label::before {
    content: "";
    background-color: #9a6b50;
    transition: background-color 0.3s linear;
    border: 1px solid #9a6b50;
  }
`;

export const Label = styled.label<{ $error?: boolean }>`
  letter-spacing: 0.02em;
  font-size: ${theme.fonts.fontSize.lg};
  cursor: pointer;
  position: relative;
  line-height: 2;
  background-color: ${({ $error }) => $error && colors.errorColor};
  color: ${({ $error }) => $error && "white"};
  padding: 0.2em;
  padding-left: 10px;
  margin-left: ${({ $error }) => $error && "3px"};
  transition: background-color 0.3s ease-in;

  &::before {
    content: "";
    position: absolute;
    top: 1px;
    display: inline-block;
    width: 16px;
    height: 16px;
    border: ${({ $error }) => ($error ? `1px solid ${colors.errorColor}` : `1px solid ${colors.borderColor}`)};
    left: ${({ $error }) => ($error ? "-19px" : "-16px")};
  }

  &::after {
    content: "";
    position: absolute;
    left: -12px;
    top: 4px;
    display: inline-block;
    height: 6px;
    width: 9px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg);
    animation-name: ${rotate};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }
`;

export const Input = styled.input`
  cursor: pointer;
  opacity: 0;
`;

export const StyledTextarea = styled.textarea`
  display: inline-block;
  background-color: ${colors.inputBckg};
  line-height: normal;
  margin-top: 3px;
  width: 100%;
  height: 100px;
  border: 1px solid ${colors.borderColor};
  padding: 8px;
`;

export const TextareaLabel = styled.label`
  cursor: pointer;
`;

export const NumberSpan = styled.span`
  display: inline-block;
`;

export const NumberDiv = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  align-items: center;
`;
