import { getWindow } from "../types/window";
import { CartDTO } from "@on/model-store";
import { QUERY_PARAMS } from "../types/queryParams";

const isBrowser = () => typeof window !== "undefined";

export const isBundleProduct = (product: CartDTO["items"][0]["product"]): boolean => product.type === "BUNDLE";

export const trimPolishPrefix = (phoneNumber: string): string => {
  const polishPrefix: string = "+48";
  if (phoneNumber.startsWith(polishPrefix)) {
    return phoneNumber.substring(polishPrefix.length);
  } else {
    return phoneNumber;
  }
};

export const clearIntervals = () => {
  if (isBrowser() && getWindow().intervalToClear && getWindow().intervalToClear?.id && getWindow().intervalToClear?.location === window.location.href) {
    try {
      clearInterval(getWindow().intervalToClear?.id as NodeJS.Timeout);
      getWindow().intervalToClear = undefined;
    } catch (e) {
      //
    }
  }
};

export const getUrlParams = (): { get(param: QUERY_PARAMS): string | null; delete(param: QUERY_PARAMS): void; toString(): string } | undefined => {
  if (typeof window !== "undefined") {
    return new URLSearchParams(window.location.search);
  } else {
    return undefined;
  }
};

export const getCurrentPage = (): "SEARCH" | "OTHER" => {
  if (typeof window !== "undefined") {
    return window.location.pathname === "/search/" ? "SEARCH" : "OTHER";
  } else {
    return "OTHER";
  }
};
