import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import IconBusket from "../../../assets/icons/IconBusket";
import MiniCartAnimation from "../animations/MiniCartAnimation";
import Link from "./Link";
import { CartContextState } from "../../../utils/types/states";
import { theme } from "../../../styles/theme/theme";
import { CartContext } from "../../cart/cartState";

const StyledButton = styled.button`
  position: relative;

  .items-count-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: -6px;
    right: -6px;
    background: ${theme.colors.primary};
    border-radius: 50%;
    height: 15px;
    width: 15px;
  }

  .items-count {
    font-size: ${theme.fonts.fontSize.base};
    color: ${theme.colors.white};
  }
`;

const CartIcon = () => {
  const { state } = useContext<CartContextState>(CartContext);
  const [quantity, setQuantity] = useState(state?.item_quantity ?? 0);
  const [startAnimation, setStartAnimation] = useState(false);
  const updateMiniCartCallback = useCallback(() => {
    setQuantity(state?.item_quantity ?? 0);
  }, [setQuantity, state?.item_quantity]);
  const animationFinishedCallback = useCallback(() => {
    setStartAnimation(false);
    setQuantity(state?.item_quantity ?? 0);
  }, [setStartAnimation, setQuantity, state?.item_quantity]);

  useEffect(() => {
    if (state?.action && state.action !== "CART_LOADED" && state?.item_quantity !== state?.prev?.item_quantity) {
      setStartAnimation(true);
    } else if (state?.isCartLoaded) {
      setQuantity(state.item_quantity ?? 0);
    }
  }, [state?.item_quantity, state?.prev?.item_quantity, state?.action, state?.isCartLoaded]);

  return (
    <Link to={"/cart"} data-testid={"cart-link-icon"}>
      <StyledButton className="checkout_button tooltip" data-text="koszyk">
        <IconBusket className="navbar_icon " title="koszyk" />

        <MiniCartAnimation startAnimation={startAnimation} updateMiniCartCallback={updateMiniCartCallback} animationFinishedCallback={animationFinishedCallback}>
          <div className="items-count-container">
            <span className="items-count" data-testid={"mini-cart-qty"}>
              {quantity}
            </span>
          </div>
        </MiniCartAnimation>
      </StyledButton>
    </Link>
  );
};

export default CartIcon;
