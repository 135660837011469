import React, { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { CartContext } from "../cart/cartState";
import MobileMenu from "./mobileMenu";
import DesktopMenu from "./desktopMenu";
import NavbarIcons from "./navbarIcons";
import { LogoMain } from "../UI";
import { Hamburger, NodeWrapper, StyledHeader } from "./helpers/navbarHelpers";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { useScroll } from "../../utils/hooks/useWindowScroll";
import { CartContextState } from "../../utils/types/states";
import { GlobalNavbarMessage } from "./GlobalNavbarMessage";
import { RenderPromotions } from "../promotion/renderPromotions";
import ManualPromotion from "../promotion/manualPromotion";

interface Props {
  path: string;
}

const NewNavbar = ({ path }: Props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const scroll = useScroll(true);
  const node: MutableRefObject<HTMLDivElement> | MutableRefObject<undefined> = useRef();
  const { state, dispatch } = useContext<CartContextState>(CartContext);
  if (!state) {
    throw new Error("Missing cart context");
  }
  const { width = 0 } = useWindowSize();

  const showManualPromoMessage = false;

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent | TouchEvent) => {
      if (node.current?.contains(e.target as Node)) {
        return;
      }
      // outside click
      setMobileMenuOpen(false);
    };

    if (mobileMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [mobileMenuOpen]);
  return (
    <>
      {state.globalMessage && <GlobalNavbarMessage globalMessage={state.globalMessage} dispatch={dispatch} />}
      {!showManualPromoMessage && <RenderPromotions promotions={state.promotions} cart={state} />}
      {showManualPromoMessage && <ManualPromotion />}
      <StyledHeader $visible={scroll} $path={path} data-testid={"navbar-header"} $loaded={width !== 0}>
        {width < 1100 ? (
          <NodeWrapper ref={node as MutableRefObject<HTMLDivElement>} data-testid={"navbar-mobile__wrapper"}>
            <Hamburger role="button" open={mobileMenuOpen} onClick={() => setMobileMenuOpen(true)} data-testid={"navbar-mobile__hamburger"} />
            <MobileMenu open={mobileMenuOpen} setOpen={setMobileMenuOpen} width={width} path={path} />
          </NodeWrapper>
        ) : (
          <DesktopMenu />
        )}
        <LogoMain />
        <NavbarIcons width={width} path={path} />
      </StyledHeader>
    </>
  );
};

export default NewNavbar;
