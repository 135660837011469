import { getCookie } from "./commonService";
import { Cart, Order } from "../../utils/types/dto";
import {
  AccountChangeNewsletterSubscriptionRequest,
  AccountDTO,
  AccountUpdateRequest,
  CartRequest,
  FBC_HEADER_NAME,
  FBP_HEADER_NAME,
  ForgetPasswordRequest,
  IAPIError,
  REFERER_HEADER_NAME,
  RegisterUserRequest,
  ResetPasswordRequest,
  SearchResponse,
} from "@on/model-store";
import { getStoreProxyUrl } from "../../utils/functions/urls";

const SESSION_HEADER_NAME = "x-session-id";
export const SESSION_COOKIE_NAME = "store-session-id";
const FBP_COOKIE_NAME = "_fbp";
const FBC_COOKIE_NAME = "_fbc";

type Request =
  | CartGetRequest
  | CartPostRequest
  | PromotionsGetRequest
  | OrderGetRequest
  | OrdersGetRequest
  | RegisterUserPostRequest
  | ForgetPasswordPostRequest
  | ResetPasswordPostRequest
  | AccountUpdatePostRequest
  | AccountGetRequest
  | AccountChangeNewsletterSubscriptionPostRequest
  | AccountGetNewsletterSubscriptionPostRequest
  | SearchProductsGetRequest;

interface AccountGetRequest {
  url: "accounts";
  method: "GET";
  bodyJson: undefined;
}

interface AccountUpdatePostRequest {
  url: "accounts";
  method: "POST";
  bodyJson: AccountUpdateRequest;
}

interface RegisterUserPostRequest {
  url: "accounts/register";
  method: "POST";
  bodyJson: RegisterUserRequest;
}

interface AccountChangeNewsletterSubscriptionPostRequest {
  url: "accounts/subscription";
  method: "POST";
  bodyJson: AccountChangeNewsletterSubscriptionRequest;
}

interface AccountGetNewsletterSubscriptionPostRequest {
  url: `accounts/subscription?email=${string}`;
  method: "GET";
  bodyJson: undefined;
}

interface SearchProductsGetRequest {
  url: `search?q=${string}`;
  method: "GET";
  bodyJson: undefined;
}

interface ForgetPasswordPostRequest {
  url: "accounts/forget-password";
  method: "POST";
  bodyJson: ForgetPasswordRequest;
}

interface ResetPasswordPostRequest {
  url: "accounts/reset-password";
  method: "POST";
  bodyJson: ResetPasswordRequest;
}

interface PromotionsGetRequest {
  url: "promotions";
  method: "GET";
  bodyJson: undefined;
}

interface OrderGetRequest {
  url: `orders/${string}`;
  method: "GET";
  bodyJson: undefined;
}

interface OrdersGetRequest {
  url: `orders`;
  method: "GET";
  bodyJson: undefined;
}

interface CartGetRequest {
  url: "carts";
  method: "GET";
  bodyJson: undefined;
}

interface CartPostRequest {
  url: "carts";
  method: "POST";
  bodyJson: CartRequest;
}

const lastCartPostRequest = {
  inProgress: false,
  started: Date.now(),
};

function getHeaderValues(needsSessionCookie: boolean) {
  let sessionCookie, fbcCookie, fbpCookie, referer: string | undefined;
  if (needsSessionCookie) {
    sessionCookie = getCookie(SESSION_COOKIE_NAME);
    fbcCookie = getCookie(FBC_COOKIE_NAME);
    fbpCookie = getCookie(FBP_COOKIE_NAME);
    referer = window?.location?.href;
  }
  return { sessionCookie, fbcCookie, fbpCookie, referer };
}

const isCartUpdateRequest = (url: string, method: string) => url === "carts" && method === "POST";

const doStoreRequest = async <T extends Request>(url: T["url"], method: T["method"], bodyJson: T["bodyJson"], needsSessionCookie = true): Promise<Response> => {
  const { sessionCookie, fbcCookie, fbpCookie, referer } = getHeaderValues(needsSessionCookie);

  if (isCartUpdateRequest(url, method)) {
    while (lastCartPostRequest.inProgress && Date.now() - lastCartPostRequest.started < 3000) {
      await new Promise((resolve) => setTimeout(resolve, 200));
    }
    lastCartPostRequest.inProgress = true;
  }

  const response = await fetch(`${getStoreProxyUrl()}/${url}`, {
    method,
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      ...(sessionCookie ? { [SESSION_HEADER_NAME]: sessionCookie } : undefined),
      ...(fbcCookie ? { [FBC_HEADER_NAME]: fbcCookie } : undefined),
      ...(fbpCookie ? { [FBP_HEADER_NAME]: fbpCookie } : undefined),
      ...(referer ? { [REFERER_HEADER_NAME]: referer } : undefined),
    },
    ...(bodyJson ? { body: JSON.stringify(bodyJson) } : undefined),
  });
  if (isCartUpdateRequest(url, method)) {
    lastCartPostRequest.inProgress = false;
  }
  if (response.ok) {
    if (needsSessionCookie) {
      const sessionHeader = response.headers.get(SESSION_HEADER_NAME);
      if (!sessionHeader) {
        throw new Error(`Missing session header for ${JSON.stringify(bodyJson)}`);
      }
      setSessionCookie(SESSION_COOKIE_NAME, sessionHeader);
    }
  }
  return response;
};

export const searchProducts = async (query: string): Promise<SearchResponse | IAPIError> => {
  const response = await doStoreRequest(`search?q=${encodeURIComponent(query)}`, "GET", undefined, false);
  return (await response.json()) as SearchResponse | IAPIError;
};

export const getNewsletterSubscription = async (email: string): Promise<{ subscribed: boolean } | IAPIError> => {
  const response = await doStoreRequest(`accounts/subscription?email=${encodeURIComponent(email.toLowerCase())}`, "GET", undefined, false);
  return (await response.json()) as { subscribed: boolean } | IAPIError;
};

export const changeNewsletterSubscription = async (data: AccountChangeNewsletterSubscriptionRequest): Promise<{ result: boolean } | IAPIError> => {
  const response = await doStoreRequest(`accounts/subscription`, "POST", data, false);
  return (await response.json()) as { result: boolean } | IAPIError;
};

export const updateAccount = async (data: AccountUpdateRequest): Promise<Cart | IAPIError> => {
  const response = await doStoreRequest(`accounts`, "POST", data);
  return (await response.json()) as Cart | IAPIError;
};

export const getCurrentAccount = async (): Promise<AccountDTO | IAPIError> => {
  const response = await doStoreRequest(`accounts`, "GET", undefined);
  return (await response.json()) as AccountDTO | IAPIError;
};

export const registerUser = async (data: RegisterUserRequest): Promise<{ result: boolean } | IAPIError> => {
  if ("Email" in data && data.Email) {
    data.Email = data.Email.toLowerCase();
  }
  const response = await doStoreRequest(`accounts/register`, "POST", data, false);
  return (await response.json()) as { result: boolean } | IAPIError;
};

export const forgetPassword = async (data: ForgetPasswordRequest): Promise<{ result: boolean } | IAPIError> => {
  data.Email = data.Email.toLowerCase();
  const response = await doStoreRequest(`accounts/forget-password`, "POST", data, false);
  return (await response.json()) as { result: boolean } | IAPIError;
};

export const resetPassword = async (data: ResetPasswordRequest): Promise<{ result: boolean } | IAPIError> => {
  const response = await doStoreRequest(`accounts/reset-password`, "POST", data, false);
  return (await response.json()) as { result: boolean } | IAPIError;
};

export const getOrder = async (orderId: string): Promise<Order | IAPIError> => {
  const response = await doStoreRequest(`orders/${orderId}`, "GET", undefined);
  return (await response.json()) as Order | IAPIError;
};

export const getOrders = async (): Promise<Order[] | IAPIError> => {
  const sessionCookie = getCookie(SESSION_COOKIE_NAME);
  if (!sessionCookie) {
    return {
      code: 401,
      message: "Unauthorized",
      errorData: {
        errorMsgCode: "account.already.confirmed", //TODO FIX
      },
    };
  }
  const response = await doStoreRequest(`orders`, "GET", undefined);
  return (await response.json()) as Order[] | IAPIError;
};

export const getCart = async (): Promise<Cart> => {
  const response = await doStoreRequest("carts", "GET", undefined);
  if (response.status === 404) {
    setSessionCookie(SESSION_COOKIE_NAME, null);
  }
  return (await response.json()) as Cart;
};

export const getCartWithToken = async (params: { registrationConfirmationToken?: string; cartRecoveryToken?: string }): Promise<Cart | IAPIError> => {
  let response: Response | undefined = undefined;
  if (params.cartRecoveryToken) {
    response = await doStoreRequest("carts", "POST", {
      recoveryToken: params.cartRecoveryToken,
    });
  } else if (params.registrationConfirmationToken) {
    response = await doStoreRequest("carts", "POST", {
      account: {
        emailRegistrationConfirmationToken: params.registrationConfirmationToken,
      },
    });
  }
  if (response) {
    return (await response.json()) as Cart | IAPIError;
  } else {
    throw new Error("Illegal state");
  }
};

export const updateItem = async (
  product: { product_id: string },
  quantity: number,
  increase: boolean,
  options?: {
    id: string;
    value: string;
  }[]
): Promise<Cart> => {
  const response = await doStoreRequest("carts", "POST", {
    update_items: {
      ...product,
      quantity,
      increase,
      options: options ?? [],
    },
  });
  return (await response.json()) as Cart;
};

export const updateItemByLineId = async (
  product: { lineId: string },
  quantity: number,
  increase: boolean,
  options?: {
    id: string;
    value: string;
  }[]
): Promise<Cart> => {
  const response = await doStoreRequest("carts", "POST", {
    update_items: {
      ...product,
      quantity,
      increase,
      options: options ?? [],
    },
  });
  return (await response.json()) as Cart;
};

export const addToCart = async (
  product: { product_id: string; forceNewLine?: boolean },
  quantity: number,
  subProductsReplacementForced: boolean,
  options?: {
    id: string;
    value: string;
  }[]
): Promise<Cart> => {
  const response = await doStoreRequest("carts", "POST", {
    add_items: {
      ...product,
      quantity,
      subProductsReplacementForced,
      options,
    },
  });
  return (await response.json()) as Cart;
};

export const applyCoupon = async (couponCode: string): Promise<Cart | IAPIError> => {
  const response = await doStoreRequest("carts", "POST", {
    add_coupon_code: couponCode.toUpperCase(),
  });
  return (await response.json()) as Cart | IAPIError;
};

export const removeCoupon = async (couponCode: string): Promise<Cart | IAPIError> => {
  const response = await doStoreRequest("carts", "POST", {
    remove_coupon_code: couponCode,
  });
  return (await response.json()) as Cart | IAPIError;
};

export const removeItems = async (product_id: string): Promise<Cart> => {
  const response = await doStoreRequest("carts", "POST", {
    remove_items: {
      product_id,
    },
  });
  return (await response.json()) as Cart;
};

export const removeItemsByLineId = async (lineId: string): Promise<Cart> => {
  const response = await doStoreRequest("carts", "POST", {
    remove_items: {
      lineId,
    },
  });
  return (await response.json()) as Cart;
};

export const updateMetadata = async (metadata: CartPostRequest["bodyJson"]["metadata"]): Promise<Cart> => {
  const response = await doStoreRequest("carts", "POST", {
    metadata,
  });
  return (await response.json()) as Cart;
};

export const orderAgain = async (orderId: string): Promise<Cart | IAPIError> => {
  return await save({ orderAgain: { orderId } });
};

export const save = async (data: CartPostRequest["bodyJson"]): Promise<Cart | IAPIError> => {
  if (data.account && "email" in data.account) {
    data.account.email = data.account.email.toLowerCase();
  }
  if (data.metadata?.email) {
    data.metadata.email = data.metadata.email.toLowerCase();
  }

  const response = await doStoreRequest("carts", "POST", {
    ...(data.orderAgain ? { orderAgain: data.orderAgain } : undefined),
    ...(data.logout ? { logout: data.logout } : undefined),
    ...(data.metadata ? { metadata: data.metadata } : undefined),
    ...(data.account ? { account: data.account } : undefined),
    ...(data.billing ? { billing: data.billing } : undefined),
    ...(data.shipping ? { shipping: data.shipping } : undefined),
    ...(data.comments ? { comments: data.comments } : undefined),
    ...(data.shippingService ? { shippingService: { method_id: data.shippingService.method_id, attributes: data.shippingService.attributes } } : undefined),
  });
  return (await response.json()) as Cart | IAPIError;
};

export const getPromotions = async () => {
  const response = await doStoreRequest("promotions", "GET", undefined);
  return response.json();
};

function setSessionCookie(name: string, value: string | null) {
  const COOKIE_MAX_AGE_28_DAYS = 604800 * 4;
  const options = {
    path: "/",
    "max-age": COOKIE_MAX_AGE_28_DAYS,
    samesite: "lax",
  } as const;
  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value ?? "");
  for (const optionKey in options) {
    updatedCookie += "; " + optionKey;
    const optionValue = options[optionKey as keyof typeof options];
    // if (optionValue !== true) {

    updatedCookie += "=" + optionValue;
    // }
  }
  document.cookie = updatedCookie;
}
