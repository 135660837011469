import React, { MutableRefObject, useCallback, useContext, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { CartContext } from "../../cart/cartState";
import { addToCart } from "../../service/cartService";
import { CartLinearProgress } from "../../UI/elements/LinearProgress";
import { ButtonAnimation } from "../../../styles/mixins";
import { theme } from "../../../styles/theme/theme";
import { IGatsbyImageData } from "gatsby-plugin-image";

const flyingImageSize = {
  width: 150,
  height: 150,
  finalRatio: 2,
};

const flyToCart = (transformX: string, transformY: string) => keyframes`
    to {
      transform: translateX(calc(-50% + ${transformX})) translateY(calc(-50% + ${transformY})) scale(0.5);
    }
`;

const BuyButtonWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const FlyToCartImage = styled.img<{ $left: string; $top: string; $transformX: string; $transformY: string }>`
  opacity: 0.5;
  position: fixed;
  height: ${flyingImageSize.height}px;
  width: ${flyingImageSize.width}px;
  left: ${(props) => props.$left};
  top: ${(props) => props.$top};
  z-index: 100;
  animation: ${(props) => flyToCart(props.$transformX, props.$transformY)} 1s ease-in-out;
`;

const StyledButton = styled.button<{ $padding?: boolean; $loading: boolean }>`
  position: relative;
  background-color: ${theme.colors.white};
  padding: ${({ $padding }) => ($padding ? "0.75em 1em" : "")};
  opacity: ${({ $loading }) => ($loading ? "0.5" : "1")};
  cursor: ${({ $loading }) => ($loading ? "unset" : "pointer")};
  font-size: 1em;
  text-transform: uppercase;
  color: ${theme.colors.primary};
  letter-spacing: 0.1em;
  vertical-align: middle;
  transition: opacity 0.5s linear;

  @media (min-width: 800px) {
    padding: 0.9em 1.4em;
  }
  ${ButtonAnimation}
`;

const getCartIconPosition = () => {
  const targetElements = document.getElementsByClassName("items-count-container");
  if (targetElements.length) {
    const { left, top } = targetElements[0].getBoundingClientRect();
    return { targetLeft: Math.round(left), targetTop: Math.round(top) };
  } else {
    return { targetLeft: 0, targetTop: 0 };
  }
};

interface Props {
  productId: string;
  text?: string;
  subProductsReplacementForced?: boolean;
  images: {
    fileLocal: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  }[];
}

const BuyButton = ({ productId, images, text, subProductsReplacementForced }: Props) => {
  const { dispatch } = useContext(CartContext);
  const buttonRef: MutableRefObject<HTMLDivElement> | MutableRefObject<undefined> = useRef();
  const [state, setState] = useState({ isLoading: false, position: { left: "", top: "", transformX: "", transformY: "" } });
  const sources = images[0].fileLocal.childImageSharp.gatsbyImageData.images.sources;
  const srcSet = sources?.find(Boolean)?.srcSet;
  const sizes = sources?.find(Boolean)?.sizes;
  if (!srcSet) {
    throw new Error(`srcSet is undefined for product ${productId}`);
  }
  if (!sizes) {
    throw new Error(`sizes is undefined for product ${productId}`);
  }

  const onClick = async (params: { productId: string; quantity: number; subProductsReplacementForced: boolean }) => {
    if (!state.isLoading) {
      const { left: originalLeft, top: originalTop } = (buttonRef as MutableRefObject<HTMLDivElement>).current.getBoundingClientRect();
      const { targetLeft, targetTop } = getCartIconPosition();
      const left = Math.round(originalLeft);
      const top = Math.round(originalTop);
      setState({ isLoading: true, position: { left: `${left}px`, top: `${top}px`, transformX: `${targetLeft - left}px`, transformY: `${targetTop - top}px` } });
      await addToCart(params, dispatch);
    }
  };

  const onAnimationEnd = useCallback(() => {
    setState({ isLoading: false, position: { left: "", top: "", transformX: "", transformY: "" } });
  }, [setState]);

  return (
    <BuyButtonWrapper ref={buttonRef as MutableRefObject<HTMLDivElement>}>
      {state.isLoading && (
        <FlyToCartImage
          srcSet={srcSet}
          sizes={sizes}
          $left={state.position.left}
          $top={state.position.top}
          onAnimationEnd={onAnimationEnd}
          $transformX={state.position.transformX}
          $transformY={state.position.transformY}
        />
      )}
      <StyledButton
        onClick={() => onClick({ productId, quantity: 1, subProductsReplacementForced: Boolean(subProductsReplacementForced) })}
        $loading={state.isLoading}
        data-cy={`add-to-cart-button`}
        data-testid={`add-to-cart-button-${productId}`}
        $padding={true}
      >
        {text ? text : "Do koszyka"}
      </StyledButton>
      <CartLinearProgress loading={state.isLoading} position="absolute" />
    </BuyButtonWrapper>
  );
};

export default BuyButton;
