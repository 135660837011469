import React from "react";
import styled from "styled-components";
import { TitleHomepage } from "../../UI";
import { borderBefore } from "../../../styles/mixins";
import SubscriptionForm from "../../newsletter/subscriptionForm";
import { staticLinks } from "../../../utils/constants/staticLinks";
import { theme } from "../../../styles/theme/theme";

const Wrapper = styled.section`
  margin: ${theme.spaces.margin.vertical} 0;
  padding: ${theme.spaces.margin.horizontal} 0;
  ${borderBefore};

  @media (min-width: 680px) {
    margin: ${theme.spaces.margin.vertical_big} 0;
    padding-top: ${theme.spaces.margin.vertical_big};
  }
`;

const InnerWrapper = styled.div`
  display: grid;
  justify-items: center;
  padding: 0 ${theme.spaces.margin.xxl};
  grid-row-gap: 3em;
`;

const index = () => {
  const {
    pages: {
      regulations: { regulaminNewsletter },
    },
  } = staticLinks;
  return (
    <Wrapper>
      <TitleHomepage title="subskrypcja newsletter" span="bądź na bieżąco" />
      <InnerWrapper>
        <SubscriptionForm regulaminNewsletter={regulaminNewsletter} />
      </InnerWrapper>
    </Wrapper>
  );
};

export default index;
